import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Table,
    Spin,
    Popconfirm,
} from "antd";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from '@ant-design/icons';
import "./style.css";
import {
    EMPTY_DATA,
    LABELS_BONUS_CONFIG,
    LABEL_CREATE,
    TEXT_DEF,
    DELETE_SUCCESS,
    DELETE_FAIL,
} from "../../constant/text";
import API from "../../network/api";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";

const BonusConfig = () => {
    let isRequest = useRef(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bonusConfig, setBonusConfig] = useState([]);
    const colums = [
        {
            title: LABELS_BONUS_CONFIG.NumberOfUser,
            children: [
                {
                    title: LABELS_BONUS_CONFIG.From,
                    dataIndex: "from",
                    key: "from",
                },
                {
                    title: LABELS_BONUS_CONFIG.To,
                    dataIndex: "to",
                    key: "to",
                },
            ]
        },
        {
            title: LABELS_BONUS_CONFIG.Percent,
            dataIndex: "percent",
            key: "percent",
        },
        {
            title: 'Action',
            key: "setting",
            align: "center",
            render: (record) =>
                <div className="flex w-full items-center justify-center">
                    {
                        hasRight([RIGHTS.EditSettingXSNBonus]) ? (
                            <div>
                                <Button
                                    className="edit"
                                    onClick={() => {
                                        navigate(`/edit-bonus-config/${record.id}`);
                                    }} danger
                                >Edit</Button>
                            </div>
                        ) : null
                    }
                    {
                        hasRight([RIGHTS.DeleteSettingXSNBonus]) ? (
                            <div>
                                <Popconfirm
                                    title="Delete the task"
                                    description={TEXT_DEF.text0069}
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => { confirm(record.id) }}
                                    onCancel={cancel}
                                >
                                    <Button danger>Delete</Button>
                                </Popconfirm>
                            </div>
                        ) : null
                    }
                </div>
        },
    ];

    useEffect(() => {
        getBonusConfig();
    }, []);

    const getBonusConfig = async () => {
        try {
            setLoading(true);
            const result = await API.get('admin-bonus-setting');
            setLoading(false);
            const bonusConfig = result.data?.data || [];
            if (!bonusConfig || bonusConfig.length === 0) return;

            const finalResult = [];
            for (const item of bonusConfig) {
                finalResult.push({
                    id: item.id,
                    to: item.to,
                    from: item.from,
                    percent: item.percent,
                });
            }

            setBonusConfig(finalResult);
        } catch (err) {
            setLoading(false);
        }
    };

    const createBonusConfig = () => {
        navigate("/create-bonus-config");
    };

    const confirm = async (id) => {
        if (isRequest.current) {
            return;
        };

        isRequest.current = true;
        try {
            await API.delete(`admin-bonus-setting/${id}`);
            await getBonusConfig();
            isRequest.current = false;
            toast(DELETE_SUCCESS);
        } catch (err) {
            setLoading(false);
            isRequest.current = false;
            toast(DELETE_FAIL);
        }
    };

    const cancel = (e) => { };

    return (
        <div className="bonus-config">
            {
                hasRight([RIGHTS.CreateSettingXSNBonus]) ? (
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
                        <Button type="primary" onClick={createBonusConfig}>
                            {LABEL_CREATE}
                        </Button>
                    </div>
                ) : null
            }

            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                    <Table
                        key="BONUS_CONFIG"
                        locale={EMPTY_DATA}
                        columns={colums}
                        dataSource={bonusConfig}
                        pagination={false}
                        bordered
                        style={{ width: "100%" }}
                    />
                </div>
                {/* <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div> */}
            </Spin>
        </div>
    );
};

export default BonusConfig;