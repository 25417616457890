import React, { useState, useEffect } from "react";
import API, { USER_QUESTION } from "../../network/api";
import { Table, Spin, Pagination, Button, Form } from "antd";
import { TEXT_DEF } from "../../constant/text";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const UserQuestionCollector = () => {
  const [listQuestion, setListQuestion] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    fetchUserQuestionData();
  }, [params]);

  const fetchUserQuestionData = async () => {
    setLoading(true);
    try {
      const response = await API.get(`${USER_QUESTION}?${params}`);
      const data = response?.data?.data[0] || [];
      const totals = response?.data?.data[1] || [];

      const lists = data.map((item) => ({
        question: item?.question,
        encryptedUserName: item?.user?.username,
        createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
      }));

      setListQuestion(lists);
      setTotalRecord(totals);
      setLoading(false);
    } catch (error) {
      console.log('Error: ', error);
      setLoading(false);
    }
  };

  const getParams = (data = {}) => {
    const currentPage = data?.page || 1;
    const limit = data?.size || 10;

    setPage(currentPage);

    const params = `page=${currentPage}&limit=${limit}`;

    setParams(params);

    return params;
  };

  const onChangePage = (page, size) => {
    getParams({ page, size });
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const columns = [
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Tên hội viên mã hoá",
      dataIndex: "encryptedUserName",
      key: "encryptedUserName",
      width: "15%",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ];

  const handleDownloadExcel = async () => {
    setLoading(true);
    await API.get(`${USER_QUESTION}`)
      .then((response) => {
        const data = response?.data?.data[0] || [];
        const lists = data.map((item) => ({
          question: item?.question,
          encryptedUserName: item?.user?.username,
          createdAt: moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
        }));
        const fileName = "Câu hỏi của hội viên";

        exportToExcel(lists, fileName);
        setLoading(false);
      })
      .then((error) => {
        console.log("Error: ", error);
        setLoading(false);
      });
  };

  const exportToExcel = (data, fileName) => {
    const formattedData = data.map((item) => ({
      "Câu hỏi": item?.question,
      "Tên HV mã hoá": item?.encryptedUserName,
      "Thời gian tạo câu hỏi": item?.createdAt,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const renderAction = () => {
    return (
      <div className="col-12">
        <Form layout="inline" autoComplete="off">
          <div className="payment-search">
            <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType="button" type="primary">
              {TEXT_DEF.text0027}
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      {renderAction()}
      <Spin spinning={loading} delay={500} size="large" style={{ width: "100%" }}>
        <div className="col-12 flex-col" style={{ display: "flex", marginTop: 30, alignItems: "center" }}>
          <Table key="qaList" locale={locale} columns={columns} dataSource={listQuestion} pagination={false} bordered style={{ width: "100%" }} />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination current={page} total={totalRecord} onChange={onChangePage} showTotal={showTotal} showSizeChanger={true} pageSizeOptions={["10", "50", "100", "200", "500", "1000"]} />
        </div>
      </Spin>
    </>
  );
};

export default UserQuestionCollector;
