import React, { useState } from "react";
import { Button, Modal } from "antd";
import { TEXT_DEF } from "../../constant/text";
import CreateQA from "./createQA";
import CreateTopics from "./createTopic";
import EditTopicOrQA from "./editTopicOrQA";
import "./style.css";

export const MainModal = ({ isModalCreateOpen, isModalEditOrDeleteOpen, handleCancel, record, setIsReloadQa, type, setIsReloadTopics, currentStepEdit, setCurrentStepEdit, topicRecord }) => {
  const [currentStepCreate, setCurrentStepCreate] = useState(1);

  const handleCreateNewTopic = () => setCurrentStepCreate(2);
  const handleCreateNewQA = () => setCurrentStepCreate(3);

  const handleCancelModalCreate = () => {
    setCurrentStepCreate(1);
    handleCancel("create");
  };

  const handleCancelModalEdit = () => {
    handleCancel("editOrDelete");
  };

  const renderCreateModalContent = () => {
    switch (currentStepCreate) {
      case 1:
        return (
          <div className="flex justify-center items-center gap-10 py-4 col-12">
            {type === "qa" ? (
              <div className="col-3">
                <Button onClick={handleCreateNewQA}>{TEXT_DEF.text0174}</Button>
              </div>
            ) : (
              <div className="col-3">
                <Button onClick={handleCreateNewTopic}>{TEXT_DEF.text0173}</Button>
              </div>
            )}
          </div>
        );
      case 2:
        return <CreateTopics handleCancelModal={handleCancelModalCreate} setIsReloadTopic={setIsReloadTopics} />;
      case 3:
        return <CreateQA handleCancelModal={handleCancelModalCreate} setIsReloadQa={setIsReloadQa} />;
      default:
        return null;
    }
  };

  const renderEditOrDelete = () => {
    switch (currentStepEdit) {
      case 2:
        return <EditTopicOrQA record={record} handleCancelModal={handleCancelModalEdit} setIsReloadQa={setIsReloadQa} />;
      case 3:
        return <EditTopicOrQA topicRecord={topicRecord} type={"editTopic"} handleCancelModal={handleCancelModalEdit} setIsReloadTopics={setIsReloadTopics} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal open={isModalCreateOpen} onCancel={handleCancelModalCreate}>
        {renderCreateModalContent()}
      </Modal>
      <Modal open={isModalEditOrDeleteOpen} onCancel={handleCancelModalEdit}>
        {renderEditOrDelete()}
      </Modal>
    </>
  );
};

export const ConfirmModal = ({ handleConfirm, handleCancelModal, text }) => {
  const replaceText = TEXT_DEF.text0177.replace("$", text);

  return (
    <div className="col-12">
      <div className="col-12 text-center">{replaceText}</div>
      <div className="col-12 flex justify-center items-center pt-3">
        <div className="col-3">
          <Button onClick={handleConfirm}>{TEXT_DEF.text0178}</Button>
        </div>
        <div className="col-3">
          <Button onClick={handleCancelModal}>{TEXT_DEF.text0007}</Button>
        </div>
      </div>
    </div>
  );
};
