import React, { useEffect, useState, useRef } from 'react';
import * as _ from "lodash";
import Modal from 'react-bootstrap/Modal';
import { Button, Input } from 'antd';
import API, { USER_INFO, ADMIN_MANAGE_USER_INFO } from '../../network/api';
import './style.css'
import { TEXT_DEF } from '../../constant/text';

const ModalNickname = ({isShow, user, close, realoadPage, setIsRealoadPage}) => {
    let isRequest = useRef(false);
    const [userInfo, setUserInfo] = useState();
    const [nicknameUp, setNicknameUp] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const getUserInfo = async () => {
            await API.get(`${ADMIN_MANAGE_USER_INFO}/${user?.userId}`)
                .then((response) => {
                    const result = response.data?.data?.result;
                    if (result) {
                        setUserInfo(result);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getUserInfo()
    }, [user])

    const pathUpdate = async () => {
        if (isRequest.current) {
            return;
        };

        isRequest.current = true;
        const param = {
            ...userInfo,
            sumBet: Number(userInfo?.sumBet),
            nickname: nicknameUp ? nicknameUp.trim() : userInfo?.nickname,
        }
        // realoadPage(true)
        await API.patch(`${ADMIN_MANAGE_USER_INFO}/${userInfo?.id}`, param)
            .then((response) => {
                const codeMess = response.data?.data?.code;
                if (codeMess === 20004) {
                    setIsRealoadPage(true);
                    close();
                }
                isRequest.current = false;
            }).catch((error) => {
                const errorMes = _.get(error, 'response.data.message.message', '');
                if (errorMes) {
                    setErrorMessage(errorMes);
                }
                console.log(error);
                isRequest.current = false;
            });
    }
    
    return (
        <Modal show={isShow} className='modal-update-nickname' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{"Đổi nickname"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input 
                    placeholder='Nhập nickname' 
                    minLength={3} maxLength={20} 
                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]} 
                    defaultValue={user?.nickname}
                    onChange={(e) => setNicknameUp(e.target.value)}
                />
            </Modal.Body>
            <div className="error-nickname">{errorMessage}</div>
            <Modal.Footer>
                <Button type="primary" onClick={pathUpdate}>
                    {TEXT_DEF.text0016}
                </Button>
                <Button type="default" onClick={close}>
                    {TEXT_DEF.text0007}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalNickname;