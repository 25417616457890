import React, { useState, useEffect } from "react";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";

import { TEXT_DEF } from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import API, { ADMIN_LIMBO } from "../../../network/api";

const LimboSetting = ({ data, auth, roleArr, reload }) => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [fromRange1, setFromRange1] = useState(0);
  const [fromRange2, setFromRange2] = useState(0);
  const [fromRange3, setFromRange3] = useState(0);
  const [fromRange4, setFromRange4] = useState(0);
  const [fromRange5, setFromRange5] = useState(0);
  const [toRange1, setToRange1] = useState(0);
  const [toRange2, setToRange2] = useState(0);
  const [toRange3, setToRange3] = useState(0);
  const [toRange4, setToRange4] = useState(0);
  const [toRange5, setToRange5] = useState(0);
  const [probability1, setProbability1] = useState(0);
  const [probability2, setProbability2] = useState(0);
  const [probability3, setProbability3] = useState(0);
  const [probability4, setProbability4] = useState(0);
  const [probability5, setProbability5] = useState(0);
  const [ratesWin, setRatesWin] = useState(0);
  const [firstLose, setRirstLose] = useState(0);
  const [ratioRev, setRatioRev] = useState(0);
  const [ratioBonus, setRatioBonus] = useState(0);
  const [ratioWinMax, setRatioWinMax] = useState(0);
  const [ratioWinMin, setRatioWinMin] = useState(0);

  useEffect(() => {
    if (!data) return;

    const valueRanges = data?.find((x) => x.key == "ranges")?.value || "";
    const valueProbabilities =
      data?.find((x) => x.key == "probabilities")?.value || "";
    const ranges = valueRanges?.split(",");
    const probabilities = valueProbabilities?.split(",");
    setFromRange1(ranges?.[0]?.split("-")[0]);
    setToRange1(ranges?.[0]?.split("-")[1]);
    setFromRange2(ranges?.[1]?.split("-")[0]);
    setToRange2(ranges?.[1]?.split("-")[1]);
    setFromRange3(ranges?.[2]?.split("-")[0]);
    setToRange3(ranges?.[2]?.split("-")[1]);
    setFromRange4(ranges?.[3]?.split("-")[0]);
    setToRange4(ranges?.[3]?.split("-")[1]);
    setFromRange5(ranges?.[4]?.split("-")[0]);
    setToRange5(ranges?.[4]?.split("-")[1]);
    setProbability1(probabilities?.[0] || 0);
    setProbability2(probabilities?.[1] || 0);
    setProbability3(probabilities?.[2] || 0);
    setProbability4(probabilities?.[3] || 0);
    setProbability5(probabilities?.[4] || 0);
  }, [data]);

  const showConfirm = (key) => {
    if (
      fromRange1 < 0 ||
      fromRange2 < 0 ||
      fromRange3 < 0 ||
      fromRange4 < 0 ||
      fromRange5 < 0 ||
      toRange1 < 0 ||
      toRange2 < 0 ||
      toRange3 < 0 ||
      toRange4 < 0 ||
      toRange5 < 0 ||
      ratesWin < 0 ||
      firstLose < 0 ||
      ratioRev < 0 ||
      ratioBonus < 0 ||
      ratioWinMax < 0 ||
      ratioWinMin < 0
    ) {
      alert("Giá trị không được nhỏ hơn 0");
      return;
    }
    let params;
    const id = data?.find((x) => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString(),
        };
        break;
      case "ratesWin":
        params = {
          key: "ratesWin",
          value: ratesWin.toString(),
        };
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: firstLose.toString(),
        };
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: ratioRev.toString(),
        };
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: ratioBonus.toString(),
        };
        break;
      case "ratio_win_max":
        params = {
          key: "ratio_win_max",
          value: ratioWinMax.toString(),
        };
        break;
      case "ratio_win_min":
        params = {
          key: "ratio_win_min",
          value: ratioWinMin.toString(),
        };
        break;
      case "probabilities":
        params = {
          key: "probabilities",
          value: `${probability1.toString()},${probability2.toString()},${probability3.toString()},${probability4.toString()},${probability5.toString()}`,
        };
        break;
      case "ranges":
        params = {
          key: "ranges",
          value: `${fromRange1.toString()}-${toRange1.toString()},${fromRange2.toString()}-${toRange2.toString()},${fromRange3.toString()}-${toRange3.toString()},${fromRange4.toString()}-${toRange4.toString()},${fromRange5.toString()}-${toRange5.toString()}`,
        };
        break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_LIMBO}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          reload();
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {data ? (
        <div
          className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={
                  data?.find((x) => x.key == "isMaintenance")?.value === "true"
                    ? true
                    : false
                }
                style={{ width: 60, marginTop: "5px" }}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>
          <div style={{ margin: "10px 0px" }} />
          {/* {renderIp(
            "ratesWin",
            data?.find((x) => x.key == "ratesWin")?.value,
            (e) => setRatesWin(e),
            () => showConfirm("ratesWin"),
            false
          )} */}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Thắng lần đầu (đơn vị 1 = 1000 VND)",
            data?.find((x) => x.key == "firstLose")?.value,
            (e) => setRirstLose(e),
            () => showConfirm("firstLose"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ Rev",
            data?.find((x) => x.key == "ratioRev")?.value,
            (e) => setRatioRev(e),
            () => showConfirm("ratioRev"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ bonus",
            data?.find((x) => x.key == "ratioBonus")?.value,
            (e) => setRatioBonus(e),
            () => showConfirm("ratioBonus"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng Max",
            data?.find((x) => x.key == "ratio_win_max")?.value,
            (e) => setRatioWinMax(e),
            () => showConfirm("ratio_win_max"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng Min",
            data?.find((x) => x.key == "ratio_win_min")?.value,
            (e) => setRatioWinMin(e),
            () => showConfirm("ratio_win_min"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />

          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}

          {/* <div className="col-12 flex">
            <div className="col-3 text-left">ranges</div>
            <div className="col-6">
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange1(value)}
                    value={Number(fromRange1)}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange1(value)}
                    value={Number(toRange1)}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange2(value)}
                    value={fromRange2}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange2(value)}
                    value={toRange2}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange3(value)}
                    value={fromRange3}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange3(value)}
                    value={toRange3}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange4(value)}
                    value={fromRange4}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange4(value)}
                    value={toRange4}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange5(value)}
                    value={fromRange5}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange5(value)}
                    value={toRange5}
                  />
                </div>
              </div>
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button type="primary" onClick={() => showConfirm("ranges")}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>

          <div style={{ margin: "10px 0px" }} />
          <div className="col-12 flex">
            <div className="col-3 text-left">probabilities</div>
            <div className="col-6">
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability1(value)}
                    value={probability1}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability2(value)}
                    value={probability2}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability3(value)}
                    value={probability3}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability4(value)}
                    value={probability4}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability5(value)}
                    value={probability5}
                  />
                </div>
              </div>
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("probabilities")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div> */}
        </div>
      ) : null}
    </>
  );
};

const renderIp = (
  label,
  defaultValue,
  onChangeIp,
  onUpdate,
  isHilo,
  onChangeIp2
) => {
  return (
    <div className="col-12 flex">
      <div className="col-3 text-left">{label}</div>
      <div className="col-6">
        <InputNumber
          style={{ width: "90%" }}
          min={0}
          onChange={onChangeIp}
          defaultValue={Number(defaultValue)}
        />
      </div>
      {hasRight([RIGHTS.EditSettingOriginals]) ? (
        <div className="col-3 text-left">
          <Button type="primary" onClick={onUpdate}>
            {TEXT_DEF.text0016}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default LimboSetting;
