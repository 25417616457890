import React, { useState, useEffect } from "react";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";

import { TEXT_DEF } from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import API, { ADMIN_TOWER } from "../../../network/api";

const TowerSetting = ({ data, auth, roleArr, reload }) => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [ratesWin, setRatesWin] = useState(0);
  const [firstLose, setRirstLose] = useState(0);
  const [ratioRev, setRatioRev] = useState(0);
  const [ratioBonus, setRatioBonus] = useState(0);
  const [ratesWinEasy, setRatesWinEasy] = useState(0);
  const [ratesWinBasic, setRatesWinBasic] = useState(0);
  const [ratesWinMedium, setRatesWinMedium] = useState(0);
  const [ratesWinHard, setRatesWinHard] = useState(0);
  const [ratesWinExtreme, setRatesWinExtreme] = useState(0);

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const showConfirm = (key) => {
    if (
      ratesWin < 0 ||
      firstLose < 0 ||
      ratioRev < 0 ||
      ratioBonus < 0 ||
      ratesWinEasy < 0 ||
      ratesWinBasic < 0 ||
      ratesWinMedium < 0 ||
      ratesWinHard < 0 ||
      ratesWinExtreme < 0
    ) {
      alert("Giá trị không được nhỏ hơn 0");
      return;
    }
    let params;
    debugger;
    const id = data?.find((x) => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString(),
        };
        break;
      case "ratesWin":
        params = {
          key: "ratesWin",
          value: ratesWin.toString(),
        };
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: firstLose.toString(),
        };
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: ratioRev.toString(),
        };
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: ratioBonus.toString(),
        };
        break;
      case "ratesWin_easy":
        params = {
          key: "ratesWin_easy",
          value: ratesWinEasy.toString(),
        };
        break;
      case "ratesWin_basic":
        params = {
          key: "ratesWin_basic",
          value: ratesWinBasic.toString(),
        };
        break;
      case "ratesWin_medium":
        params = {
          key: "ratesWin_medium",
          value: ratesWinMedium.toString(),
        };
        break;
      case "ratesWin_hard":
        params = {
          key: "ratesWin_hard",
          value: ratesWinHard.toString(),
        };
        break;
      case "ratesWin_extreme":
        params = {
          key: "ratesWin_extreme",
          value: ratesWinExtreme.toString(),
        };
        break;
    }
    debugger;
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_TOWER}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          reload();
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {data ? (
        <div
          className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={
                  data?.find((x) => x.key == "isMaintenance")?.value === "true"
                    ? true
                    : false
                }
                style={{ width: 60, marginTop: "5px" }}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng",
            data?.find((x) => x.key == "ratesWin")?.value,
            (e) => setRatesWin(e),
            () => showConfirm("ratesWin"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Thắng lần đầu (đơn vị 1 = 1000 VND)",
            data?.find((x) => x.key == "firstLose")?.value,
            (e) => setRirstLose(e),
            () => showConfirm("firstLose"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ Rev",
            data?.find((x) => x.key == "ratioRev")?.value,
            (e) => setRatioRev(e),
            () => showConfirm("ratioRev"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ bonus",
            data?.find((x) => x.key == "ratioBonus")?.value,
            (e) => setRatioBonus(e),
            () => showConfirm("ratioBonus"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng dễ",
            data?.find((x) => x.key == "ratesWin_easy")?.value,
            (e) => setRatesWinEasy(e),
            () => showConfirm("ratesWin_easy"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng cơ bản",
            data?.find((x) => x.key == "ratesWin_basic")?.value,
            (e) => setRatesWinBasic(e),
            () => showConfirm("ratesWin_basic"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng trung bình",
            data?.find((x) => x.key == "ratesWin_medium")?.value,
            (e) => setRatesWinMedium(e),
            () => showConfirm("ratesWin_medium"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng khó",
            data?.find((x) => x.key == "ratesWin_hard")?.value,
            (e) => setRatesWinHard(e),
            () => showConfirm("ratesWin_hard"),
            false
          )}
          <div style={{ margin: "10px 0px" }} />
          {renderIp(
            "Tỷ lệ thắng rất khó",
            data?.find((x) => x.key == "ratesWin_extreme")?.value,
            (e) => setRatesWinExtreme(e),
            () => showConfirm("ratesWin_extreme"),
            false
          )}

          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

const renderIp = (
  label,
  defaultValue,
  onChangeIp,
  onUpdate,
  isHilo,
  onChangeIp2
) => {
  return (
    <div className="col-12 flex">
      <div className="col-3 text-left">{label}</div>
      <div className="col-6">
        <InputNumber
          style={{ width: "90%" }}
          min={0}
          onChange={onChangeIp}
          defaultValue={Number(defaultValue)}
        />
      </div>
      {hasRight([RIGHTS.EditSettingOriginals]) ? (
        <div className="col-3 text-left">
          <Button type="primary" onClick={onUpdate}>
            {TEXT_DEF.text0016}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default TowerSetting;
