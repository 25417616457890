import { Button } from "antd";
import { TEXT_DEF } from "./text";

export const LIST_CYCLE = {
  ticket_1_time_cycle: "Chu kỳ mở thưởng",
  tick_1_multiple: "Số vòng cược",
  tick_1_point_draw_n: "Số lần quay để điều chỉnh tỉ lệ ra Min",
  tick_1_checkin_to: "Phút kết thúc nhận vé trong khung giờ",
  tick_1_checkin_from: "Phút đắt đầu nhận vé trong khung giờ",
  tick_1_point_max: "Điểm thưởng Max",
  tick_1_point_min: "Điểm thưởng Min",
};

export const DAYS_WEEK = [
  {
    value: "1",
    label: "Thứ 2",
  },
  {
    value: "2",
    label: "Thứ 3",
  },
  {
    value: "3",
    label: "Thứ 4",
  },
  {
    value: "4",
    label: "Thứ 5",
  },
  {
    value: "5",
    label: "Thứ 6",
  },
  {
    value: "6",
    label: "Thứ 7",
  },
  {
    value: "0",
    label: "Chủ Nhật",
  },
];

export const SETTING_SYTEMS = [
  "tick_1_edit",
  "tick_2_edit",
  "tick_3_checkin_edit",
];

export const SETTING_HIS = [
  "tick_1_view",
  "tick_2_view",
  "tick_3_checkin_view",
  "tick_1_edit",
  "tick_2_edit",
  "tick_3_checkin_edit",
  "tick_3_award_view",
  "tick_3_award_edit",
];

export const PERMISSION_HIS_3 = [
  "tick_3_checkin_view",
  "tick_3_checkin_edit",
  "tick_3_fake_view",
  "tick_3_fake_edit",
  "tick_3_award_view",
  "tick_3_award_edit",
];

export const transtype = {
  deposit: "Đào coin",
  withdrawal: "Rút coin",
  transfer: "Chuyển coin",
  refund: "Đền coin",
  payment: "Thanh toán",
  points_redemption: "Điểm quy đổi",
  points_earn: "Điểm lấy được",
  fee: "Phí",
  adjustment: "Điều chỉnh coin",
  expense: "Chi phí",
  interest: "Quan tâm",
};

export const methods = {
  sub_wallet_to_wallet: "Chuyển coin từ ví con sang ví chính",
  wallet_to_sub_wallet: "Chuyển coin từ ví chính sang ví con",
  revenue_to_wallet: "User đào coin",
  admin_to_plus: "Admin bù coin",
  admin_to_minus: "Admin trừ coin",
  user_mini_game: "Chơi mini game",
};

export const HEAD_TABLE = [
  {
    title: TEXT_DEF.text0002,
    key: "username",
    dataIndex: "username",
  },
  {
    title: TEXT_DEF.code_wallet,
    key: "walletCode",
    dataIndex: "walletCode",
  },
  {
    title: TEXT_DEF.code_wallet_sub,
    dataIndex: "subWalletCode",
    key: "subWalletCode",
  },

  {
    title: TEXT_DEF.number_coin,
    key: "amount",
    dataIndex: "amount",
  },

  {
    title: TEXT_DEF.deposit_money,
    key: "deposit",
    dataIndex: "deposit",
  },
  {
    title: TEXT_DEF.revenue,
    key: "revenue",
    dataIndex: "revenue",
  },
  // {
  //   title: TEXT_DEF.rate,
  //   key: "rate",
  //   dataIndex: "rate",
  // },
  {
    title: TEXT_DEF.ft,
    key: "ft",
    dataIndex: "ft",
  },
  {
    title: TEXT_DEF.transRef1,
    key: "transRef1",
    dataIndex: "transRef1",
  },
  {
    title: TEXT_DEF.transType,
    key: "transType",
    dataIndex: "transType",
  },
  {
    title: TEXT_DEF.method,
    key: "method",
    dataIndex: "method",
  },
  {
    title: TEXT_DEF.status,
    key: "status",
    dataIndex: "status",
  },
  {
    title: TEXT_DEF.note,
    key: "note",
    dataIndex: "note",
  },
  // {
  //   title: TEXT_DEF.admin_note,
  //   key: "apptoveNote",
  //   dataIndex: "apptoveNote",
  // },
  {
    title: TEXT_DEF.errorReason,
    key: "errorReason",
    dataIndex: "errorReason",
  },
  {
    title: TEXT_DEF.time_transition,
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: TEXT_DEF.createdBy,
    key: "createdBy",
    dataIndex: "createdBy",
  },
];

export const HEAD_TABLE_WALLET = [
  {
    title: TEXT_DEF.text0002,
    key: "username",
    dataIndex: "username",
  },
  {
    title: TEXT_DEF.dayBy,
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: TEXT_DEF.updatedAt,
    key: "updatedAt",
    dataIndex: "updatedAt",
  },
  {
    title: TEXT_DEF.code_wallet,
    key: "walletCode",
    dataIndex: "walletCode",
  },
  {
    title: TEXT_DEF.ft,
    key: "ft",
    dataIndex: "ft",
  },
  {
    title: "Mô tả",
    key: "description",
    dataIndex: "description",
  },
  {
    title: TEXT_DEF.availableBalance,
    key: "availableBalance",
    dataIndex: "availableBalance",
  },
  {
    title: TEXT_DEF.adjustment,
    key: "adjustment",
    dataIndex: "adjustment",
  },
  // {
  //   title: TEXT_DEF.mainedCoin,
  //   key: "minedCoin",
  //   dataIndex: "minedCoin",
  // },
  {
    title: TEXT_DEF.totalUsedAmount,
    key: "totalUsedAmount",
    dataIndex: "totalUsedAmount",
  },
  {
    title: TEXT_DEF.totalBalance,
    key: "totalEarn",
    dataIndex: "totalEarn",
  },
  {
    title: TEXT_DEF.totalRevenue,
    key: "totalRevenue",
    dataIndex: "totalRevenue",
  },
  {
    title: TEXT_DEF.user_admin,
    key: "updatedBy",
    dataIndex: "updatedBy",
  },
];

export const STATUS_COINS = [
  {
    label: "Bù Coins",
    value: 1,
  },
  {
    label: "Trừ Coins",
    value: 2,
  },
];

export const STATUS_METHOD = {
  admin_to_plus: "Bù coins",
  admin_to_minus: "Trừ coins",
};

export const HEAD_TABLE_WALLET_sub = [
  {
    title: TEXT_DEF.text0002,
    key: "username",
    dataIndex: "username",
  },
  {
    title: TEXT_DEF.dayBy,
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: TEXT_DEF.updatedAt,
    key: "updatedAt",
    dataIndex: "updatedAt",
  },
  {
    title: TEXT_DEF.code_wallet,
    key: "walletCode",
    dataIndex: "walletCode",
  },
  {
    title: TEXT_DEF.ft,
    key: "ft",
    dataIndex: "ft",
  },
  {
    title: "Mô tả",
    key: "description",
    dataIndex: "description",
  },
  {
    title: TEXT_DEF.code_wallet_sub,
    key: "subWalletCode",
    dataIndex: "subWalletCode",
  },
  {
    title: TEXT_DEF.game,
    key: "gameCode",
    dataIndex: "gameCode",
  },
  // {
  //   title: TEXT_DEF.balance,
  //   dataIndex: "balance",
  //   key: "balance",
  // },

  {
    title: TEXT_DEF.sub_wallet_number,
    key: "availableBalance",
    dataIndex: "availableBalance",
  },
  {
    title: TEXT_DEF.totalUsedAmount,
    key: "totalUsedAmount",
    dataIndex: "totalUsedAmount",
  },
  {
    title: TEXT_DEF.user_admin,
    key: "createdBy",
    dataIndex: "createdBy",
  },
];

export const MANAGER_PESMISSION = [
  "wallet_view",
  "wallet_view_update",
  // "sub_wallet_view",
  // "sub_wallet_update",
  "request_top_up",
];
