import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Form, Input, Button, Select, message } from "antd";
import API, { REGISTER, BOOKMAKER, ROLES } from "../../network/api";
import { TEXT_DEF, CREATE_SUCCESS, CREATE_FAIL, GET_DATA_FAIL } from "../../constant/text";

const CreateAdminUser = () => {
  const { Option } = Select;
  const limit = 10;
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const indexTabLocal = localStorage.getItem("indexLocalCreate") || 2;
  const [errorMess, setErrorMess] = useState();
  const [bookmarkers, setBookmarkers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    forceUpdate({});
    getBookmarkers();
    getRoles();
  }, []);

  const createUser = async () => {
    const body = {
      username: form.getFieldValue('username'),
      bookmakerId: form.getFieldValue('bookmarker'),
      roleAdminUserId: form.getFieldValue('role'),
    };

    try {
      setLoading(true);
      const result = await API.post(REGISTER, body);
      const message = _.get(result, 'data.data.result', '');
      const code = _.get(result, 'data.data.code', 0);
      if (code === 40000) {
        setMessageError(message);
        return;
      } else {
        setMessageError('');
      }
      navigate("/account-manager");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast(CREATE_FAIL);
    }
  }

  const getRoles = async () => {
    try {
      setLoading(true);
      let roles = [];
      let page = 1;
      while (true) {
        const result = await API.get(`${ROLES}?page=${page}&limit=${limit}`);
        const data = _.get(result, 'data.data.roles', []);
        if (data.length < limit) {
          roles = roles.concat(data);
          break;
        }
        page++;
      }
      setRoles(roles);
      if (roles.length > 0) {
        initFormValues({
          role: roles?.[0]?.id || '',
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.key,
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;

    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
      });
    }
  }

  const renderFormByUser = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={createUser}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", color: "red" }}
          >
            <div className="col-3"></div>
            <div className="col-9">{errorMess}</div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {`${TEXT_DEF.text0002}`} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
              <div className="message-error">{messageError}</div>
            </div>
          </div>

          <div style={{ marginBottom: 30 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {`${TEXT_DEF.text0011}`} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="bookmarker"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                >
                  {bookmarkers.map((item) => (
                    <Option key={item.key} value={item.key}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", color: "red" }}
          >
            <div className="col-3"></div>
            <div className="col-9">{errorMess}</div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              Roles <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                >
                  {roles.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item style={{ width: "100%" }}>
            <div className="lg-btn">
              <Button
                type="primary"
                htmlType="submit"
              >
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    );
  };

  return (
    <div className="col-12">
      {renderFormByUser()}
    </div>
  );
};

export default CreateAdminUser;
