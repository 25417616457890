import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Select } from 'antd';
import API, { USER, RANK } from '../../network/api';
import './style.css'
import { TEXT_DEF } from '../../constant/text';

const ModalLevel = ({isShow, user, close, realoadPage, setIsRealoadPage}) => {
    const [userInfo, setUserInfo] = useState();
    const [rankSl, setRankSl] = useState();
    const [rankList, setRankList] = useState();
    let isRequest = useRef(false);

    useEffect(() => {
        const getUserInfo = async () => {
            await API.get(`${RANK}`)
                .then((response) => {
                    const result = response.data?.data;
                    if (result) {
                        const dataRanks = []
                        for (const item of result) {
                            const rank = {
                                id: item?.id,
                                value: item?.id,
                                label: item?.rankName,
                            }
                            dataRanks.push(rank)
                        }
                        setRankList(dataRanks);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getUserInfo()
    }, [])


    const pathUpdate = async () => {
        if (isRequest.current) {
            return;
        };

        isRequest.current = true;
        const param = {
            rankId: rankSl ? rankSl : rankList?.find(x => x.label === user?.level).value,
        }
        // realoadPage(true)
        await API.patch(`${USER}/change-level-user/${user?.userId}`, param)
            .then((response) => {
                const codeMess = response.data?.data?.result?.code;
                if (codeMess === 20004) {
                    setIsRealoadPage(true);
                    close();
                }
                isRequest.current = false;
            }).catch((error) => {
                console.log(error);
                isRequest.current = false;
            });
    }
    
    return (
        <Modal show={isShow} className='modal-update-nickname' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{"Đổi level"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {rankList && <Select
                    showSearch
                    placeholder="Chọn level"
                    optionFilterProp="children"
                    onChange={(val) => setRankSl(val)}
                    defaultValue={rankList?.find(x => x.label === user?.level).value}
                    options={rankList}
                    allowClear
                    style={{ width: '80%' }}
                />}
            </Modal.Body>
            <Modal.Footer>
                <Button type="primary" onClick={pathUpdate}>
                    {TEXT_DEF.text0016}
                </Button>
                <Button type="default" onClick={close}>
                    {TEXT_DEF.text0007}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalLevel;