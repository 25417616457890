import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from 'react-toastify';
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Popconfirm,
} from "antd";
import API, { BOOKMAKER } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import {
  TEXT_DEF,
  DELETE_SUCCESS,
  DELETE_FAIL,
} from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import deteleIcon from "../../image/delete.png";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import { QuestionCircleOutlined } from '@ant-design/icons';

const BookmakerList = () => {
  let isRequest = useRef(false);
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataList, setDataList] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [idField, setIdField] = useState();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [recordSl, setRecordSl] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (indexTabLocal === 1) {
      if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("user_view") || roleArr?.indexOf("user_edit"))
      ) {
        setIndexTab(1);
      } else if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("admin_permissions_view") ||
          roleArr?.indexOf("admin_permissions_edit"))
      ) {
        setIndexTab(2);
      }
    }
  }, []);

  useEffect(() => {
    getListDataUser();
  }, [page, indexTab]);

  const getListDataUser = async () => {
    setLoading(true);
    const dataList1 = API.get(
      `${BOOKMAKER}?skip=${page}`
    );

    await Promise.all([dataList1])
      .then((response) => {
        const res = response[0].data.data.result[0];
        if (res) {
          let data = [];
          res?.map((item) => {
            const newItem = {
              key: item?.id,
              name: item?.name,
              gameReg: item?.gameReg,
              createdAt: item?.createdAt,
              updatedAt: item?.updatedAt,
              updatedBy: item?.updatedBy,
            };
            data.push(newItem);
          });

          setDataList(data);
          setTotalRecord(response[0].data.data.result[1]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const handleShowModal = (id) => {
    setIsShowModalConfirm(true);
    setIdField(id);
  };

  const confirm = async (id) => {
    try {
        await API.delete(`${BOOKMAKER}/${id}`);
        await getListDataUser();
        toast(DELETE_SUCCESS);
    } catch (err) {
        setLoading(false);
        toast(DELETE_FAIL);
    }
  };

  const handleDeleteAccount = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    try {
      setIsShowModalConfirm(false);
      await API.delete(`${BOOKMAKER}/${recordSl.key}`);
      await getListDataUser();
      setRecordSl(undefined);
      isRequest.current = false;
    } catch (err) {
      console.log(err);
      isRequest.current = false;
    } finally {
      isRequest.current = false;
      setIsShowModalSuccess(true);
    }
  };

  const colums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(page - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: "id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: TEXT_DEF.text0012,
    //   dataIndex: "gameReg",
    //   key: "gameReg",
    // },
    {
      title: TEXT_DEF.text0013,
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    // {
    //   title: TEXT_DEF.text0014,
    //   key: "updatedAt",
    //   render: (record) =>
    //     moment(record?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    // },
    // {
    //   title: TEXT_DEF.text0015,
    //   dataIndex: "updatedBy",
    //   key: "updatedBy",
    // },
    // {
    //   title: TEXT_DEF.text0016,
    //   key: "setting",
    //   align: "center",
    //   render: (record) =>
    //     <div>
    //       {
    //         hasRight([RIGHTS.EditBookmarker]) ? (
    //           <div className="flex w-full items-center justify-center">
    //             <img
    //               style={{ height: 20, cursor: "pointer" }}
    //               src={settingIcon}
    //               alt="setting"
    //               onClick={() => {
    //                 localStorage.setItem("indexLocalEdit", "2");
    //                 navigate(`/updateBookmaker/${record.key}`);
    //               }}
    //             />
    //           </div>
    //         ) : null
    //       }
    //     </div>
    // },
    {
      title: TEXT_DEF.text0014,
      key: "updatedAt",
      render: (record) =>
        moment(record?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Action",
      key: "action",
      render: (record) =>
        <div className="flex w-full items-center justify-center">
          {
            hasRight([RIGHTS.EditBookmarker]) ? (
              <Button
                className="edit"
                onClick={() => {
                    navigate(`/updateBookmaker/${record.key}`);
                }}
                danger
              >
                Edit
              </Button>
            ) : null
          }
          {
            hasRight([RIGHTS.DeleteBookmarker]) ? (
              <div>
                <Popconfirm
                    title="Delete the task"
                    description={TEXT_DEF.text0069}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => { confirm(record.key) }}
                    onCancel={cancel}
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
              </div>
            ) : null
          }
        </div>
    },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const cancel = (e) => { };

  const addNewData = () => {
    navigate("/createBookmaker");
  };

  const renderAccUser = () => {
    return (
      <>
        {
          hasRight([RIGHTS.CreateBookmarker]) ? (
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
              <Button type="primary" onClick={addNewData}>
                {TEXT_DEF.text0018}
              </Button>
            </div>
          ) : null
        }

        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={colums}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={page}
              total={totalRecord}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderAccUser()}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0069}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0009}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {/* {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          // message={TEXT_DEF.text_modal_success}
          message={TEXT_DEF.text0069}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )} */}
    </div>
  );
};

export default BookmakerList;
