import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { ADMIN_ORDERS, BOOKMAKER, ADMIN_DASHBOARD } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import { 
  Chart as ChartJS, 
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { getGame } from "../../components/until";
import ModalChart from "./modal"; 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  // const [dateFrom, setDateFrom] = useState();
  // const [dateTo, setDateTo] = useState();
  // const [dataList, setDataList] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [idField, setIdField] = useState();
  
  const [typeSl, setTypeSl] = useState("day");
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  // const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const [dataUserChart, setDataUserChart] = useState(undefined);
  const [dataOrdersChart, setDataOrdersChart] = useState(undefined);
  const [dataBetChart, setDataBetChart] = useState(undefined);
  const [dataProfitChart, setDataProfitChart] = useState(undefined);
  const [dataGameCountChart, setDataGameCountChart] = useState(undefined);
  const [dataGameProfitChart, setDataGameProfitChart] = useState(undefined);

  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);

  const options = {
    plugins: {
      filler: {
        propagate: false,
      },
      title: {
        display: true,
      }
    },
    interaction: {
      intersect: false,
    }
};

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataDashboardUser = async () => {
      const keyword = auth?.permissions !== "super" ? 
        encodeURIComponent(JSON.stringify({ typeFilter: "day", bookmaker: auth?.bookmakerId })) :
        encodeURIComponent(JSON.stringify({ typeFilter: "day" }))
      await API.get(`${ADMIN_DASHBOARD}/chart-user?keyword=${keyword}`)
      .then((response) => {
        let result = response.data?.data?.result;
        if (result) {
          result = result.length > 5 ? result.slice(result.length - 5, result.length) : result;
          const labelDay = result?.map(item => item?.timeFilter);
          const dataCountUser = result?.map(item => item?.count);
          const dataChartUser = {
            labels: labelDay,
            datasets: [
              {
                label: "Số lượng hội viên",
                data: dataCountUser,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ]
          }
          setDataUserChart(dataChartUser);
        }
      }).catch((error) => {
        console.log(error);
      });
    }

    const getDataDashboardOrders = async () => {
      const keyword = auth?.permissions !== "super" ? 
        encodeURIComponent(JSON.stringify({ typeFilter: "day", bookmaker: auth?.bookmakerId })) :
        encodeURIComponent(JSON.stringify({ typeFilter: "day" }))
      await API.get(`${ADMIN_DASHBOARD}/chart-order-all?keyword=${keyword}`)
      .then((response) => {
        let result = response.data?.data?.result;
        if (result) {
          result = result.length > 5 ? result.slice(result.length - 5, result.length) : result;
          const labelDay = result?.map(item => item?.timeFilter);
          const dataCountOrder = result?.map(item => item?.count);
          const dataChartCountOrder = {
            labels: labelDay,
            datasets: [
              {
                label: "Số lượng đơn cược",
                data: dataCountOrder,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ]
          }
          setDataOrdersChart(dataChartCountOrder);

          const dataTotalBet = result?.map(item => item?.totalBet);
          let dataTotalReturn = result?.map(item => item?.totalReturn);
          if (dataTotalReturn[0] == undefined) {
            dataTotalReturn = []
            result?.map(item => {
              const totalReturn = item.totalBet - (item.totalPaymentWin * -1)
              dataTotalReturn.push(totalReturn);
            })
          }

          const dataChartTotalBet = {
            labels: labelDay,
            datasets: [
              {
                label: "Tiền cược",
                data: dataTotalBet,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
              {
                label: "Tiền trả lại",
                data: dataTotalReturn,
                backgroundColor: 'rgba(245, 39, 39, 0.5)',
              }
            ]
          }
          setDataBetChart(dataChartTotalBet)

          const dataTotalProfit = result?.map(item => Number(item?.totalPaymentWin) * -1);
          const dataChartTotalProfit = {
            labels: labelDay,
            datasets: [
              {
                label: "Lợi nhuận",
                data: dataTotalProfit,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ]
          }
          setDataProfitChart(dataChartTotalProfit)
        }
      }).catch((error) => {
        console.log(error);
      });
    }

    getDataDashboardUser();
    getDataDashboardOrders();
  }, [])

  const getRandomColor = (index) => {
    // Sử dụng hàm Math.sin để tạo giá trị giả mạo từ -1 đến 1 dựa trên chỉ số
    const pseudoRandom = Math.sin(index) * 10000;
    // Chuyển đổi giá trị giả mạo thành một số nguyên
    const integerPart = Math.floor(pseudoRandom);
    // Chuyển đổi số nguyên thành một màu hex
    const color = (integerPart & 0xFFFFFF).toString(16);
  
    // Đảm bảo màu có đúng 6 ký tự và thêm đầu 0 nếu cần
    return `#${"000000".slice(0, 6 - color.length)}${color}`;
  }

  const onSearch = () => {
    let params = {};
    if (bookmakerSl) {
      params.bookmaker = bookmakerSl;
    }
    
    if (typeSl) {
      params.type = typeSl;
    }

    // if (dateFrom) {
    //   params.startDate = moment(dateFrom).format("YYYY-MM-DD");
    //   if (!dateTo) params.endDate = "2125-12-31";
    // }
    // if (dateTo) {
    //   if (!dateFrom) params.endDate = "2023-01-01";
    //   params.endDate = moment(dateTo).format("YYYY-MM-DD");
    // }

    setPage(1);

    if (params?.username || params.type) {
      setDataFilter(params);
    } else {
      setDataFilter(null);
    }
  };

  const handleShowModal = (id) => {
    setIsShowModalConfirm(true);
    setIdField(id);
  };

  const dollarUS = Intl.NumberFormat("en-US");

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const showDataModal = async (type) => {
    setLoading(true);
    await API.get(`${ADMIN_ORDERS}/chart-detail?bookmakerId=${dataFilter?.bookmaker ? dataFilter?.bookmaker : auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? auth?.bookmakerId : '0'}&timeFillter=day`)
    .then((response) => {
      const result = response.data?.data?.result;
      if (result) {
        const labelList = result?.map(item => item?.timeFilter);
        const dataReport = 
          type === "count" ? result?.map(item => Number(item?.count)) :
          type === "bet" ? result?.map(item => Number(item?.totalBet)) :
          result?.map(item => Number(item?.totalPaymentWin) * -1)
        const dataChart = {
          labels: labelList,
          datasets: [
            {
              label: type === "count" ? "Số lượng đơn cược" : type === "bet" ? "Tiền cược" : "Doanh thu",
              data: dataReport,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setTitleChartModal(
          type === "count" ? "Biều đồ chi tiết số lượng đơn cược" : 
          type === "bet" ? "Biều đồ chi tiết tiền cược" : 
          "Biều đồ chi tiết doanh thu"
        );
        setDataChartModal(dataChart);
        setIsShowModalChart(true);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  const renderCharts = () => {
    return (
      <>
        {auth?.role === "supper" && <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    onChange={(val) => setBookmakerSl(val)}
                    // onSearch={onSearch}
                    filterOption={filterOptionBm}
                    options={opBookmaker}
                    allowClear
                  />
                </Form.Item>
              </div>
              {/* <div className="col-4">
                <Form.Item name="typeFilter" label={TEXT_DEF.text0096}>
                  <Select onChange={(val) => setTypeSl(val)} allowClear>
                    <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                    <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                    <Select.Option value="year">{TEXT_DEF.text0091}</Select.Option>
                  </Select>
                </Form.Item>
              </div> */}
              {/* <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div> */}
              {/* <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div> */}
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>
        </div> }
        <Spin spinning={loading} delay={500} size="large">
          {dataUserChart &&
            <div className="col-12 flex mt-4" style={{ alignItems: 'center' }}>
              <div className="col-2">{TEXT_DEF.text0099}:</div>
              <div className="col-5">
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataUserChart} />
                </div>
              </div>
            </div>
          }
          {dataOrdersChart &&
            <div className="col-12 flex mt-4" style={{ alignItems: 'center' }}>
              <div className="col-2">{TEXT_DEF.text0092}:</div>
              <div className="col-5">
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataOrdersChart} />
                </div>
              </div>
              <div className="col-3">
                <Button htmlType="submit" type="primary" onClick={() => showDataModal("count")}>
                  {"Xem biều đồ chi tiết"}
                </Button>
              </div>
            </div>
          }
          {dataBetChart &&
            <div className="col-12 flex mt-4" style={{ alignItems: 'center' }}>
              <div className="col-2">{TEXT_DEF.text0093}:</div>
              <div className="col-5">
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataBetChart} />
                </div>
              </div>
              <div className="col-3">
                <Button htmlType="submit" type="primary" onClick={() => showDataModal("bet")}>
                  {"Xem biều đồ chi tiết"}
                </Button>
              </div>
            </div>
          }
          {dataProfitChart &&
            <div className="col-12 flex mt-4" style={{ alignItems: 'center' }}>
              <div className="col-2">{TEXT_DEF.text0094}:</div>
              <div className="col-5">
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataProfitChart} />
                </div>
              </div>
              <div className="col-3">
                <Button htmlType="submit" type="primary" onClick={() => showDataModal("profit")}>
                  {"Xem biều đồ chi tiết"}
                </Button>
              </div>
            </div>
          }
          {dataGameCountChart && dataGameProfitChart &&
            <div className="col-12 flex mt-4" style={{ alignItems: 'center' }}>
              <div className="col-2">{TEXT_DEF.text0100}:</div>
              <div className="col-5 flex">
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataGameCountChart} />
                </div>
                <div style={{ width: 230, height: 230 }}>
                  <Pie data={dataGameProfitChart} />
                </div>
              </div>
            </div>
          }
        </Spin>
      </>
    );
  };

  const setDataFillterChart = (type) => {
    // const dataFilter = {
    //   bookmakerId: auth?.role === "supper" || auth?.role === "administrator" ? 0 : auth?.bookmakerId,
    //   typeChart: type
    // }
    // localStorage.setItem("dataFilterChart", JSON.stringify(dataFilter))
    navigate("/report-by-bookmaker");
  }

  const renderLayout = (title, dataChart, type) => {
    return (
      <>
        <div
          className="col-5"
          style={{ marginBottom: 15, display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ marginBottom: 15, display: "flex", fontSize: 18, fontWeight: "bolder" }}>
            {title}
          </div>
          <Bar options={options}  data={dataChart} />
          <Button className="mt-8" htmlType="submit" type="primary" onClick={() => setDataFillterChart(type)}>
            {"Xem thêm"}
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="col-12">
      <h3 style={{ marginBottom: 30 }}>Tổng hợp số liệu 5 ngày gần nhất</h3>
      <div className="col-12" style={{ display: "flex", flexDirection: 'row' }}>
        {dataUserChart && renderLayout(TEXT_DEF.text0115, dataUserChart, "user")}
        <div className="col-2" style={{  }}/>
        {dataOrdersChart && renderLayout(TEXT_DEF.text0092, dataOrdersChart, "order")}
      </div>
      <div className="col-12" style={{ display: "flex", flexDirection: 'row' }}>
        {dataBetChart && renderLayout(TEXT_DEF.text0093, dataBetChart, "bet")}
        <div className="col-2" style={{  }}/>
        {dataProfitChart && renderLayout(TEXT_DEF.text0094, dataProfitChart, "profit")}
      </div>
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
      {/* {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_delete_user}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )} */}
    </div>
  );
};

export default Dashboard;
