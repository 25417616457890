import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './pages/main';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
// import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { TabsProvider } from './contexts/TabContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <BrowserRouter>
      <TabsProvider>
        <Main />
      </TabsProvider>
    </BrowserRouter>

);

// reportWebVitals();
