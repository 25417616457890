import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'antd';
import './style.css'

const ModalCommon = ({isShow, message, txtBtnAcc, textBtnClose, actionNext, close}) => {
    
    return (
        <Modal show={isShow} className='modal-all' onHide={close}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                {textBtnClose ? 
                    <>
                        <Button type="primary" onClick={actionNext}>
                            {txtBtnAcc}
                        </Button>
                        <Button type="default" onClick={close}>
                            {textBtnClose}
                        </Button>
                    </>
                :
                    <Button type="primary" onClick={actionNext}>
                        {txtBtnAcc}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCommon