import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select,
} from "antd";
import API, { WALLET_INOUT, BOOKMAKER } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { TEXT_DEF } from "../../constant/text";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import dayjs from 'dayjs';
import { CopyOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { disabledTimeFrom, disabledTimeTo, validateDateRange } from "../../utils/dateUtils";

const { Option } = Select;

// import DetailModal from "./detailModal";

const UserPlayHis = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState();
  const [loading, setLoading] = useState(false);
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pagePlayHis, setPagePlayHis] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecordPlayHis, setTotalRecordPlayHis] = useState(0);
  const [dataFilter, setDataFilter] = useState();
  const [dataPlayHis, setDataPlayHis] = useState();
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const [isTestPlayer, setIsTestPlayer] = useState(false);
  const [typeTransaction, setTypeTransaction] = useState();
  const [opTypeTransaction, setOpTypeTransaction] = useState();
  const [nccSelected, setNccSelected] = useState();
  const [opNcc, setOpNcc] = useState();
  const location = useLocation();
  const state = location.state;

  useEffect(()=> {
    if(state) {
        const {user} = state;
        if(user) {
          if(opBookmaker) {
            const bookmakerA = opBookmaker.find(item => item.label == user.bookmaker);
            form.setFieldValue("bookmakerFilter", bookmakerA.value)
            setBookmakerSl(bookmakerA.value)
          }
          user.isTestPlayer == "Chơi thật" ? setIsTestPlayer(false) : setIsTestPlayer(true)
          form.setFieldValue("usernameFilter", user.username)
          onSearch();
          setTimeout(() => {
            navigate(location.pathname, { replace: true, state: null });
          }, 2000);
      }
    }
  }, [opBookmaker])

  useEffect(() => {
    if(state) {
      const {user} = state;
      if(user) {
        const initialDateFrom = user.dateFrom ? dayjs(user.dateFrom, 'YYYY-MM-DD') : dayjs(user.createdAt);
        const initialDateTo = dayjs();
        form.setFieldsValue({
          dateFrom: initialDateFrom,
          dateTo: initialDateTo,
        });
  
        setDateFrom(initialDateFrom);
        setDateTo(initialDateTo);
      }
      
    }
  },[])

  const nccDf = [
    {
      key: 'ncc-0',
      value: '',
      label: 'Tất cả'
    },
    {
      key: 'ncc-1',
      value: 'VnTop',
      label: 'VnTop'
    },
    {
      key: 'ncc-2',
      value: 'Xổ Số',
      label: 'Xổ Số'
    },
    {
      key: 'ncc-3',
      value: 'Game Việt',
      label: 'Game Việt'
    },
  ]

  useEffect(() => {
    forceUpdate({});
    setOpTypeTransaction([
      {
        key: 'type-trans-0',
        value: '',
        label: 'Tất cả'
      },
      {
        key: 'type-trans-1',
        value: 'Chơi game',
        label: "Chơi game"
      },
      {
        key: 'type-trans-2',
        value: 'Chuyển tiền',
        label: 'Chuyển tiền'
      },
      {
        key: 'type-trans-3',
        value: 'Khuyến mãi',
        label: 'Khuyến mãi'
      },
    ])
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataBookmaker = [];
            const nccBm = [];
            result?.map((item, index) => {
              const newIt = {
                key: item?.id,
                value: item?.id,
                label: item?.name
              }
              dataBookmaker.push(newIt);

              const newNcc = {
                key: `ncc-${nccDf?.length + index}`,
                value: item?.id,
                label: item?.name
              }
              nccBm.push(newNcc)
            });
            let nccNew = nccDf.concat(nccBm);
            const bookmarkerId = auth?.bookmakerId;
            if (bookmarkerId) {
              nccNew = nccNew.filter(n => n.value == bookmarkerId);
            }
            setOpNcc(nccNew);
            setOpBookmaker(dataBookmaker);
          }
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    getDataBookmaker();
  }, [])

  useEffect(() => {
    // const keyword = dataFilter
    //   ? dataFilter
    //   : auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? encodeURIComponent(
    //     JSON.stringify({ bookmakerId: auth?.bookmakerId })) :
    //     "null"
    const keyword = dataFilter ? dataFilter : '';
    const getDataTable = async () => {
      setLoading(true);
      await API.get(`${WALLET_INOUT}/history?take=${pageSize}&skip=${pagePlayHis}&order=DESC&keyword=${keyword}`)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataList = [];
            result?.map((item) => {
              const balanceBefore = item?.subOrAdd === 0 ?
                Number(item?.balance) + Number(item?.amount) :
                Number(item?.balance) - Number(item?.amount);
              const newIt = {
                key: item?.hisId,
                code: item?.code,
                typeTransaction: item?.typeTransaction,
                nccNote: item?.nccNote,
                username: item?.user?.username,
                bookmaker: item?.user?.bookmaker?.name,
                nickname: item?.user?.userInfo?.nickname,
                createdAt: item?.createdAt,
                balance: dollarUS.format(Number(item?.balance)),
                amount: item.subOrAdd !== null ? `${item?.subOrAdd === 0 ? '-' : '+'}${dollarUS.format(Number(item?.amount))}` : '',
                detail: item?.detail,
                isTestPlayer: item?.user?.usernameReal === "" ? 'Chơi thật' : 'Chơi thử',
                balanceBefore: dollarUS.format(balanceBefore)
              };
              dataList.push(newIt);
            });

            setDataPlayHis(dataList);
          }
          setTotalRecordPlayHis(response.data?.data?.result[1]);
          setLoading(false);
          setIsSubmitSearch(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
          setIsSubmitSearch(false);
        });
    }

    if (isSubmitSearch) {
      getDataTable();
    }
  }, [pagePlayHis, dataFilter, pageSize])

  const locale = { emptyText: TEXT_DEF.text0043 };

  const dollarUS = Intl.NumberFormat("en-US");

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const disabledDate = (currentDate) => {
		const threeMonthsAgo = moment().subtract(3, "months");
    return currentDate && (currentDate < threeMonthsAgo || currentDate > moment());
	}

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
      else setDateTo(moment(e.$d).format("yyyy-MM-DD HH:mm:ss"));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePagePlayHis = (page, size) => {
    setIsSubmitSearch(true);
    setPagePlayHis(page);
    setPageSize(size);
  };

  const onSearch = () => {
    makeParams()
    setPagePlayHis(1)
    setIsSubmitSearch(true);
  };

  const  fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      alert('Sap chép thành công');
    } catch (err) {
      alert('Sao chép thất bại: ', err);
    }
    document.body.removeChild(textArea);
}

const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Sao chép thành công');
            })
            .catch(err => {
              alert('Sao chép thất bại: ', err);
            });
    } else {
        fallbackCopyToClipboard(text);
    }
}


  const  makeParams = () => {

    if(!dateFrom || !dateTo) {
      return;
    }

    let params = auth?.role === 'admin_bm' || auth?.role === "administrators_bm" ? { bookmakerId: auth?.bookmakerId } : {};
    if (form.getFieldValue('usernameFilter')) params.username = form.getFieldValue('usernameFilter').trim().toLowerCase();
    if (form.getFieldValue('nicknameFilter')) {
      params.nickname = form.getFieldValue('nicknameFilter').trim().toLowerCase();
    }
    if (dateFrom) {
      params.startDate = dayjs(dateFrom).unix();
      if (!dateTo) params.endDate = '2125-12-31';
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = '2023-01-01';
      params.endDate = dayjs(dateTo).unix();
    }
    if (bookmakerSl) {
      params.bookmarkerId = bookmakerSl;
    }
    // if (isTestPlayer == true || ) {
      params.isTestPlayer = isTestPlayer
    // }
    if (form.getFieldValue('orderId')) {
      params.orderId = form.getFieldValue('orderId').trim();
    }
    if (typeTransaction) {
      params.typeTransaction = typeTransaction;
    }

    const bm = (opBookmaker || []).find(b => b.key == nccSelected);
    if (bm) {
      params.nccNote = bm.label;
    } else {
      params.nccNote = nccSelected;
    }

    // if (
    //   params?.username || params?.startDate ||
    //   params?.endDate || params.bookmakerId ||
    //   params.nickname || params.isTestPlayer ||
    //   params.orderId || params.typeTransaction ||
    //   params.nccNote || params.isTestPlayer == true 
    // ) {
      setDataFilter(JSON.stringify(params));
    // } else {
    //   setDataFilter(null);
    // }
  }
  useEffect(()=> {
    makeParams()
  }, [])

  const playHis = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(pagePlayHis - 1) * pageSize + (index + 1)}</span>,
    },
    {
      title: 'Mã đơn cược',
      key: 'code',
      render: (record) =>
        <div>
          <div className="flex w-full items-center justify-center" >
            <div >{record.code}</div>
            {
              record.code ? <CopyOutlined className='copy-icon' onClick={() => copyToClipboard(record?.code)} /> : ''
            }
            
          </div>
        </div>
    
    },
    {
      title: TEXT_DEF.text0011,
      key: 'bookmaker',
      dataIndex: 'bookmaker',
    },
    {
      title: 'Tên HV mã hoá',
      key: 'username',
      render: (record) =>
        <div>
          <div className="flex w-full items-center justify-center" >
            <div >{record.username}</div>
            {
              record.username ? <CopyOutlined className='copy-icon' onClick={() => copyToClipboard(record?.username)} /> : ''
            }
          </div>
        </div>
    },
    {
      title: 'Nickname',
      key: 'nickname',
      dataIndex: 'nickname',
    },
    {
      title: "Thời gian giao dịch",
      key: 'createdAt',
      render: (record) => moment(record?.createdAt).utcOffset(7).format('DD/MM/YYYY HH:mm:ss:SSS'),
    },
    {
      title: TEXT_DEF.text0129,
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: "Số dư trước",
      key: 'balanceBefore',
      dataIndex: 'balanceBefore',
    },
    {
      title: "Số dư sau",
      key: 'balance',
      dataIndex: 'balance',
    },
    {
      title: "Loại giao dịch",
      key: 'typeTransaction',
      dataIndex: 'typeTransaction',
    },
    {
      title: TEXT_DEF.text0078,
      key: 'detail',
      dataIndex: 'detail',
    },
    {
      title: "NCC liên quan",
      key: 'nccNote',
      dataIndex: 'nccNote',
    },
    // {
    //   title: "Chi tiết",
    //   key: "detail",
    //   render: (record) =>
    //     (auth?.role === "supper" || auth.role === "admin_bm") && record?.profit !== "0" ? (
    //       <div className="flex w-full items-center justify-center"
    //         style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
    //         onClick={() => {
    //           console.log(record)
    //           handleShowModal(record)
    //         }}
    //       >
    //         Xem
    //       </div>
    //     ) : null,
    // },
    // {
    //   title: TEXT_DEF.setting_user,
    //   key: "setting",
    //   align: "center",
    //   render: (record) =>
    //     auth?.role === "supper" ||
    //       (auth.role === "admin" && roleArr?.indexOf("user_edit_info")) > -1 ? (
    //       <div className="flex w-full items-center justify-center">
    //         <img
    //           style={{ height: 20, cursor: "pointer" }}
    //           src={settingIcon}
    //           alt="setting"
    //           onClick={() => {
    //             localStorage.setItem("indexLocalEdit", "2");
    //             navigate(`/keyUser/${record.key}`);
    //           }}
    //         />
    //       </div>
    //     ) : null,
    // },
  ];
  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf("day");
    }
    return current && (current > dayjs().endOf("day") || current < dayjs(dateTo).subtract(3, "months").startOf("day") || current > dayjs(dateTo).endOf("day"));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf("day");
    }
    return current && (current > dayjs().endOf("day") || current < dayjs(dateFrom).startOf("day") || current > dayjs(dateFrom).add(3, "months").endOf("day") || current > dayjs(dateFrom).add(3, "months").endOf("day"));
  };

  const handleDownloadExcel = async () => {
    let params = auth?.role === 'admin_bm' ? { bookmakerId: auth?.bookmakerId } : {};
    if (form.getFieldValue('usernameFilter')) params.username = form.getFieldValue('usernameFilter').trim().toLowerCase();
    if (form.getFieldValue('nicknameFilter')) {
      params.nickname = form.getFieldValue('nicknameFilter').trim().toLowerCase();
    }
    if (dateFrom) {
      params.startDate = moment(dateFrom).format('YYYY-MM-DD');
      if (!dateTo) params.endDate = '2125-12-31';
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = '2023-01-01';
      params.endDate = moment(dateTo).format('YYYY-MM-DD');
    }
    if (bookmakerSl) {
      params.bookmarkerId = bookmakerSl;
    }
    if (isTestPlayer !== undefined) {
      params.isTestPlayer = isTestPlayer;
    }
    if (form.getFieldValue('orderId')) {
      params.orderId = form.getFieldValue('orderId').trim();
    }
    if (typeTransaction) {
      params.typeTransaction = typeTransaction;
    }
    if (nccSelected) {
      params.nccNote = nccSelected;
    }
    const keyword = JSON.stringify(params);
    await API.get(`${WALLET_INOUT}/history?take=10000000&skip=1&order=DESC&keyword=${keyword}`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataList = [];
          result?.map((item, index) => {
            const newIt = {
              key: index + 1,
              code: item.code ? item.code:  '',
              bookmaker: item?.user?.bookmaker?.name,
              username: item?.user?.username ? item?.user?.username:'null',
              nickname: item?.user?.userInfo?.nickname ? item?.user?.userInfo?.nickname : '',
              createdAt: moment(item?.createdAt).utcOffset(7).format('DD/MM/YYYY HH:mm:ss:SSS'),
              amount: item.subOrAdd !== null ? `${item?.subOrAdd === 0 ? '-' : ''}${Number(item?.amount)}` : '',
              balanceBefore: item?.subOrAdd === 0 ? 
                              Number(item?.balance) + Number(item?.amount) : 
                              Number(item?.balance) - Number(item?.amount),
              balance: Number(item?.balance),
              typeTransaction: item.typeTransaction,
              detail: item?.detail,
              // isTestPlayer: item?.user?.usernameReal === "" ? 'Chơi thật' : 'Chơi thử',
              nccNote: item?.nccNote,
            };
            dataList.push(newIt);
          });

          exportToExcel(dataList)
        }
      })
  };

  const exportToExcel = (data) => {
    const name = "Lịch sử dòng tiền HV"

    const formattedData = data.map(item => ({
      'STT': item.key ,
      'Mã đơn cược': item.code ,
      'Nhà điều hành': item.bookmaker ,
      'Tên HV mã hóa': item.username,
      'Nickname': item.nickname,
      'Thời gian giao dịch': item.createdAt ,
      'Số tiền': item.amount, 
      'Số dư trước': item.balanceBefore, 
      'Số dư sau': item.balance,  
      'Loại giao dịch': item.typeTransaction, 
      'Chi tiết': item.detail,
      'Ncc liên quan': item.nccNote
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const columnFormats = [
      { header: 'STT', key: 'STT', type: 'n' },
      { header: 'Mã đơn cược', key: 'Mã đơn cược' },
      { header: 'Nhà điều hành', key: 'Nhà điều hành' },
      { header: 'Tên HV mã hóa', key: 'Tên HV mã hóa' },
      { header: 'Nickname', key: 'Nickname' },
      { header: 'Thời gian giao dịch', key: 'Thời gian giao dịch' },
      { header: 'Số tiền', key: 'Số tiền', type: 'n' },
      { header: 'Số dư trước', key: 'Số dư trước', type: 'n' },
      { header: 'Số dư sau', key: 'Số dư sau', type: 'n' },
      { header: 'Loại giao dịch', key: 'Loại giao dịch' },
      { header: 'Chi tiết', key: 'Chi tiết' },
      { header: 'Ncc liên quan', key: 'Ncc liên quan' }
    ];

    formattedData.forEach((row, rowIndex) => {
      columnFormats.forEach((col, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
        if (col.type === 'n') {
          ws[cellRef].t = 'n';
        }
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const renderResult = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
            <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="orderId" label={'Mã đơn cược'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
              {
                hasRight([RIGHTS.Super]) ? (
                  <div className="col-4">
                    <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}
                    
                    rules={[
                      {
                        required: true,
                        message: TEXT_DEF.text0004,
                      },
                    ]}
                    >
                      <Select showSearch placeholder="Chọn nhà cái" optionFilterProp="children" onChange={(val) => setBookmakerSl(val)}
                       filterOption={filterOptionBm} options={opBookmaker} allowClear defaultValue={bookmakerSl} />
                    </Form.Item>
                  </div>
                ) : null
              }
              <div className="col-4">
                <Form.Item name="usernameFilter" label={'Tên HV mã hoá'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="typeTransaction" label={`Loại giao dịch`}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setTypeTransaction(val)}
                      filterOption={filterOptionBm}
                      options={opTypeTransaction}
                      allowClear
                    />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="nccNote" label={`NCC liên quan`}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(val) => setNccSelected(val)}
                    filterOption={filterOptionBm}
                    options={opNcc}
                    allowClear
                  />
                </Form.Item>
                {/* <Form.Item name="nccNote" label={'NCC liên quan'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item> */}
              </div>
              <div className="col-4">
                <Form.Item name="isTestPlayer" label={`Loại tài khoản`}
                initialValue={'false'}>
                    {/* <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setIsTestPlayer(val)}
                      options={opTypeTransaction}
                      allowClear
                    /> */}
                    <Select onChange={setIsTestPlayer} value={isTestPlayer}>
                      {/* <Option value="">Tất cả</Option> */}
                      <Option value="true">Chơi thử</Option>
                      <Option value="false" >Chơi thật</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item
                  name="dateFrom"
                  label={TEXT_DEF.text0024}
                  rules={[
                    { required: true, message: "Vui lòng nhập thông tin!" },
                    {
                      validator: () => {
                        return validateDateRange(dateFrom, dateTo);
                      },
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'from')}
                    format={'DD-MM-YYYY HH:mm:ss'}
                    showTime={{ format: 'HH:mm:ss' }}
                    style={{ width: '100%' }}
                    disabledDate={disabledDateFrom}
                    disabledTime={disabledTimeFrom(dateTo)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'to')}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={disabledDateTo}
                    disabledTime={disabledTimeTo(dateFrom)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              {/* <div className="col-4">
                <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                  <Select showSearch placeholder="Chọn trò chơi" optionFilterProp="children" onChange={(val) => setIsTestPlayer(val)} filterOption={''} options={playerTypes} allowClear />
                </Form.Item>
              </div> */}
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>

        {dataPlayHis && (
          <>
            {/* <div style={{ width: '100%', height: '2px', background: 'gray', margin: '20px 0px' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0134} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0093} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0131} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0132} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0133} :</span>
                <span>100000000000</span>
              </div>
            </div>
            <div style={{ width: '100%', height: '2px', background: 'gray', margin: '20px 0px' }}></div> */}
            <Spin spinning={loading} delay={500} size="large">
              <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
                <Table key="userList" locale={locale} columns={playHis} dataSource={dataPlayHis} pagination={false} bordered style={{ width: '100%' }} scroll={{ x: 1300 }} />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 15,
                  justifyContent: 'flex-end',
                }}
              >
                <Pagination 
                  current={pagePlayHis} 
                  total={totalRecordPlayHis} 
                  onChange={onChangePagePlayHis} 
                  showTotal={showTotal} 
                  showSizeChanger={true}
                  pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
                />
              </div>
            </Spin>
          </>
        )}
      </>
    );
  };

  return (
    <div className="col-12">
      {renderResult()}
    </div>
  );
}

export default UserPlayHis;