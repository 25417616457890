import moment from "moment";
import { TEXT_DEF } from "../constant/text";

export const validateDateRange = (dateFrom, dateTo) => {
  return new Promise((resolve, reject) => {
    const momentDateFrom = moment(dateFrom, "YYYY-MM-DD HH:mm:ss");
    const momentDateTo = moment(dateTo, "YYYY-MM-DD HH:mm:ss");

    if (momentDateTo.isBefore(momentDateFrom)) {
      reject(new Error(TEXT_DEF.text0172));
    } else {
      resolve();
    }
  });
};

export const disabledTimeFrom = (timeTo) => (current) => {
  const timeToMoment = timeTo ? moment(timeTo) : null;
  const currentMoment = moment();

  return {
    disabledHours: () => {
      const hours = [];
      const currentHour = currentMoment.hour();
      const isBeforeHour = current?.isBefore(currentMoment, "hour");

      if (timeToMoment) {
        for (let i = timeToMoment.hour() + 1; i < 24; i++) {
          hours.push(i);
        }
      } else if (!timeToMoment) {
        for (let i = currentHour + 1; i < 24; i++) {
          hours.push(i);
        }
      }

      if (isBeforeHour) {
        return [];
      }

      return hours;
    },

    disabledMinutes: (selectedHour) => {
      const minutes = [];
      const currentMinute = currentMoment.minute();
      const isBeforeMinute = current?.isBefore(currentMoment, "minute");

      if (timeToMoment && selectedHour === timeToMoment.hour()) {
        for (let i = timeToMoment.minute() + 1; i < 60; i++) {
          minutes.push(i);
        }
      } else if (!timeToMoment && selectedHour === moment().hour()) {
        for (let i = currentMinute + 1; i < 60; i++) {
          minutes.push(i);
        }
      }

      if (isBeforeMinute) {
        return [];
      }

      return minutes;
    },

    disabledSeconds: (selectedHour, selectedMinute) => {
      const seconds = [];
      const currentSecond = currentMoment.second();
      const isBeforeSecond = current?.isBefore(currentMoment, "second");

      if (timeToMoment && selectedHour === timeToMoment.hour() && selectedMinute === timeToMoment.minute()) {
        for (let i = timeToMoment.second() + 1; i < 60; i++) {
          seconds.push(i);
        }
      } else if (!timeToMoment && selectedHour === moment().hour() && selectedMinute === moment().minute()) {
        for (let i = currentSecond + 1; i < 60; i++) {
          seconds.push(i);
        }
      }

      if (isBeforeSecond) {
        return [];
      }

      return seconds;
    },
  };
};

export const disabledTimeTo = (timeFrom) => (current) => {
  const timeFromMoment = timeFrom ? moment(timeFrom) : null;
  const currentMoment = moment();

  return {
    disabledHours: () => {
      const hours = [];
      const currentHour = moment().hour();
      const isBeforeHour = current?.isBefore(currentMoment, "hour");

      if (timeFromMoment) {
        if (current && current.isAfter(timeFromMoment)) {
          if (current.isAfter(currentMoment)) {
            return [];
          }
          if (current.isSame(currentMoment, "day")) {
            for (let i = currentHour + 1; i < 24; i++) {
              hours.push(i);
            }
            return hours;
          }
        }
        for (let i = 0; i < timeFromMoment.hour(); i++) {
          hours.push(i);
        }
      } else if (!timeFromMoment) {
        for (let i = currentHour + 1; i < 24; i++) {
          hours.push(i);
        }
      }

      if (isBeforeHour) {
        return [];
      }
      return hours;
    },

    disabledMinutes: (selectedHour) => {
      const minutes = [];
      const currentMinute = moment().minute();
      const isBeforeMinute = current?.isBefore(currentMoment, "minute");

      if (timeFromMoment && selectedHour === timeFromMoment.hour()) {
        for (let i = 0; i < timeFromMoment.minute(); i++) {
          minutes.push(i);
        }
      } else if (!timeFromMoment && selectedHour === moment().hour()) {
        for (let i = currentMinute + 1; i <= 60; i++) {
          minutes.push(i);
        }
      }

      if (isBeforeMinute) {
        return [];
      }

      return minutes;
    },

    disabledSeconds: (selectedHour, selectedMinute) => {
      const seconds = [];
      const currentSecond = moment().second();
      const isBeforeSecond = current?.isBefore(currentMoment, "second");

      if (timeFromMoment && selectedHour === timeFromMoment.hour() && selectedMinute === timeFromMoment.minute()) {
        for (let i = timeFromMoment.second() + 1; i < 60; i++) {
          seconds.push(i);
        }
      } else if (!timeFromMoment && selectedHour === moment().hour() && selectedMinute === moment().minute()) {
        for (let i = currentSecond + 1; i < 60; i++) {
          seconds.push(i);
        }
      }

      if (isBeforeSecond) {
        return [];
      }

      return seconds;
    },
  };
};
