import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { WALLET_INOUT, BOOKMAKER } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import DetailModal from "./detailModal";

const WalletHisList = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataListInout, setDataListInout] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pageInout, setPageInout] = useState(1);
  const [totalRecordInout, setTotalRecordInout] = useState(0);
  const [pageAdmin, setPageAdmin] = useState(1);
  const [totalRecordAdmin, setTotalRecordAdmin] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);

  const [isShowModal, setIsShowModal] = useState(false);
  const [paramsModal, setParamsModal] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (indexTabLocal === 1) {
      if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("user_view") || roleArr?.indexOf("user_edit"))
      ) {
        setIndexTab(1);
      } else if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("admin_permissions_view") ||
          roleArr?.indexOf("admin_permissions_edit"))
      ) {
        setIndexTab(2);
      }
    }
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataBookmaker = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: item?.id,
              label: item?.name
            }
            dataBookmaker.push(newIt);
          });

          setOpBookmaker(dataBookmaker);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataBookmaker();
  }, [])

  useEffect(() => {
    getListDataInOut();
  }, [pageInout, pageAdmin, dataFilter, indexTab]);

  const getListDataInOut = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? encodeURIComponent(
        JSON.stringify({ bookmakerId: auth?.bookmakerId })) :
        "null"

    await API.get(`${WALLET_INOUT}/all?take=10&skip=${pageInout}&order=DESC&keyword=${keyword}`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          let dataList = [];
          result?.map(item => {
            const itCv = {
              key: item?.id,
              bookmaker: item?.user?.bookmaker?.name,
              bookmakerId: item?.user?.bookmaker?.id,
              username: item?.user?.username,
              userId: item?.user?.id,
              balanceIn: dollarUS.format(Number(item?.balanceIn)),
              balanceOut: dollarUS.format(Number(item?.balanceOut)),
              timeIn: item?.timeIn,
              timeOut: item?.timeOut,
              profit: dollarUS.format(Number(item?.balanceIn) - Number(item?.balanceOut))
            };
            dataList.push(itCv);
          })
          setDataListInout(dataList);
          setTotalRecordInout(response.data?.data?.result[1]);
          setLoading(false);
        }
      }) 
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onChangePageInOut = (page) => {
    setPageInout(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const onSearch = () => {
    let params = auth?.role === "admin_bm" || auth?.role === "administrators_bm" ?
       { bookmakerId: auth?.bookmakerId } : 
       { } ;
    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01";
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
    }
    if (bookmakerSl) {
      params.bookmakerId = bookmakerSl;
    }

    if (indexTab === 1) {
      setPageInout(1);
    } else {
      setPageAdmin(1);
    }

    if (params?.username || params?.startDate || params?.endDate || params.bookmakerId) {
      setDataFilter(JSON.stringify(params));
    } else {
      setDataFilter(null);
    }
  };

  const handleShowModal = (record) => {
    const parmas = {
      bookmakerId: record?.bookmakerId,
      userId: record?.userId,
      startDate: record?.timeIn,
      endDate: record?.timeOut || moment().toISOString()
    }
    setParamsModal(parmas);
    setIsShowModal(true);
  };

  // const handleDeleteAccount = async () => {
  //   try {
  //     setIsShowModalConfirm(false);
  //     await API.delete(`${USER}/${idField}`);
  //     await getListDataUser();
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsShowModalSuccess(true);
  //   }
  // };

  const userColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pageInout - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0011,
      key: "bookmaker",
      dataIndex: "bookmaker",

    },
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
    },
    {
      title: TEXT_DEF.text0112,
      key: "timeIn",
      render: (record) =>
        moment(record?.timeIn).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: TEXT_DEF.text0104,
      key: "balanceIn",
      dataIndex: "balanceIn",
    },
    {
      title: TEXT_DEF.text0113,
      key: "timeOut",
      render: (record) =>
        record?.timeOut ? moment(record?.timeOut).utcOffset(7).format("DD-MM-YYYY HH:mm:ss") : "",
    },
    {
      title: TEXT_DEF.text0107,
      key: "balanceOut",
      dataIndex: "balanceOut",
    },
    {
      title: TEXT_DEF.text0110,
      key: "profit",
      dataIndex: "profit",
    },
    {
      title: "Chi tiết",
      key: "detail",
      render: (record) =>
        (auth?.role === "supper" || auth.role === "admin_bm") && record?.profit !== "0" ? (
          <div className="flex w-full items-center justify-center" 
            style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
            onClick={() => {
              console.log(record)
              handleShowModal(record)
            }}
          >
            Xem
          </div>
        ) : null,
    },
    // {
    //   title: TEXT_DEF.setting_user,
    //   key: "setting",
    //   align: "center",
    //   render: (record) =>
    //     auth?.role === "supper" ||
    //       (auth.role === "admin" && roleArr?.indexOf("user_edit_info")) > -1 ? (
    //       <div className="flex w-full items-center justify-center">
    //         <img
    //           style={{ height: 20, cursor: "pointer" }}
    //           src={settingIcon}
    //           alt="setting"
    //           onClick={() => {
    //             localStorage.setItem("indexLocalEdit", "2");
    //             navigate(`/keyUser/${record.key}`);
    //           }}
    //         />
    //       </div>
    //     ) : null,
    // },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const addNewAdmin = () => {
    localStorage.setItem("indexLocalCreate", indexTab);
    navigate("/createAccount");
  };

  const onChangeTab = (e) => {
    setPageInout(1);
    setPageAdmin(1);
    setIndexTab(e.target.value);
  };

  const handleDownloadExcel = async () => {
    // const keyword = dataFilter
    //   ? dataFilter
    //   : encodeURIComponent(
    //     JSON.stringify({ role: indexTab === 1 ? "member" : "admin" })
    //   );

    // await API.get(`${USER}/all?take=10000&skip=1&order=DESC&keyword=${keyword}`)
    //   .then((response) => {
    //     const res = response.data.data?.result[0];
    //     if (res) {
    //       let dataCV = [];
    //       res?.map((item) => {
    //         if (item?.role === "member") {
    //           const user = {
    //             username: item?.username,
    //             createdAt: `'${item?.createdAt
    //               ? moment(item?.createdAt)
    //                 .utcOffset(7)
    //                 .format("DD-MM-YYYY HH:mm:ss")
    //               : null
    //               }`,
    //           };
    //           dataCV.push(user);
    //         }
    //       });

    //       const header = [TEXT_DEF.text0002, TEXT_DEF.text0013];
    //       downloadExcel({
    //         fileName: "Tài khoản",
    //         sheet: "Sheet 1",
    //         tablePayload: {
    //           header: header,
    //           body: dataCV,
    //         },
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  const renderAccUser = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 20, display: "flex" }}
            >
              {auth?.role === "supper" && <div className="col-4">
                <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    onChange={(val) => setBookmakerSl(val)}
                    filterOption={filterOptionBm}
                    options={opBookmaker}
                    allowClear
                  />
                </Form.Item>
              </div>}
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
        {/* {auth?.role === "supper" ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={addNewBank}>
            {TEXT_DEF.text0041}
          </Button>
        </div> : null } */}
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={userColums}
              dataSource={dataListInout}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageInout}
              total={totalRecordInout}
              onChange={onChangePageInOut}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderAccUser()}
      {isShowModal && (
        <DetailModal
          isShow={isShowModal}
          params={paramsModal}
          close={() => setIsShowModal(false)}
        />
      )}
    </div>
  );
};

export default WalletHisList;
