import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import API, { BOOKMAKER, ROLE_CREATE, REGISTER } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import { RIGHTS, RIGHTS_DEFAULT } from "../../constant/rights";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const CreateBookmaker = () => {
  let isRequest = useRef(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [errorMess, setErrorMess] = useState();
  const [rights, setRights] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    forceUpdate({});

    const rights = separateRolesByModule();
    setRights(rights);
  }, []);

  const getModules = () => {
    const modules = [];
    for (const k in RIGHTS) {
        const hasModule = modules.some(i => i.module === RIGHTS[k].Module);
        if (hasModule) continue;

        modules.push({
            module: RIGHTS[k].Module,
            moduleTitle: RIGHTS[k].Module_Title,
        });
    }

    return modules;
  }

  const onChangeRight = (item) => {
    if (!item) return;

    rights.forEach((i) => {
        (i.rights || []).map((r) => {
            if (r.Name === item.Name) {
                r.checked = !item.checked;
            }

            return r;
        });
    });

    setRights([...rights]);
  }

  const separateRolesByModule = () => {
    const modules = getModules();
    const rightsByModule = [];

    for (const m of modules) {
        if (m.module === 'super') continue;

        const rights = [];
        for (const r in RIGHTS) {
            if (RIGHTS[r].Module === m.module) {
                const hasRightDefault = RIGHTS_DEFAULT.some(rd => rd === RIGHTS[r].Name);
                if (hasRightDefault) {
                    RIGHTS[r].checked = true;
                } else {
                    RIGHTS[r].checked = false;
                }
                rights.push(RIGHTS[r]);
            }
        }
        rightsByModule.push({
            module: m.module,
            moduleTitle: m.moduleTitle,
            rights: rights,
        });
    }

    return rightsByModule;
  };

  const getRightsCreated = () => {
    const rightsBody = [];
    for (const i of (rights || [])) {
        for (const r of i.rights) {
            if (r.checked) {
                rightsBody.push(r.Name);
            }
        }
    }

    return rightsBody.join(',');
  }

  const onSubmitForm = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    setIsLoading(true)
    const permissions = getRightsCreated();
    const params = {
      name: form.getFieldValue("name").trim(),
      permissions,
    };

    const URL = `${BOOKMAKER}/create`;
    // await API.post(URL, params).then((response) => {
    //   const result = response.data.data;
    //   if (result?.code === 20001) {
    //     const bookmaker = {
    //       id: result?.result?.id,
    //       name: params?.name,
    //       permissions: params.permissions
    //     }
    //     createRoleByBookmaker(bookmaker);
    //   }
    // }).finally(() => {
    //   setIsLoading(false)
    // });

    const response = await API.post(URL, params);
    const result = response?.data?.data;
    if (result?.code === 20001) {
      const bookmaker = {
        id: result?.result?.id,
        name: params?.name,
        permissions: params.permissions
      }
      await createRoleByBookmaker(bookmaker);
    }

    setIsLoading(false)
    isRequest.current = false;
  };

  const createRoleByBookmaker = async (bookmaker) => {
    const params = {
      name: `${bookmaker.name}_role_adminSys`,
      permissions: bookmaker.permissions,
      bookmaker: bookmaker.id,
    };

    const URL = `${ROLE_CREATE}`;
    // await API.post(URL, params).then((response) => {
    //   const result = response.data?.data;
    //   if (result) {
    //     createAdminByBookmaker(result, bookmaker)
    //   }
    // })

    const response = await API.post(URL, params);
    const result = response?.data?.data;
    if (result) {
      await createAdminByBookmaker(result, bookmaker)
    }
  }

  const createAdminByBookmaker = async (role, bookmaker) => {
    const params = {
      username: `${bookmaker.name}_adminSys`,
      password: `AIGmkt123!@#`,
      bookmakerId: bookmaker.id,
      roleAdminUserId: role.id,
      isAdminSystem: 1,
    };
    const URL = `${REGISTER}`;
    await API.post(URL, params).then((response) => {
      const result = response.data?.data;
      if (result?.code === 20000) {
        backScreen()
      }
    });
  }

  const backScreen = () => {
    navigate("/bookmaker");
  };

  const renderForm = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={onSubmitForm}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0011} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", color: "red" }}
          >
            <div className="col-3"></div>
            <div className="col-9">{errorMess}</div>
          </div>
          <div style={{ marginBottom: 15 }} />

          <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
          >
              <div className="col-3">
                  Quyền hạn <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-9">
                  {
                      rights.map((m) => {
                          return (
                              <div className="group-roles">
                                  <div className="module">{m.moduleTitle}</div>
                                  {
                                      m.rights.map((r) => {
                                          return (
                                              <Form.Item name="right" className="right">
                                                  <Checkbox onChange={() => { onChangeRight(r) }} checked={r.checked} key={r.Code} value={r.Name}>{r.Right_Title}</Checkbox>
                                              </Form.Item>
                                          );
                                      })
                                  }
                              </div>
                          )
                      })
                  }
              </div>
          </div>
          <div style={{ marginBottom: 30 }} />
          <Form.Item shouldUpdate style={{ width: "100%" }}>
            {() => (
              <div className="lg-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {TEXT_DEF.text0018}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    );
  };

  return (
    <div className="col-12">
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {TEXT_DEF.text0017}
      </Button>
      <Spin spinning={isLoading} delay={500} size="large">
        {renderForm()}
      </Spin>
    </div>
  );
};

export default CreateBookmaker;
