import React, { useState } from 'react';
import { TEXT_DEF } from '../../../constant/text';
import { Button, InputNumber, Select, Switch, Tabs } from 'antd';
import ModalCommon from '../../../components/Modal';
import API, { ADMIN_KENO } from '../../../network/api';
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";

const { Option } = Select;
const LisOP = [
  {
    id: 0,
    value: "easy",
    label: "Dễ"
  },
  {
    id: 1,
    value: "basic",
    label: "Cơ bản"
  },
  {
    id: 2,
    value: "normal",
    label: "Trung bình"
  },
  {
    id: 3,
    value: "high",
    label: "Khó"
  }
]


const KenoSetting = ({ data, auth, roleArr, reload }) => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [valueIp1, setValueIp1] = useState();
  const [valueIp2, setValueIp2] = useState();
  const [valueIp3, setValueIp3] = useState();
  const [valueIp4, setValueIp4] = useState();

  const [valueIp5, setValueIp5] = useState();
  const [valueIp6, setValueIp6] = useState();
  const [valueIp7, setValueIp7] = useState();
  const [valueIp8, setValueIp8] = useState();
  const [valueIp9, setValueIp9] = useState();

  const [hit1Values, setHit1Values] = useState({ value1: undefined, value2: undefined });
  const [hit2Values, setHit2Values] = useState({ value1: undefined, value2: undefined, value3: undefined });
  const [hit3Values, setHit3Values] = useState({ value1: undefined, value2: undefined, value3: undefined, value4: undefined });
  const [hit4Values, setHit4Values] = useState({ value1: undefined, value2: undefined, value3: undefined, value4: undefined, value5: undefined });
  const [hit5Values, setHit5Values] = useState({ value1: undefined, value2: undefined, value3: undefined, value4: undefined, value5: undefined, value6: undefined });
  const [hit6Values, setHit6Values] = useState({ value1: undefined, value2: undefined, value3: undefined, value4: undefined, value5: undefined, value6: undefined, value7: undefined });
  const [hit7Values, setHit7Values] = useState({ value1: undefined, value2: undefined, value3: undefined, value4: undefined, value5: undefined, value6: undefined, value7: undefined, value8: undefined });
  const [hit8Values, setHit8Values] = useState({
    value1: undefined,
    value2: undefined,
    value3: undefined,
    value4: undefined,
    value5: undefined,
    value6: undefined,
    value7: undefined,
    value8: undefined,
    value9: undefined,
  });
  const [hit9Values, setHit9Values] = useState({
    value1: undefined,
    value2: undefined,
    value3: undefined,
    value4: undefined,
    value5: undefined,
    value6: undefined,
    value7: undefined,
    value8: undefined,
    value9: undefined,
    value10: undefined,
  });
  const [hit10Values, setHit10Values] = useState({
    value1: undefined,
    value2: undefined,
    value3: undefined,
    value4: undefined,
    value5: undefined,
    value6: undefined,
    value7: undefined,
    value8: undefined,
    value9: undefined,
    value10: undefined,
    value11: undefined,
  });

  const [dataHit, setDataHit] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isExpanseForObject, setIsExpanseForObject] = useState({});
  const [content, setContent] = useState(null);

  const showConfirm = (key) => {
    if(valueIp1 <= 0 || valueIp2 <= 0 || valueIp3 <= 4) {
      alert('Giá trị không được nhỏ hơn 0');
      return
    }
    let params;
    const id = data?.find((x) => x.key === key)?.id;

    switch (key) {
      case 'isMaintenance':
        params = {
          key: 'isMaintenance',
          value: isMaintenance.toString(),
        };
        break;
      case 'firstLose':
        params = {
          key: 'firstLose',
          value: valueIp1.toString(),
        };
        break;
      case 'ratioRev':
        params = {
          key: 'ratioRev',
          value: valueIp2.toString(),
        };
        break;
      case 'ratioBonus':
        params = {
          key: 'ratioBonus',
          value: valueIp3.toString(),
        };
        break;
      case 'ratesWin':
        params = {
          key: 'ratesWin',
          value: valueIp4.toString(),
        };
        break;
      case 'ratio_win_easy':
        params = {
          key: 'ratio_win_easy',
          value: valueIp5.toString(),
        };
        break;
      case 'ratio_win_basic':
        params = {
          key: 'ratio_win_basic',
          value: valueIp6.toString(),
        };
        break; 
      case 'ratio_win_normal':
        params = {
          key: 'ratio_win_normal',
          value: valueIp7.toString(),
        };
        break;
      case 'ratio_win_high':
        params = {
          key: 'ratio_win_high',
          value: valueIp8.toString(),
        };
        break;
      case 'mode_default':
        params = {
          key: 'mode_default',
          value: valueIp9,
        };
        break;  
      default:
        break;
    }

    const dataReq = {
      id: id,
      params: params,
    };

    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const createParams = (key, values, partValue) => {
    const valueKeys = Object.keys(values);
    let newValues = '';

    for (let i = 0; i < valueKeys.length; i++) {
      if (i > 0) {
        newValues += ',';
      }
      newValues += `${values[`value${i + 1}`]?.toString() ?? partValue[i]}`.replace(/^,|,$/g, '');
    }

    return { key, value: newValues };
  };

  const showConfirmModalByHitKey = (key, module) => {
    let params;
    const { id, value } = data?.find((x) => x.key === key && x.module === module) || {};
    const partValue = value.split(',');

    switch (key) {
      case '1_hit':
        params = createParams('1_hit', hit1Values, partValue);
        break;
      case '2_hit':
        params = createParams('2_hit', hit2Values, partValue);
        break;
      case '3_hit':
        params = createParams('3_hit', hit3Values, partValue);
        break;
      case '4_hit':
        params = createParams('4_hit', hit4Values, partValue);
        break;
      case '5_hit':
        params = createParams('5_hit', hit5Values, partValue);
        break;
      case '6_hit':
        params = createParams('6_hit', hit6Values, partValue);
        break;
      case '7_hit':
        params = createParams('7_hit', hit7Values, partValue);
        break;
      case '8_hit':
        params = createParams('8_hit', hit8Values, partValue);
        break;
      case '9_hit':
        params = createParams('9_hit', hit9Values, partValue);
        break;
      case '10_hit':
        params = createParams('10_hit', hit10Values, partValue);
        break;
      default:
        break;
    }

    const dataReq = {
      id: id,
      params: params,
    };

    setDataHit(dataReq);
    setIsShowModalConfirm(true);
  };

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (dataUp) {
      await API.patch(`${ADMIN_KENO}/updateConfig/${dataUp?.id}`, dataUp?.params)
        .then((response) => {
          const result = response.data?.data;
          if (result?.code === 20004) {
            reload();
            setIsShowModalConfirm(false);
            setIsShowModalSuccess(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (dataHit) {
      await API.patch(`${ADMIN_KENO}/updateConfig/${dataHit?.id}`, dataHit?.params)
        .then((response) => {
          const result = response.data?.data;
          if (result?.code === 20004) {
            reload();
            setIsShowModalConfirm(false);
            setIsShowModalSuccess(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const renderIp = (label, defaultValue, onChangeIp, onUpdate) => {
    return (
      <div className="col-12 flex">
        <div className="col-3 flex align-items-center text-left">{label}</div>
        <div className="col-6">
          <InputNumber style={{ width: '90%' }} min={0} onChange={onChangeIp} defaultValue={Number(defaultValue)} />
        </div>
        {
          hasRight([RIGHTS.EditSettingOriginals]) ? (
            <div className="col-3 text-left">
              <Button type="primary" onClick={onUpdate}>
                {TEXT_DEF.text0016}
              </Button>
            </div>
          ) : null
        }
      </div>
    );
  };

  const handleValueChange = (setValues, key) => (value) => {
    const numberValue = Number(value);
    if (!isNaN(numberValue) && numberValue >= 0) {
      setValues((prevValues) => ({ ...prevValues, [key]: numberValue }));
    } else {
      alert('Giá trị phải là số hoặc không phải là số âm');
    }
  };

  const onChangeHandlers = {
    // 1_hit easy
    '1_hit_value1': handleValueChange(setHit1Values, 'value1'),
    '1_hit_value2': handleValueChange(setHit1Values, 'value2'),

    // 2_hit easy
    '2_hit_value1': handleValueChange(setHit2Values, 'value1'),
    '2_hit_value2': handleValueChange(setHit2Values, 'value2'),
    '2_hit_value3': handleValueChange(setHit2Values, 'value3'),

    // 3_hit easy
    '3_hit_value1': handleValueChange(setHit3Values, 'value1'),
    '3_hit_value2': handleValueChange(setHit3Values, 'value2'),
    '3_hit_value3': handleValueChange(setHit3Values, 'value3'),
    '3_hit_value4': handleValueChange(setHit3Values, 'value4'),

    // 4_hit easy
    '4_hit_value1': handleValueChange(setHit4Values, 'value1'),
    '4_hit_value2': handleValueChange(setHit4Values, 'value2'),
    '4_hit_value3': handleValueChange(setHit4Values, 'value3'),
    '4_hit_value4': handleValueChange(setHit4Values, 'value4'),
    '4_hit_value5': handleValueChange(setHit4Values, 'value5'),

    // 5_hit easy
    '5_hit_value1': handleValueChange(setHit5Values, 'value1'),
    '5_hit_value2': handleValueChange(setHit5Values, 'value2'),
    '5_hit_value3': handleValueChange(setHit5Values, 'value3'),
    '5_hit_value4': handleValueChange(setHit5Values, 'value4'),
    '5_hit_value5': handleValueChange(setHit5Values, 'value5'),
    '5_hit_value6': handleValueChange(setHit5Values, 'value6'),

    // 6_hit easy
    '6_hit_value1': handleValueChange(setHit6Values, 'value1'),
    '6_hit_value2': handleValueChange(setHit6Values, 'value2'),
    '6_hit_value3': handleValueChange(setHit6Values, 'value3'),
    '6_hit_value4': handleValueChange(setHit6Values, 'value4'),
    '6_hit_value5': handleValueChange(setHit6Values, 'value5'),
    '6_hit_value6': handleValueChange(setHit6Values, 'value6'),
    '6_hit_value7': handleValueChange(setHit6Values, 'value7'),

    // 7_hit easy
    '7_hit_value1': handleValueChange(setHit7Values, 'value1'),
    '7_hit_value2': handleValueChange(setHit7Values, 'value2'),
    '7_hit_value3': handleValueChange(setHit7Values, 'value3'),
    '7_hit_value4': handleValueChange(setHit7Values, 'value4'),
    '7_hit_value5': handleValueChange(setHit7Values, 'value5'),
    '7_hit_value6': handleValueChange(setHit7Values, 'value6'),
    '7_hit_value7': handleValueChange(setHit7Values, 'value7'),
    '7_hit_value8': handleValueChange(setHit7Values, 'value8'),

    // 8_hit easy
    '8_hit_value1': handleValueChange(setHit8Values, 'value1'),
    '8_hit_value2': handleValueChange(setHit8Values, 'value2'),
    '8_hit_value3': handleValueChange(setHit8Values, 'value3'),
    '8_hit_value4': handleValueChange(setHit8Values, 'value4'),
    '8_hit_value5': handleValueChange(setHit8Values, 'value5'),
    '8_hit_value6': handleValueChange(setHit8Values, 'value6'),
    '8_hit_value7': handleValueChange(setHit8Values, 'value7'),
    '8_hit_value8': handleValueChange(setHit8Values, 'value8'),
    '8_hit_value9': handleValueChange(setHit8Values, 'value9'),

    // 9_hit easy
    '9_hit_value1': handleValueChange(setHit9Values, 'value1'),
    '9_hit_value2': handleValueChange(setHit9Values, 'value2'),
    '9_hit_value3': handleValueChange(setHit9Values, 'value3'),
    '9_hit_value4': handleValueChange(setHit9Values, 'value4'),
    '9_hit_value5': handleValueChange(setHit9Values, 'value5'),
    '9_hit_value6': handleValueChange(setHit9Values, 'value6'),
    '9_hit_value7': handleValueChange(setHit9Values, 'value7'),
    '9_hit_value8': handleValueChange(setHit9Values, 'value8'),
    '9_hit_value9': handleValueChange(setHit9Values, 'value9'),
    '9_hit_value10': handleValueChange(setHit9Values, 'value10'),

    // 10_hit easy
    '10_hit_value1': handleValueChange(setHit10Values, 'value1'),
    '10_hit_value2': handleValueChange(setHit10Values, 'value2'),
    '10_hit_value3': handleValueChange(setHit10Values, 'value3'),
    '10_hit_value4': handleValueChange(setHit10Values, 'value4'),
    '10_hit_value5': handleValueChange(setHit10Values, 'value5'),
    '10_hit_value6': handleValueChange(setHit10Values, 'value6'),
    '10_hit_value7': handleValueChange(setHit10Values, 'value7'),
    '10_hit_value8': handleValueChange(setHit10Values, 'value8'),
    '10_hit_value9': handleValueChange(setHit10Values, 'value9'),
    '10_hit_value10': handleValueChange(setHit10Values, 'value10'),
    '10_hit_value11': handleValueChange(setHit10Values, 'value11'),
  };

  const toggleExpanse = (objKey, module) => {
    const updatedExpanseStates = { ...isExpanseForObject };

    const keyMappings = {
      '3_hit': '3_hit',
      '4_hit': '4_hit',
      '5_hit': '5_hit',
      '6_hit': '6_hit',
      '7_hit': '7_hit',
      '8_hit': '8_hit',
      '9_hit': '9_hit',
      '10_hit': '10_hit',
    };

    const moduleMappings = {
      easy: 'easy',
      normal: 'normal',
      basic: 'basic',
      high: 'high',
    };

    const mappedKey = keyMappings[objKey];
    const mappedModule = moduleMappings[module];

    if (mappedKey && mappedModule) {
      updatedExpanseStates[`${mappedKey}_${mappedModule}`] = !isExpanseForObject[`${mappedKey}_${mappedModule}`];
    }

    // if (keyMappings[objKey]) {
    //   updatedExpanseStates[keyMappings[objKey]] = !isExpanseForObject[keyMappings[objKey]];
    // }

    setIsExpanseForObject(updatedExpanseStates);
  };

  const renderHit = (label, defaultValue, onUpdate, onShowMore, isHit, onChangeHandlers, objKey, level) => {
    let hitData = {};

    if (isHit) {
      const dataArr = defaultValue?.split(',').map(Number);
      dataArr?.forEach((value, index) => {
        hitData[`value${index + 1}`] = value;
      });
    }

    const hitDataKeys = Object.keys(hitData).filter((key) => hitData[key] !== null && hitData[key] !== undefined);
    const inputShowLimit = 3;
    const isShow = hitDataKeys.length > 3 ? true : false;
    const hitDataKeysToShow = isExpanseForObject[`${objKey}_${level}`] ? hitDataKeys : hitDataKeys.slice(0, inputShowLimit);

    return (
      <>
        <div className="row">
          <div className="col" style={{ marginBottom: 10 }}>
            {label}
          </div>
        </div>
        {hitDataKeysToShow.map((key, index) => (
          <React.Fragment key={index}>
            <div className="row">
              <div className="col-4">{TEXT_DEF.text0159.replace('$', index)}</div>
              <div className="col-4">
                <InputNumber min={0} style={{ width: '90%', marginBottom: 10 }} onChange={onChangeHandlers[`${objKey}_${key}`]} defaultValue={hitData[key]} />
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className="row">
          <div className="col-4"></div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-1 float-right" style={{ width: isExpanseForObject[`${objKey}_${level}`] && isShow ? '23%' : isShow ? '20%' : '30%', display: 'flex', justifyContent: 'end' }}>
                <Button type="primary" onClick={onUpdate}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
          {isShow && (
            <div className="col-1 float-right" style={{ width: '23%' }}>
              <Button type="primary" onClick={onShowMore}>
                {isExpanseForObject[`${objKey}_${level}`] ? TEXT_DEF.text0157 : TEXT_DEF.text0156}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderEasy = () => {
    return (
      <>
        {renderHit(`${TEXT_DEF.text0158} 1 Hit`, data?.find((x) => x.key === '1_hit' && x.module === 'easy')?.value, () => showConfirmModalByHitKey('1_hit', 'easy'), null, true, onChangeHandlers, '1_hit', null)}

        {renderHit(`${TEXT_DEF.text0158} 2 Hit`, data?.find((x) => x.key === '2_hit' && x.module === 'easy')?.value, () => showConfirmModalByHitKey('2_hit', 'easy'), null, true, onChangeHandlers, '2_hit', null)}

        {renderHit(
          `${TEXT_DEF.text0158} 3 Hit`,
          data?.find((x) => x.key === '3_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('3_hit', 'easy'),
          () => toggleExpanse('3_hit', 'easy'),
          true,
          onChangeHandlers,
          '3_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 4 Hit`,
          data?.find((x) => x.key === '4_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('4_hit', 'easy'),
          () => toggleExpanse('4_hit', 'easy'),
          true,
          onChangeHandlers,
          '4_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 5 Hit`,
          data?.find((x) => x.key === '5_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('5_hit', 'easy'),
          () => toggleExpanse('5_hit', 'easy'),
          true,
          onChangeHandlers,
          '5_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 6 Hit`,
          data?.find((x) => x.key === '6_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('6_hit', 'easy'),
          () => toggleExpanse('6_hit', 'easy'),
          true,
          onChangeHandlers,
          '6_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 7 Hit`,
          data?.find((x) => x.key === '7_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('7_hit', 'easy'),
          () => toggleExpanse('7_hit', 'easy'),
          true,
          onChangeHandlers,
          '7_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 8 Hit`,
          data?.find((x) => x.key === '8_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('8_hit', 'easy'),
          () => toggleExpanse('8_hit', 'easy'),
          true,
          onChangeHandlers,
          '8_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 9 Hit`,
          data?.find((x) => x.key === '9_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('9_hit', 'easy'),
          () => toggleExpanse('9_hit', 'easy'),
          true,
          onChangeHandlers,
          '9_hit',
          'easy'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 10 Hit`,
          data?.find((x) => x.key === '10_hit' && x.module === 'easy')?.value,
          () => showConfirmModalByHitKey('10_hit', 'easy'),
          () => toggleExpanse('10_hit', 'easy'),
          true,
          onChangeHandlers,
          '10_hit',
          'easy'
        )}
      </>
    );
  };

  const renderBasics = () => {
    return (
      <>
        {renderHit(`${TEXT_DEF.text0158} 1 Hit`, data?.find((x) => x.key === '1_hit' && x.module === 'basic')?.value, () => showConfirmModalByHitKey('1_hit', 'basic'), null, true, onChangeHandlers, '1_hit')}

        {renderHit(`${TEXT_DEF.text0158} 2 Hit`, data?.find((x) => x.key === '2_hit' && x.module === 'basic')?.value, () => showConfirmModalByHitKey('2_hit', 'basic'), null, true, onChangeHandlers, '2_hit')}

        {renderHit(
          `${TEXT_DEF.text0158} 3 Hit`,
          data?.find((x) => x.key === '3_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('3_hit', 'basic'),
          () => toggleExpanse('3_hit', 'basic'),
          true,
          onChangeHandlers,
          '3_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 4 Hit`,
          data?.find((x) => x.key === '4_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('4_hit', 'basic'),
          () => toggleExpanse('4_hit', 'basic'),
          true,
          onChangeHandlers,
          '4_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 5 Hit`,
          data?.find((x) => x.key === '5_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('5_hit', 'basic'),
          () => toggleExpanse('5_hit', 'basic'),
          true,
          onChangeHandlers,
          '5_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 6 Hit`,
          data?.find((x) => x.key === '6_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('6_hit', 'basic'),
          () => toggleExpanse('6_hit', 'basic'),
          true,
          onChangeHandlers,
          '6_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 7 Hit`,
          data?.find((x) => x.key === '7_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('7_hit', 'basic'),
          () => toggleExpanse('7_hit', 'basic'),
          true,
          onChangeHandlers,
          '7_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 8 Hit`,
          data?.find((x) => x.key === '8_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('8_hit', 'basic'),
          () => toggleExpanse('8_hit', 'basic'),
          true,
          onChangeHandlers,
          '8_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 9 Hit`,
          data?.find((x) => x.key === '9_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('9_hit', 'basic'),
          () => toggleExpanse('9_hit', 'basic'),
          true,
          onChangeHandlers,
          '9_hit',
          'basic'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 10 Hit`,
          data?.find((x) => x.key === '10_hit' && x.module === 'basic')?.value,
          () => showConfirmModalByHitKey('10_hit', 'basic'),
          () => toggleExpanse('10_hit', 'basic'),
          true,
          onChangeHandlers,
          '10_hit',
          'basic'
        )}
      </>
    );
  };

  const renderNormal = () => {
    return (
      <>
        {renderHit(`${TEXT_DEF.text0158} 1 Hit`, data?.find((x) => x.key === '1_hit' && x.module === 'normal')?.value, () => showConfirmModalByHitKey('1_hit', 'normal'), null, true, onChangeHandlers, '1_hit')}

        {renderHit(`${TEXT_DEF.text0158} 2 Hit`, data?.find((x) => x.key === '2_hit' && x.module === 'normal')?.value, () => showConfirmModalByHitKey('2_hit', 'normal'), null, true, onChangeHandlers, '2_hit')}

        {renderHit(
          `${TEXT_DEF.text0158} 3 Hit`,
          data?.find((x) => x.key === '3_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('3_hit', 'normal'),
          () => toggleExpanse('3_hit', 'normal'),
          true,
          onChangeHandlers,
          '3_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 4 Hit`,
          data?.find((x) => x.key === '4_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('4_hit', 'normal'),
          () => toggleExpanse('4_hit', 'normal'),
          true,
          onChangeHandlers,
          '4_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 5 Hit`,
          data?.find((x) => x.key === '5_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('5_hit', 'normal'),
          () => toggleExpanse('5_hit', 'normal'),
          true,
          onChangeHandlers,
          '5_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 6 Hit`,
          data?.find((x) => x.key === '6_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('6_hit', 'normal'),
          () => toggleExpanse('6_hit', 'normal'),
          true,
          onChangeHandlers,
          '6_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 7 Hit`,
          data?.find((x) => x.key === '7_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('7_hit', 'normal'),
          () => toggleExpanse('7_hit', 'normal'),
          true,
          onChangeHandlers,
          '7_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 8 Hit`,
          data?.find((x) => x.key === '8_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('8_hit', 'normal'),
          () => toggleExpanse('8_hit', 'normal'),
          true,
          onChangeHandlers,
          '8_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 9 Hit`,
          data?.find((x) => x.key === '9_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('9_hit', 'normal'),
          () => toggleExpanse('9_hit', 'normal'),
          true,
          onChangeHandlers,
          '9_hit',
          'normal'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 10 Hit`,
          data?.find((x) => x.key === '10_hit' && x.module === 'normal')?.value,
          () => showConfirmModalByHitKey('10_hit', 'normal'),
          () => toggleExpanse('10_hit', 'normal'),
          true,
          onChangeHandlers,
          '10_hit',
          'normal'
        )}
      </>
    );
  };

  const renderHigh = () => {
    return (
      <>
        {renderHit(`${TEXT_DEF.text0158} 1 Hit`, data?.find((x) => x.key === '1_hit' && x.module === 'high')?.value, () => showConfirmModalByHitKey('1_hit', 'high'), null, true, onChangeHandlers, '1_hit')}

        {renderHit(`${TEXT_DEF.text0158} 2 Hit`, data?.find((x) => x.key === '2_hit' && x.module === 'high')?.value, () => showConfirmModalByHitKey('2_hit', 'high'), null, true, onChangeHandlers, '2_hit')}

        {renderHit(
          `${TEXT_DEF.text0158} 3 Hit`,
          data?.find((x) => x.key === '3_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('3_hit', 'high'),
          () => toggleExpanse('3_hit', 'high'),
          true,
          onChangeHandlers,
          '3_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 4 Hit`,
          data?.find((x) => x.key === '4_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('4_hit', 'high'),
          () => toggleExpanse('4_hit', 'high'),
          true,
          onChangeHandlers,
          '4_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 5 Hit`,
          data?.find((x) => x.key === '5_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('5_hit', 'high'),
          () => toggleExpanse('5_hit', 'high'),
          true,
          onChangeHandlers,
          '5_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 6 Hit`,
          data?.find((x) => x.key === '6_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('6_hit', 'high'),
          () => toggleExpanse('6_hit', 'high'),
          true,
          onChangeHandlers,
          '6_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 7 Hit`,
          data?.find((x) => x.key === '7_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('7_hit', 'high'),
          () => toggleExpanse('7_hit', 'high'),
          true,
          onChangeHandlers,
          '7_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 8 Hit`,
          data?.find((x) => x.key === '8_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('8_hit', 'high'),
          () => toggleExpanse('8_hit', 'high'),
          true,
          onChangeHandlers,
          '8_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 9 Hit`,
          data?.find((x) => x.key === '9_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('9_hit', 'high'),
          () => toggleExpanse('9_hit', 'high'),
          true,
          onChangeHandlers,
          '9_hit',
          'high'
        )}

        {renderHit(
          `${TEXT_DEF.text0158} 10 Hit`,
          data?.find((x) => x.key === '10_hit' && x.module === 'high')?.value,
          () => showConfirmModalByHitKey('10_hit', 'high'),
          () => toggleExpanse('10_hit', 'high'),
          true,
          onChangeHandlers,
          '10_hit',
          'high'
        )}
      </>
    );
  };

  const items = [
    {
      key: '1',
      label: TEXT_DEF.text0152,
      children: renderEasy(),
    },
    {
      key: '2',
      label: TEXT_DEF.text0153,
      children: renderBasics(),
    },
    {
      key: '3',
      label: TEXT_DEF.text0154,
      children: renderNormal(),
    },
    {
      key: '4',
      label: TEXT_DEF.text0200,
      children: renderHigh(),
    },
  ];

  const onChange = (value) => {
    return value;
  };

  return (
    <>
    
      {
        data ? 
        <div className="col-12 flex-col" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
        <div className="col-12 flex">
          <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
          <div className="col-6">
            <Switch
              checkedChildren="Bật"
              unCheckedChildren="Tắt"
              defaultChecked={data?.find((x) => x.key === 'isMaintenance')?.value === 'true' ? true : false}
              style={{ width: 60, marginTop: '5px' }}
              onChange={(e) => handleChangeSwitch(e)}
            />
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button type="primary" onClick={() => showConfirm('isMaintenance')}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        <div style={{ margin: '10px 0px' }} />
        <div className="col-12 flex">
          <div className="col-3 text-left">{"Mức độ mặc định"}</div>
          <div className="col-6">
            <Select defaultValue={data?.find((x) => x.key === 'mode_default')?.value} style={{ width: '30%' }} onChange={(e) => setValueIp9(e)}>
              {LisOP.map(item => (
                <Option key={item.id} value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button type="primary" onClick={() => showConfirm('mode_default')}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Thắng lần đầu (đơn vị 1 = 1000 VND)',
          data?.find((x) => x.key === 'firstLose')?.value,
          (e) => setValueIp1(e),
          () => showConfirm('firstLose'),
          false
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ rev',
          data?.find((x) => x.key === 'ratioRev')?.value,
          (e) => setValueIp2(e),
          () => showConfirm('ratioRev'),
          false
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ bonus',
          data?.find((x) => x.key === 'ratioBonus')?.value,
          (e) => setValueIp3(e),
          () => showConfirm('ratioBonus'),
          false
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ thắng chung',
          data?.find((x) => x.key === 'ratesWin')?.value,
          (e) => setValueIp4(e),
          () => showConfirm('ratesWin'),
          false
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ thắng cho cách chơi dễ',
          data?.find((x) => x.key === 'ratio_win_easy')?.value,
          (e) => setValueIp5(e),
          () => showConfirm('ratio_win_easy'),
          false,
          true
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ thắng cho cách chơi cơ bản',
          data?.find((x) => x.key === 'ratio_win_basic')?.value,
          (e) => setValueIp6(e),
          () => showConfirm('ratio_win_basic'),
          false,
          true
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ thắng cho cách chơi trung bình',
          data?.find((x) => x.key === 'ratio_win_normal')?.value,
          (e) => setValueIp7(e),
          () => showConfirm('ratio_win_normal'),
          false,
          true
        )}
        <div style={{ margin: '10px 0px' }} />
        {renderIp(
          'Tỉ lệ thắng cho cách chơi cao',
          data?.find((x) => x.key === 'ratio_win_high')?.value,
          (e) => setValueIp8(e),
          () => showConfirm('ratio_win_high'),
          false,
          true
        )}
      </div> : null
      }

      <div className="col-12">
        {/* <div
          className="col-12"
          style={{
            borderBottom: "1px solid #CCC",
            paddingTop: "0.5rem",
            marginTop: "0.5rem",
            paddingBottom: "0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          <Radio.Group value={tabIndex} onChange={(e) => handleChange(e)}>
            <Radio.Button value={0}>{TEXT_DEF.text0152}</Radio.Button>
            <Radio.Button value={1}>{TEXT_DEF.text0153}</Radio.Button>
            <Radio.Button value={2}>{TEXT_DEF.text0154}</Radio.Button>
            <Radio.Button value={3}>{TEXT_DEF.text0155}</Radio.Button>
            <Radio.Button value={4}>TEST</Radio.Button>
          </Radio.Group>
        </div> */}
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => {
            setIsShowModalConfirm(false);
            setDataUp(undefined);
          }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  );
};

export default KenoSetting;
