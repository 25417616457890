import React, { useEffect, useState } from "react";
import { Table, Pagination, Spin, Form, Button } from "antd";
import API, { QA, GET_TOPIC } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import settingIcon from "../../image/setting.png";
import deteleIcon from "../../image/delete.png";
import { fetchTopic } from "./action";

const ListTopic = ({ setIsModalCreateOpen, setType, isReloadTopics, setIsReloadTopics, setIsModalEditOrDeleteOpen, setCurrentStepEdit, setTopicRecord, setRecordSl, setIsShowModalConfirm }) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [allTopics, setAllTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    getAllTopics();
  }, [page]);

  useEffect(() => {
    if (isReloadTopics) {
      getAllTopics();
      setIsReloadTopics(false);
    }
  }, [isReloadTopics, setIsReloadTopics, page]);

  const getAllTopics = async () => {
    try {
      setLoading(true);

      const response = await fetchTopic(`${QA}/${GET_TOPIC}`);
      const result = response.data;
      const total = response.total;
      const listTopics = result.map((item) => ({
        mainId: item?.id,
        mainName: item?.name,
        childrenNames: item?.children?.map((child) => ({
          subId: child?.id,
          subName: child?.name,
        })),
      }));

      setAllTopics(listTopics);
      setTotal(total);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  };

  const colAllTopics = [
    {
      title: "Chủ đề lớn",
      dataIndex: "mainName",
      key: "mainName",
      width: "30%",
    },
    {
      title: "Chủ đề nhỏ",
      dataIndex: "childrenNames",
      key: "childrenNames",
      render: (record, index) => {
        return <div>{record.map((item) => item.subName).join(", ")}</div>;
      },
      width: "30%",
    },
    {
      title: TEXT_DEF.text0016,
      key: "edit",
      align: "center",
      width: "10%",
      render: (record) => (
        <div>
          {hasRight([RIGHTS.EditSettingQA]) ? (
            <div className="flex justify-center">
              <img
                style={{
                  height: 20,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                src={settingIcon}
                alt="setting"
                onClick={() => {
                  setIsModalEditOrDeleteOpen(true);
                  setCurrentStepEdit(3);
                  setType("editTopic");
                  handleEdit(record);
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    // {
    //   title: "Xóa",
    //   key: "detele",
    //   width: "10%",
    //   render: (record) => (
    //     <div>
    //       {hasRight([RIGHTS.DeleteSettingQA]) ? (
    //         <div className="flex justify-center">
    //           <img
    //             style={{
    //               height: 20,
    //               cursor: "pointer",
    //               display: "flex",
    //               justifyContent: "center",
    //             }}
    //             src={deteleIcon}
    //             alt="delete"
    //             onClick={() => {
    //               setRecordSl(record);
    //               setType("deleteTopic");
    //               setIsShowModalConfirm(true);
    //             }}
    //           />
    //         </div>
    //       ) : null}
    //     </div>
    //   ),
    // },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const handleEdit = (record) => {
    setTopicRecord(record);
  };

  const showModal = () => {
    setIsModalCreateOpen(true);
    setType("topic");
  };

  const renderListMainTopics = () => {
    return (
      <div className="col-12">
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={null}>
            <div className="payment-search">
              {hasRight([RIGHTS.CreateSettingQA]) ? (
                <Button htmlType="submit" type="primary" onClick={showModal} style={{ marginLeft: 15 }}>
                  {TEXT_DEF.text0068}
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="w-full fixed-height">
            <Spin spinning={loading} delay={500} size="large" style={{ width: "100%" }}>
              <div className="col-12 flex-col" style={{ display: "flex", marginTop: 30, alignItems: "center" }}>
                <Table key="mainTopics" locale={locale} columns={colAllTopics} dataSource={allTopics} pagination={false} bordered style={{ width: "100%" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 15,
                  justifyContent: "flex-end",
                }}
              >
                <Pagination current={page} total={total} onChange={onChangePage} showTotal={showTotal} showSizeChanger={false} defaultPageSize={10} />
              </div>
            </Spin>
          </div>
        </div>
      </div>
    );
  };

  return renderListMainTopics();
};

export default ListTopic;
