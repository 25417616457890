import React, { useState, useEffect, useCallback } from "react";
import { TEXT_DEF } from "../../constant/text";
import TextArea from "antd/lib/input/TextArea";
import { Button, Input, Table, Radio, DatePicker } from "antd";
import settingIcon from "../../image/setting.png";
import deteleIcon from "../../image/delete.png";
import Modal from 'react-bootstrap/Modal';
import ModalCommon from "../../components/Modal";
import API, { MAINTENANCE } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import "./style.css";

const ModalMaintenance = ({
  isShow, onChangeIp1, onChangeIp2, onChangeIp3, onChangeIp4, onChangeIp5, record,
  txtBtnAcc, textBtnClose, actionNext, close
}) => {
  return (
    <Modal show={isShow} className='modal-all' onHide={close}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="col-12 flex" style={{ flexDirection: 'column', textAlign: 'left' }}>
          <div className="col-12 flex" style={{ justifyContent: 'center' }}>
            <div className="col-3">{"Thời gian bắt đầu: "}</div>
            <div className="col-8">
              <DatePicker dropdownClassName="customDropdownPicker" onChange={onChangeIp1} defaultValue={moment(record?.startTime)} showTime />
            </div>
          </div>
          <div className="col-12 flex mt-10" style={{ justifyContent: 'center' }}>
            <div className="col-3">{"Thời gian hoàn thành: "}</div>
            <div className="col-8">
              <DatePicker dropdownClassName="customDropdownPicker" onChange={onChangeIp2} defaultValue={moment(record?.completionTime)} showTime />
            </div>
          </div>
          <div className="col-12 flex mt-10" style={{ justifyContent: 'center' }}>
            <div className="col-3">{"Mô tả: "}</div>
            <div className="col-8">
              <Input onChange={onChangeIp3} defaultValue={record?.description} />
            </div>
          </div>
          <div className="col-12 flex mt-10" style={{ justifyContent: 'center' }}>
            <div className="col-3">{"Trạng thái: "}</div>
            <div className="col-8">
              <Radio.Group onChange={onChangeIp4} defaultValue={record?.status}>
                <Radio value={"progress"}>Đang chạy</Radio>
                <Radio value={"completed"}>Hoàn thành</Radio>
                <Radio value={"canceled"}>Đã hủy</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="col-12 flex mt-10" style={{ justifyContent: 'center' }}>
            <div className="col-3">{"Bảo trì lập tức: "}</div>
            <div className="col-8">
              <Radio.Group onChange={onChangeIp5} defaultValue={record?.immediateMaintenance}>
                <Radio value={true}>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {textBtnClose ?
          <>
            <Button type="primary" onClick={actionNext}>
              {txtBtnAcc}
            </Button>
            <Button type="default" onClick={close}>
              {textBtnClose}
            </Button>
          </>
          :
          <Button type="primary" onClick={actionNext}>
            {txtBtnAcc}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

const MaintenanceList = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [valIp1, setValIp1] = useState();
  const [valIp2, setValIp2] = useState();
  const [valIp3, setValIp3] = useState();
  const [valIp4, setValIp4] = useState();
  const [valIp5, setValIp5] = useState();
  const [recordSl, setRecordSl] = useState();
  const [dataMain, setDataMain] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    getListData();
  }, []);

  const getListData = async () => {
    setIsLoading(true);
    await API.get(`${MAINTENANCE}`)
      .then((response) => {
        const result = response.data?.data;
        if (result?.length > 0) {
          setDataMain(result)
        } else {
          setDataMain([])
        }
        
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const onCreateData = () => {
    setIsShowModal(true)
  }

  const onClearModal = () => {
    setIsShowModal(false);
    setValIp1(undefined);
    setValIp2(undefined);
    setValIp3(undefined);
    setValIp4(undefined);
    setValIp5(undefined);
    setRecordSl(undefined);
  }

  const requestData = async (type) => {
    if (type === "create") {
      const params = {
        startTime: valIp1,
        completionTime: valIp2,
        description: valIp3.trim(),
        status: valIp4,
        immediateMaintenance: valIp5
      }
      try {
        await API.post(`${MAINTENANCE}`, params)
          .then((response) => {
            const data = response.data?.data;
            if (typeof data == "object") {
              getListData();
              onClearModal();
            }
          })
      } catch (error) {
        console.log(error)
      }
      onClearModal();
    } else if (type === "edit") {
      const params = {
        startTime: valIp1 ? valIp1 : recordSl?.startTime,
        completionTime: valIp1 ? valIp2 : recordSl?.completionTime,
        description: valIp3 ? valIp3.trim().toString() : recordSl?.description,
        status: valIp4 ? valIp4 : recordSl?.status,
        immediateMaintenance: (valIp5 === false || valIp5 === true) ? valIp5 : recordSl?.immediateMaintenance,
      }
      try {
        await API.put(`${MAINTENANCE}/${recordSl?.id}`, params)
          .then((response) => {
            const data = response.data?.data;
            if (typeof data == "object") {
              getListData();
              onClearModal();
            }
          })
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await API.delete(`${MAINTENANCE}/${recordSl?.id}`)
          .then((response) => {
            const data = response.data?.data;
            if (typeof data == "object") {
              getListData();
              onClearModal();
              setIsShowModalConfirm(false);
              setRecordSl(undefined);
            }
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Thời gian bắt đầu",
      key: "startTime",
      render: (record) =>
        moment(record?.startTime).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Thời gian hoàn thành",
      key: "completionTime",
      render: (record) =>
        moment(record?.completionTime).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Trạng thái",
      key: "status",
      render: (record) =>
        record?.status === "completed" ? "Hoàn thành" : record?.status === "progress" ? "Đang chạy" : "Đã hủy",
    },
    {
      title: "Bảo trì lập tức",
      key: "immediateMaintenance",
      render: (record) =>
        record.immediateMaintenance ? "Có" : "Không",
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      render: (record) =>
        moment(record?.created_at).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    // {
    //   title: "Tài khoản tạo",
    //   key: "createdBy",
    //   dataIndex: "createdBy",
    // },
    {
      title: "Ngày cập nhật",
      key: "updatedAt",
      render: (record) =>
        moment(record?.updated_at).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    // {
    //   title: "Người cập nhật",
    //   key: "updatedBy",
    //   dataIndex: "updatedBy",
    // },
    {
      title: TEXT_DEF.text0016,
      key: "edit",
      align: "center",
      render: (record) =>
        auth?.role === "supper" ? (
          <div className="flex justify-center">
            <img
              style={{
                height: 20,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              src={settingIcon}
              alt="setting"
              onClick={() => {
                setRecordSl(record);
                onCreateData();
              }}
            />
          </div>
        ) : null,
    },
    {
      title: "Xóa",
      key: "detele",
      render: (record) =>
        auth?.role === "supper" ? (
          <div className="flex justify-center">
            <img
              style={{
                height: 20,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              src={deteleIcon}
              alt="delete"
              onClick={() => {
                setRecordSl(record);
                setIsShowModalConfirm(true);
              }}
            />
          </div>
        ) : null,
    },
  ]

  const onChangeDate1 = (date, dateString) => {
    setValIp1(date)
  };

  const onChangeDate2 = (date, dateString) => {
    setValIp2(date)
  };

  return (
    <div className="col-12">
      {auth?.role === "supper" ? (
        <div
          className="col-12 mt-10"
          style={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => onCreateData()}
          >
            {TEXT_DEF.text0068}
          </Button>
        </div>
      ) : null}
      <div
        className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        {dataMain &&
          <Table
            key="qaList"
            locale={locale}
            columns={columns}
            dataSource={dataMain}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        }
      </div>
      {isShowModal && (
        <ModalMaintenance
          isShow={isShowModal}
          onChangeIp1={onChangeDate1}
          onChangeIp2={onChangeDate2}
          onChangeIp3={(e) => setValIp3(e?.target.value)}
          onChangeIp4={(e) => setValIp4(e.target.value)}
          onChangeIp5={(e) => setValIp5(e.target.value)}
          record={recordSl}
          txtBtnAcc={TEXT_DEF.text0030}
          textBtnClose={TEXT_DEF.text0007}
          actionNext={() => requestData(recordSl ? "edit" : "create")}
          close={onClearModal}
        />
      )}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0069}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0007}
          actionNext={() => requestData("delete")}
          close={() => { setIsShowModalConfirm(false); setRecordSl(undefined) }}
        />
      )}
    </div>
  );
};

export default MaintenanceList;
