import React, { useState, useEffect, useRef } from "react";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, Select, Checkbox } from "antd";
import API, { REGISTER, BOOKMAKER, ROLES } from "../../network/api";
import { TEXT_DEF, CREATE_FAIL, GET_DATA_FAIL } from "../../constant/text";
import { hasRight } from "../../components/until/right";
import { RIGHTS } from "../../constant/rights";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const CreateAdminUser = () => {
  let isRequest = useRef(false);
  const { Option } = Select;
  const limit = 1000;
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMess, setErrorMess] = useState();
  const [bookmarkers, setBookmarkers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [messageError, setMessageError] = useState("");
  const [messageSysAdminError, setMessageSysAdminError] = useState("");
  const [messPWError, setMessPWError] = useState("");
  const [firstBookmarker, setFirstBookmarker] = useState("");

  const backScreen = () => {
    navigate("/account-manager");
  };

  useEffect(() => {
    forceUpdate({});
    getBookmarkers();
  }, []);

  useEffect(() => {
    getRoles(firstBookmarker);
  }, [firstBookmarker]);

  const validatePassword = (password = "") => {
    const regex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return regex.test(password);
  };

  const createUser = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    if (!validatePassword(form.getFieldValue("password").trim())) {
      setMessPWError(
        "Mật khẩu dài 8 ký tự,bao gồm 1 chữ hoa, chữ thường cùng với một số và ký tự đặc biệt !"
      );
      return;
    }

    const body = {
      username: form.getFieldValue("username").trim(),
      password: form.getFieldValue("password").trim(),
      bookmakerId: form.getFieldValue("bookmarker"),
      roleAdminUserId: form.getFieldValue("role"),
      isAdminSystem: form.getFieldValue("isAdminSystem"),
    };

    try {
      setLoading(true);
      const result = await API.post(REGISTER, body);
      const message = _.get(result, "data.data.result", "");
      const code = _.get(result, "data.data.code", 0);
      if (code === 40000) {
        setMessageError(message);
        return;
      } else {
        setMessageError("");
        setMessPWError("");
      }
      if (code === 40001) {
        setMessageSysAdminError(message);
        return;
      } else {
        setMessageSysAdminError("");
      }
      navigate("/account-manager");
      setLoading(false);
      isRequest.current = false;
    } catch (err) {
      setLoading(false);
      toast(CREATE_FAIL);
      isRequest.current = false;
    }
  };

  const getRoles = async (bookmarkerId) => {
    if (!bookmarkerId) {
      setRoles([]);
      return;
    }

    try {
      setLoading(true);
      const result = await API.get(`${ROLES}/bookmarker/${bookmarkerId}`);
      const data = _.get(result, "data.data", []);
      setRoles(data);
      initFormValues({
        role: data?.[0]?.id || "",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  const onChangeBookmarker = (value) => {
    setFirstBookmarker(value);
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    setFirstBookmarker(tempBookmarkers?.[0]?.key);
    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.key,
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;

    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
      });
    }
  };

  const renderFormByUser = () => {
    return (
      <div className="w-full flex flex-col">
        <Button type="default" htmlType="button" style={{ marginBottom: 30, width: 80 }} onClick={backScreen}>
          Quay lại
        </Button>

        <Form className="form-create" form={form} layout="inline" autoComplete="off" onFinish={createUser}>
          <div className="col-8">
            <div className="col-12" style={{ display: "flex", alignItems: "center", color: "red" }}>
              <div className="col-3"></div>
              <div className="col-9">{errorMess}</div>
            </div>
            <div style={{ marginBottom: 30 }} />

            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-3">
                {`${TEXT_DEF.text0002}`} <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-9">
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                  style={{ marginRight: 0 }}
                >
                  <Input maxLength={255} onChange={() => setErrorMess()} />
                </Form.Item>
                <div className="message-error">{messageError}</div>
              </div>
            </div>

            <div style={{ marginBottom: 30 }} />

            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-3">
                {`Mật khẩu`} <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-9">
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                  style={{ marginRight: 0 }}
                >
                  <Input maxLength={255} onChange={() => setErrorMess()} />
                </Form.Item>
                <div className="message-error">{messPWError}</div>
              </div>
            </div>

            {hasRight([RIGHTS.Super])
              ? <div
                  className="col-12"
                  style={{ display: "flex", alignItems: "center", marginTop: 30 }}
                >
                  <div className="col-3">
                    {`${TEXT_DEF.text0011}`}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="col-9">
                    <Form.Item
                      name="bookmarker"
                      rules={[
                        {
                          required: true,
                          message: TEXT_DEF.text0004,
                        },
                      ]}
                      style={{ marginRight: 0 }}
                    >
                      <Select onChange={onChangeBookmarker}>
                        {bookmarkers.map((item) => (
                          <Option key={item.key} value={item.key}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              : null}

            <div className="col-12" style={{ display: "flex", alignItems: "center", color: "red" }}>
              <div className="col-3"></div>
              <div className="col-9">{errorMess}</div>
            </div>
            <div style={{ marginBottom: 30 }} />

            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-3">
                Vai trò <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-9">
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                  style={{ marginRight: 0 }}
                >
                  <Select>
                    {roles.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} />
            {hasRight([RIGHTS.Super]) ? (
              <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
                <div className="col-3">Is system admin</div>
                <div className="col-9">
                  <Form.Item name="isAdminSystem" valuePropName="checked">
                    <Checkbox>Is system admin</Checkbox>
                  </Form.Item>
                  <div className="message-error">{messageSysAdminError}</div>
                </div>
              </div>
            ) : null}

            <Form.Item style={{ width: "100%" }}>
              <div className="lg-btn">
                <Button type="primary" htmlType="submit">
                  {TEXT_DEF.text0018}
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  };

  return <div className="col-12">{renderFormByUser()}</div>;
};

export default CreateAdminUser;
