export const PERMISSION = [
  {
    id: 0,
    value: "user_view",
    label: "Xem hội viên",
  },
  {
    id: 1,
    value: "user_edit",
    label: "Tạo admin",
  },
  {
    id: 20,
    value: "user_edit_info",
    label: "Cài đặt các khóa user",
  },
  {
    id: 2,
    value: "request_top_up",
    label: "Admin tạo request bù trừ coins user",
  },
  {
    id: 3,
    value: "approve_top_up",
    label: "Admin duyệt yêu cầu",
  },
  {
    id: 4,
    value: "transaction_view",
    label: "Xem lịch sử giao dịch",
  },
  {
    id: 5,
    value: "wallet_view",
    label: "Xem danh sách ví chính",
  },

  // {
  //   id: 14,
  //   value: "wallet_view_update",
  //   label: "Cài đặt ví chính",
  // },
  {
    id: 15,
    value: "sub_wallet_view",
    label: "Xem danh sách ví con",
  },
  // {
  //   id: 17,
  //   value: "sub_wallet_update",
  //   label: "Cài đặt ví con",
  // },
  {
    id: 10,
    value: "system_config_view",
    label: "Xem cài đặt hệ thống",
  },
  {
    id: 11,
    value: "system_config_edit",
    label: "Cập nhật cài đặt hệ thống",
  },
  {
    id: 12,
    value: "admin_permissions_view",
    label: "Xem quyền của admin",
  },
  {
    id: 13,
    value: "admin_permissions_edit",
    label: "Cập nhật quyền của admin",
  },
];
