import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'antd';
import './style.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ModalChart = ({isShow, dataChart, title, close}) => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
            text: title,
          },
        },
    };
    
    return (
        <Modal show={isShow} className='modal-chart' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Line options={options} data={dataChart} />
            </Modal.Body>
            <Modal.Footer>
                <Button type="default" onClick={close}>
                    Đóng
                </Button>  
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChart