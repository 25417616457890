import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import API, { QA } from "../../network/api";
import { TEXT_DEF, UPDATE_SUCCESS } from "../../constant/text";
import { ConfirmModal } from "./mainModal";
const { TextArea } = Input;

const EditTopicOrQA = ({ record, type = "editQA", handleCancelModal, setIsReloadQa, topicRecord, setIsReloadTopics }) => {
  const [frmEditQA] = Form.useForm();
  const [frmEditTopic] = Form.useForm();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});

  const currentForm = type === "editQA" ? frmEditQA : frmEditTopic;
  const subNamesWithIds = topicRecord?.childrenNames?.map((item) => ({
    id: item.subId,
    name: item.subName,
  }));

  useEffect(() => {
    frmEditQA.setFieldValue("mainTopic", record?.parent?.parent?.name);
    frmEditQA.setFieldValue("subTopic", record?.parent?.name);
    frmEditQA.setFieldValue("question", record?.question);
    frmEditQA.setFieldValue("answer", record?.answer);
    frmEditTopic.setFieldValue("main", topicRecord?.mainName);
    frmEditTopic.setFieldValue("sub", topicRecord?.childrenNames?.map((item) => item.subName).join(", "));
  }, [frmEditQA, frmEditTopic, record, topicRecord]);

  const handleOnFinish = async (values) => {
    if (type === "editQA") {
      const params = {
        question: values?.question,
        answer: values?.answer,
      };

      setFormValues(params);
      setIsConfirmModalOpen(true);
    } else {
      let params = {};

      if (topicRecord) {
        params.mainTopic = {
          id: topicRecord?.mainId,
          name: currentForm?.getFieldValue("main"),
        };
      }

      if (topicRecord) {
        params.subTopic = {
          id: topicRecord?.subTopicID,
          name: currentForm?.getFieldValue("sub"),
        };
      }

      setFormValues(params);
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirm = async () => {
    if (type === "editQA") {
      await handleUpdateQA(formValues);
    } else {
      await handleUpdateTopic(formValues);
    }
    setFormValues({});
    alert(UPDATE_SUCCESS);
  };

  const handleUpdateQA = async (params) => {
    try {
      await API.patch(`${QA}/${record.id}`, params).then((response) => {
        const statusCode = response?.data?.data?.code;
        if (statusCode === 20004) {
          setIsReloadQa(true);
          setIsConfirmModalOpen(false);
          handleCancelModal();
        }
      });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleUpdateTopic = async (params) => {
    try {
      const tmp = params.subTopic.name.split(",").map((name) => name.trim());

      for (let i = 0; i < tmp.length; i++) {
        const newName = tmp[i];
        const originalEntry = subNamesWithIds[i];

        if (originalEntry && newName !== originalEntry.name) {
          originalEntry.name = newName;
        }
      }

      if (params.mainTopic) {
        await API.patch(`${QA}/${params?.mainTopic?.id}`, { name: params?.mainTopic?.name }).then((response) => {
          const statusCode = response?.data?.data?.code;
          if (statusCode === 20004) {
            setIsReloadTopics(true);
            setIsConfirmModalOpen(false);
            handleCancelModal();
          }
        });
      }

      if (params.subTopic.id === undefined) {
        setIsReloadTopics(false);

        for (const subTopic of subNamesWithIds) {
          await API.patch(`${QA}/${subTopic?.id}`, { name: subTopic?.name }).then((response) => {
            const statusCode = response?.data?.data?.code;
            if (statusCode === 20004) {
              setIsReloadTopics(true);
              setIsConfirmModalOpen(false);
              handleCancelModal();
            }
          });
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleCloseEditModal = () => {
    currentForm.resetFields();
    handleCancelModal();
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
    currentForm.resetFields();
    handleCancelModal();
  };

  const renderFormItem = (label, name, defaultValue, disabled = false, isVisible) => {
    return (
      <div className="form-group col-12 flex">
        <label htmlFor={name} className="col-3" style={{ display: type !== "editQA" && isVisible ? "none" : "block" }}>
          {label} <span style={{ color: "red" }}>*</span>
        </label>
        <Form.Item name={name} style={{ marginRight: 0, width: "100%" }}>
          {name === "answer" ? (
            <TextArea
              defaultValue={defaultValue}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
          ) : disabled ? (
            <Input defaultValue={defaultValue} disabled={disabled} />
          ) : (
            <Input defaultValue={defaultValue} style={{ display: isVisible ? "none" : "block" }} />
          )}
        </Form.Item>
      </div>
    );
  };

  const fields = [
    {
      label: TEXT_DEF.text0175,
      name: type === "editQA" ? "mainTopic" : "main",
      defaultValue: type === "editQA" ? record?.parent?.parent?.name : topicRecord?.mainName,
      disabled: type === "editQA",
      isVisible: type !== "editQA" && !!topicRecord?.parent,
    },
    {
      label: TEXT_DEF.text0176,
      name: type === "editQA" ? "subTopic" : "sub",
      defaultValue: type === "editQA" ? record?.parent?.name : subNamesWithIds?.map((item) => item?.name).join(", "),
      disabled: type === "editQA",
      isVisible: type !== "editQA" && !!topicRecord?.parent,
    },

    ...(type === "editQA"
      ? [
          { label: TEXT_DEF.text0181, name: "question", defaultValue: record?.question },
          { label: TEXT_DEF.text0182, name: "answer", defaultValue: record?.answer },
        ]
      : []),
  ];

  return (
    <>
      {!isConfirmModalOpen ? (
        <Form form={currentForm} style={{ padding: "2rem" }} onFinish={handleOnFinish} autoComplete="off">
          <div className="col-12 flex justify-center items-center flex-col">{fields.map((item, index) => renderFormItem(item.label, item.name, item.defaultValue, item.disabled, item?.isVisible))}</div>

          <div className="col-12 flex justify-center gap-10">
            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" disabled={!!currentForm.getFieldsError().filter(({ errors }) => errors.length).length}>
                  {TEXT_DEF.text0030}
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="default" onClick={handleCloseEditModal}>
                {TEXT_DEF.text0007}
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <ConfirmModal handleConfirm={handleConfirm} handleCancelModal={handleCloseConfirmModal} text={type === "editQA" ? TEXT_DEF.text0192 : TEXT_DEF.text0191} />
      )}
    </>
  );
};

export default EditTopicOrQA;
