import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import API, { BOOKMAKER } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import { RIGHTS, RIGHTS_DEFAULT } from "../../constant/rights";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const UpdateBookmaker = () => {
  let isRequest = useRef(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [errorMess, setErrorMess] = useState();
  const [rights, setRights] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDetail = async () => {
      await API.get(`${BOOKMAKER}/${id}`).then((response) => {
        const result = response?.data?.data?.result;
        const tempRights = (result.permissions || '').split(',');
        const rights = separateRolesByModule(id);
        rights.forEach((i) => {
            (i.rights || []).map((r) => {
                const hasRight = tempRights.some((rightSelected) => rightSelected === r.Name);
                if (hasRight) {
                    r.checked = true;
                } else {
                    r.checked = false;
                }

                return r;
            });
        });
        setRights([...rights]);
        if (result) {
          form.setFieldValue("name", result?.name);
          form.setFieldValue("gameReg", result?.gameReg);
        }
      });
    };

    getDetail();
  }, []);

  const getRightsCreated = () => {
    const rightsBody = [];
    for (const i of (rights || [])) {
        for (const r of i.rights) {
            if (r.checked) {
                rightsBody.push(r.Name);
            }
        }
    }

    return rightsBody.join(',');
  }

  const onSubmitForm = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    const permissions = getRightsCreated();
    const params = {
      name: form.getFieldValue("name"),
      gameReg: form.getFieldValue("gameReg"),
      permissions,
    };

    const URL = `${BOOKMAKER}/${id}`
    await API.patch(URL, params).then((response) => {
      const result = response.data.data;
      if (result?.code === 20004) {
        navigate("/bookmaker");
      }
      isRequest.current = false;
    }).catch(() => {
      isRequest.current = false;
    });
  };

  const backScreen = () => {
    navigate("/bookmaker");
  };

  const onChangeRight = (item) => {
    if (!item) return;

    rights.forEach((i) => {
        (i.rights || []).map((r) => {
            if (r.Name === item.Name) {
                r.checked = !item.checked;
            }

            return r;
        });
    });

    setRights([...rights]);
  };

  const getModules = () => {
    const modules = [];
    for (const k in RIGHTS) {
        const hasModule = modules.some(i => i.module === RIGHTS[k].Module);
        if (hasModule) continue;

        modules.push({
            module: RIGHTS[k].Module,
            moduleTitle: RIGHTS[k].Module_Title,
        });
    }

    return modules;
  }

  const separateRolesByModule = (id) => {
    const modules = getModules();
    const rightsByModule = [];

    for (const m of modules) {
        if (m.module === 'super') continue;

        const rights = [];
        for (const r in RIGHTS) {
            if (RIGHTS[r].Module === m.module) {
                if (!id) {
                    const hasRightDefault = RIGHTS_DEFAULT.some(rd => rd === RIGHTS[r].Name);
                    if (hasRightDefault) {
                        RIGHTS[r].checked = true;
                    } else {
                        RIGHTS[r].checked = false;
                    }
                }
                rights.push(RIGHTS[r]);
            }
        }
        rightsByModule.push({
            module: m.module,
            moduleTitle: m.moduleTitle,
            rights: rights,
        });
    }

    return rightsByModule;
  };

  const renderForm = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={onSubmitForm}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0011} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", color: "red" }}
          >
            <div className="col-3"></div>
            <div className="col-9">{errorMess}</div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
                Quyền hạn <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
                {
                    rights.map((m) => {
                        return (
                            <div className="group-roles">
                                <div className="module">{m.moduleTitle}</div>
                                {
                                    m.rights.map((r) => {
                                        return (
                                            <Form.Item name="right" className="right">
                                                <Checkbox onChange={() => { onChangeRight(r) }} checked={r.checked} key={r.Code} value={r.Name}>{r.Right_Title}</Checkbox>
                                            </Form.Item>
                                        );
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
          </div>

          <Form.Item shouldUpdate style={{ width: "100%" }}>
            {() => (
              <div className="lg-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    // !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    );
  };

  return (
    <div className="col-12">
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {TEXT_DEF.text0017}
      </Button>
      {renderForm()}
    </div>
  );
};

export default UpdateBookmaker;
