import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Spin, Input, Checkbox, Select } from "antd";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import {
  TEXT_DEF,
  CREATE_SUCCESS,
  CREATE_FAIL,
  GET_DATA_FAIL,
} from "../../constant/text";
import API, { ROLE_CREATE, BOOKMAKER } from "../../network/api";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";

const RoleCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [rights, setRights] = useState([]);
  const [messageErrorDuplicate, setMessageErrorDuplicate] = useState("");
  const [bookmarkers, setBookmarkers] = useState();
  let isRequest = useRef(false);

  useEffect(() => {
    getBookmarkers();
    handlerRole();
  }, []);

  const handlerRole = async () => {
    const role = await getRoleUserById(id);
    const bookmarkerId = role?.bookmaker?.id;
    let rightsOfBookmarker = await getRightsByBookmarker(bookmarkerId);
    let rightsOfRole = role?.permissions || "";
    rightsOfRole = rightsOfRole.split(",");
    rightsOfBookmarker = selectedRights(rightsOfBookmarker, rightsOfRole);
    setRights(rightsOfBookmarker);
    form.setFieldsValue({
      name: role?.name || "",
      bookmarker: bookmarkerId,
    });
  };

  const selectedRights = (rights, permissionsOfRole) => {
    rights.forEach((i) => {
      (i.rights || []).map((r) => {
        const hasRight = permissionsOfRole.some(
          (rightSelected) => rightSelected === r.Name
        );
        if (hasRight) {
          r.checked = true;
        } else {
          r.checked = false;
        }

        return r;
      });
    });

    return rights;
  };

  const getBookmarkers = async () => {
    try {
      const result = await API.get(`${BOOKMAKER}/all`);
      const bookmarkers = result.data?.data?.result[0] || [];
      if (!bookmarkers || bookmarkers.length === 0) return;

      const tempBookmarkers = bookmarkers?.map((item) => {
        return {
          key: item?.id,
          value: item?.id,
          label: item?.name,
        };
      });
      setBookmarkers(tempBookmarkers);
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  const getRoleUserById = async (id) => {
    if (!id) return;

    try {
      setLoading(true);
      const result = await API.get(`${ROLE_CREATE}/${id}`);
      setLoading(false);
      const response = _.get(result, "data.data", {});

      return response;
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  const createRole = async () => {
    const rightsBody = [];
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    for (const i of rights || []) {
      for (const r of i.rights) {
        if (r.checked) {
          rightsBody.push(r.Name);
        }
      }
    }
    const body = {
      name: form.getFieldValue("name"),
      permissions: rightsBody ? rightsBody.join(",") : "",
      bookmaker: form.getFieldValue("bookmarker"),
    };

    try {
      setLoading(true);
      let result;
      if (!id) {
        result = await API.post(ROLE_CREATE, body);
      } else {
        result = await API.patch(`${ROLE_CREATE}/${id}`, body);
      }
      setLoading(false);
      const status = _.get(result, "data.success", false);
      if (status === true) {
        toast(CREATE_SUCCESS);
        navigate("/roles");
      } else {
        toast(CREATE_FAIL);
      }
      isRequest.current = false;
    } catch (err) {
      const message = _.get(err, "response.data.message.message", "");
      if (message) {
        setMessageErrorDuplicate(message);
      } else {
        toast(CREATE_FAIL);
      }
      setLoading(false);
      isRequest.current = false;
    }
  };

  const getModules = () => {
    const modules = [];
    for (const k in RIGHTS) {
      const hasModule = modules.some((i) => i.module === RIGHTS[k].Module);
      if (hasModule) continue;

      modules.push({
        module: RIGHTS[k].Module,
        moduleTitle: RIGHTS[k].Module_Title,
      });
    }

    return modules;
  };

  const onChangeRight = (item) => {
    if (!item) return;

    rights.forEach((i) => {
      (i.rights || []).map((r) => {
        if (r.Name === item.Name) {
          r.checked = !item.checked;
        }

        return r;
      });
    });

    setRights([...rights]);
  };

  const getRightsByBookmarker = async (bookmarkerId, action = "") => {
    const modules = getModules();
    const finalRights = [];
    let rightsOfBookmarker = [];
    if (bookmarkerId) {
      let permissions = await getRightsByBookmarkerId(bookmarkerId);
      if (permissions) {
        permissions = permissions?.split(",") || [];
      } else {
        permissions = [];
      }
      for (const k in RIGHTS) {
        const hasPermission = (permissions || []).some(
          (p) => p === RIGHTS[k].Name
        );
        if (hasPermission) {
          rightsOfBookmarker.push(RIGHTS[k]);
        }
      }
    } else {
      const isSuper = hasRight([RIGHTS.Super]);
      if (isSuper) {
        rightsOfBookmarker = [];
      } else {
        let permissions = await getRightsByBookmarkerId(-1);
        if (permissions) {
          permissions = permissions?.split(",") || [];
        } else {
          permissions = [];
        }
        for (const k in RIGHTS) {
          const hasPermission = (permissions || []).some(
            (p) => p === RIGHTS[k].Name
          );
          if (hasPermission) {
            rightsOfBookmarker.push(RIGHTS[k]);
          }
        }
      }
    }

    for (const m of modules) {
      if (m.module === "super") continue;

      const rightsByModule = [];
      for (const r in rightsOfBookmarker) {
        if (rightsOfBookmarker[r].Module === m.module) {
          rightsByModule.push(rightsOfBookmarker[r]);
        }
      }
      finalRights.push({
        module: m.module,
        moduleTitle: m.moduleTitle,
        rights: rightsByModule,
      });
    }

    return finalRights.filter((r) => {
      return r.rights.length > 0;
    });
  };

  const onChangeBookmarker = async (value) => {
    let rights = await getRightsByBookmarker(value);
    const role = await getRoleUserById(id);
    let permissionsOfRole = role?.permissions || "";
    permissionsOfRole = permissionsOfRole.split(",");

    rights = selectedRights(rights, permissionsOfRole);
    setRights(rights);
  };

  const getRightsByBookmarkerId = async (id) => {
    try {
      const result = await API.get(`${BOOKMAKER}/${id}`);
      const permissions = result?.data?.data?.result?.permissions || "";

      return permissions;
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  return (
    <Spin spinning={loading} delay={500} size="large">
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={createRole}
      >
        <div className="col-8">
          {hasRight([RIGHTS.Super]) ? (
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-3">
                Chọn nhà cái {!id && <span style={{ color: "red" }}>*</span>}
              </div>
              <div className="col-9">
                <Form.Item
                  name="bookmarker"
                  rules={[
                    { required: true, message: "Vui lòng nhập thông tin!" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    options={bookmarkers}
                    onChange={onChangeBookmarker}
                    allowClear
                    disabled={id}
                  />
                </Form.Item>
              </div>
            </div>
          ) : null}

          <div style={{ marginBottom: 30 }} />
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              Tên vai trò
              {!id && <span style={{ color: "red" }}>*</span>}
            </div>
            <div className="col-9">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} readOnly={id} />
              </Form.Item>
              <div style={{ color: "red" }}>{messageErrorDuplicate}</div>
            </div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">Quyền hạn</div>
            <div className="col-9">
              {rights.map((m) => {
                return (
                  <div className="group-roles">
                    <div className="module">{m.moduleTitle}</div>
                    {m.rights.map((r) => {
                      return (
                        <Form.Item name="right" className="right">
                          <Checkbox
                            onChange={() => {
                              onChangeRight(r);
                            }}
                            checked={r.checked}
                            key={r.Code}
                            value={r.Name}
                          >
                            {r.Right_Title}
                          </Checkbox>
                        </Form.Item>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <Form.Item>
            <div className="lg-btn">
              <Button type="primary" htmlType="submit">
                {!id ? TEXT_DEF.text0018 : TEXT_DEF.text0016}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default RoleCreate;
