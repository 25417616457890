import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
    const [openTabs, setOpenTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    // tab state
    const [tabsState, setTabsState] = useState(JSON.parse(localStorage.getItem('tabsState')) || {});
    const location = useLocation();

    useEffect(() => {
        const storedTabs = JSON.parse(localStorage.getItem('openTabs')) || [];
        setOpenTabs(storedTabs);
        if (storedTabs.length > 0) {
            setActiveTab(storedTabs[0].key);
        }
        setTabsState(JSON.parse(localStorage.getItem('tabsState')))
    }, []);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (openTabs.some(tab => tab.key === currentPath)) {
            setActiveTab(currentPath);
        } else {
            setActiveTab('');
        }
    }, [openTabs, activeTab, location]);
    // tab state
    useEffect(() => {
        localStorage.setItem('tabsState', JSON.stringify(tabsState));
      }, [tabsState]);


    useEffect(() => {
        localStorage.setItem('openTabs', JSON.stringify(openTabs));

    }, [openTabs]);
    

    const addTab = (key, text, state = {}) => {
        if (!openTabs.some(tab => tab.key === key)) {
            setOpenTabs([...openTabs, { key, text }]);
        }
        // set tab state
        setTabsState(prevState => ({
            ...prevState,
            [key]: state
          }));
        // set tab active
        setActiveTab(key);
    };

    const modifyTabContent = (key, state) => {
        if (!openTabs.some(tab => tab.key === key)) {
            return;
        }
        setTabsState(prevState => ({
            ...prevState,
            [key]: state
          }));
    }

    const closeTab = (key) => {
        const newTabs = openTabs.filter(tab => tab.key !== key);
        setOpenTabs(newTabs);

        if (newTabs.length > 0) {
        //   setActiveTab(newTabs[0].key);
        } else {
            setActiveTab('');
        }
        // set tab state
        setTabsState(prevState => {
            const newState = { ...prevState };
            delete newState[key];
            return newState;
        });
    };

    const changeTab = (key) => {
        setActiveTab(key);
    };
    

    return (
        <TabsContext.Provider value={{ openTabs, activeTab, addTab, closeTab, changeTab, tabsState, modifyTabContent }}>
        {children}
        </TabsContext.Provider>
    );
};

export const useTabs = () => useContext(TabsContext);
