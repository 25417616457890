import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    Spin,
    Popconfirm,
} from "antd";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from '@ant-design/icons';
import "./style.css";
import {
    EMPTY_DATA,
    LABELS_RANK,
    LABEL_CREATE,
    TEXT_DEF,
    DELETE_SUCCESS,
    DELETE_FAIL,
} from "../../constant/text";
import API, { RANK } from "../../network/api";

const Rank = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ranks, setRanks] = useState([]);
    const colums = [
        {
            title: LABELS_RANK.name,
            dataIndex: "rankName",
            key: "rankName",
        },
        {
            title: LABELS_RANK.limitAmount,
            dataIndex: "maxBetAmount",
            key: "maxBetAmount",
        },
        {
            title: 'Action',
            key: "setting",
            align: "center",
            render: (record) =>
                <div className="flex w-full items-center justify-center">
                    <div>
                        <Button
                            className="edit"
                            onClick={() => {
                                navigate(`/ranks/${record.id}`);
                            }} danger
                        >Edit</Button>
                    </div>
                    {/* <div>
                        <Popconfirm
                            title="Delete the task"
                            description={TEXT_DEF.text0069}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => { confirm(record.id) }}
                            onCancel={cancel}
                        >
                            <Button danger>Delete</Button>
                        </Popconfirm>
                    </div> */}
                </div>
        },
    ];

    useEffect(() => {
        getRanks();
    }, []);

    const getRanks = async () => {
        try {
            setLoading(true);
            const result = await API.get(RANK);
            setLoading(false);
            const ranks = result.data?.data || [];
            if (!ranks || ranks.length === 0) return;

            const finalResult = [];
            for (const item of ranks) {
                finalResult.push({
                    id: item.id,
                    rankName: item.rankName,
                    maxBetAmount: item.maxBetAmount,
                });
            }

            setRanks(finalResult);
        } catch (err) {
            setLoading(false);
        }
    }

    const confirm = async (id) => {
        try {
            await API.delete(`${RANK}/${id}`);
            await getRanks();
            toast(DELETE_SUCCESS);
        } catch (err) {
            setLoading(false);
            toast(DELETE_FAIL);
        }
    };

    const cancel = (e) => { };


    return (
        <div className="bonus-config">
            {/* <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
                <Button type="primary" onClick={createBonusConfig}>
                    {LABEL_CREATE}
                </Button>
            </div> */}
            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                    <Table
                        key="RANK"
                        locale={EMPTY_DATA}
                        columns={colums}
                        dataSource={ranks}
                        pagination={false}
                        bordered
                        style={{ width: "100%" }}
                    />
                </div>
            </Spin>
        </div>
    );
}

export default Rank;