import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash';
import { Form, Button, Spin, Select, Input } from 'antd';
import API, { ADMIN_SETTING } from '../../network/api';
import { ToastContainer, toast } from 'react-toastify';
import { ERROR_MESSAGES } from '../../constant/error';
import { RIGHTS } from '../../constant/rights';
import { hasRight } from '../../components/until/right';
import { TEXT_DEF } from '../../constant/text';

const SettingClassicGames = () => {
  let isRequest = useRef(false);
  const [settingId, setSettingId] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (isRequest.current) {
      return;
    }

    isRequest.current = true;
    const maximumNumberOfBets = form.getFieldValue('maximumNumberOfBets');
    const biggestBet = form.getFieldValue('biggestBet');

    const body = {
      maximumNumberOfBets,
      biggestBet,
    };

    try {
      setLoading(true);
      if (!settingId) {
        await API.post(`${ADMIN_SETTING}`, body);
      } else {
        await API.patch(`${ADMIN_SETTING}/${settingId}`, body);
      }
      setLoading(false);
      isRequest.current = false;
      toast(ERROR_MESSAGES.UPDATE_SUCCESS);
    } catch (err) {
      setLoading(false);
      isRequest.current = false;
      toast(ERROR_MESSAGES.UPDATE_FAIL);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    try {
      setLoading(true);
      const result = await API.get(`${ADMIN_SETTING}`);
      const setting = _.get(result, 'data.data', {});
      form.setFieldsValue({
        maximumNumberOfBets: setting?.maximumNumberOfBets || 50,
        biggestBet: addCommas(setting?.biggestBet) || 999999999,
      });
      setSettingId(setting?.id || '');
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const addCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <Spin spinning={loading} delay={500} size="large">
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => submit()}>
            <div className="col-4">
              <h5>Điều kiện hold đơn</h5>
              <div style={{ marginBottom: 10 }} />
              <Form.Item
                name="maximumNumberOfBets"
                label={TEXT_DEF.text0205}
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                  {
                    type: 'number',
                    min: 0,
                    message: 'Nhập kiểu số',
                    transform(value) {
                      if (!value) return;

                      return Number(value);
                    },
                  },
                ]}
              >
                <Input style={{ width: '90%' }} />
              </Form.Item>
              <div style={{ marginBottom: 10 }} />
              <Form.Item
                name="biggestBet"
                label={TEXT_DEF.text0206}
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                  {
                    type: 'number',
                    min: 0,
                    message: 'Nhập kiểu số',
                    transform(value) {
                      if (!value) return;

                      return Number(value);
                    },
                  },
                ]}
              >
                <Input style={{ width: '90%' }} />
              </Form.Item>
            </div>
            {hasRight([RIGHTS.EditSettingXSNCommon]) ? (
              <div className="col-4 payment-search">
                <Button htmlType="submit" type="primary">
                  Lưu
                </Button>
              </div>
            ) : null}
          </Form>
          <ToastContainer />
        </div>
      </Spin>
    </>
  );
};

export default SettingClassicGames;
