import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { ADMIN_ORDERS, BOOKMAKER, ADMIN_DASHBOARD, ADMIN_POKER, ADMIN_HILO, ADMIN_KENO } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { getGame } from "../../components/until";
import ModalChart from "./modal"; 

ChartJS.register(ArcElement, Tooltip, Legend, BarElement);

const ReportCom = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [indexTab, setIndexTab] = useState(1);
  localStorage.removeItem("indexTabAcc");
  const [loading, setLoading] = useState(false);
  const [typeSl, setTypeSl] = useState("day");
  const [typeChartSl, setTypeChartSl] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [dataChart, setDataChart] = useState(undefined);
  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);
  const [dataFillterDf, setDataFilterDf] = useState();
  const [dataGameLottery, setDataGameLottery] = useState()
  const [dataGame, setDataGame] = useState();
  const [gameType, setGameType] = useState();
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "aa",
      },
    },
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataBookmaker = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: item?.id,
              label: item?.name
            }
            dataBookmaker.push(newIt);
          });

          setOpBookmaker(dataBookmaker);
          // const dataFilterChart = localStorage.getItem("dataFilterChart");
  
          // if (dataFilterChart) {
          //   const filterJson = JSON.parse(dataFilterChart);
          //   setDataFilterDf(filterJson);
          //   setTypeChartSl(filterJson?.typeChart);
          //   form.setFieldValue("bookmakerFilter", dataBookmaker?.find(x => x.value === filterJson?.bookmakerId)?.label)
          //   form.setFieldValue("typeFilter", typeSl)
          //   form.setFieldValue("typeChartFilter", filterJson?.typeChart)
          //   if (filterJson?.typeChart) {
          //     const parmas = {
          //       bookmakerId: filterJson?.bookmakerId,
          //       typeFilter: "day"
          //     }
          //   } else {
          //     const parmas = {
          //       bookmakerId: filterJson?.bookmakerId,
          //       typeFilter: "day"
          //     }
          //     getDataChartOrder(parmas);
          //   }
          // }
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataBookmaker();
  }, [])

  const getDataChartUser = async (params) => {
    setLoading(true)
    const keyword = encodeURIComponent(JSON.stringify(params))
    await API.get(`${ADMIN_DASHBOARD}/chart-user?keyword=${keyword}`)
    .then((response) => {
      let result = response.data?.data?.result;
      if (result) {
        const labelDay = result?.map(item => item?.timeFilter);
        const dataCountUser = result?.map(item => item?.count);
        const dataChartUser = {
          labels: labelDay,
          datasets: [
            {
              label: "Số lượng hội viên",
              data: dataCountUser,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setDataChart(dataChartUser);
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  const getDataChartPoker = async (params) => {
    setLoading(true);
    const keyword = encodeURIComponent(JSON.stringify(params))
    await API.get(`${ADMIN_POKER}/report?keyword=${keyword}`)
    .then((response) => {
      let result = response.data?.data?.result;
      if (result) {
        const labelDay = result?.map(item => item?.timeFilter);
        const label = 
          typeChartSl === "order" ? TEXT_DEF.text0125 : 
          typeChartSl === "bet" ? TEXT_DEF.text0126 :
          TEXT_DEF.text0127;
          
        const dataShow = 
          typeChartSl === "order" ? result?.map(item => item?.count) : 
          typeChartSl === "bet" ? result?.map(item => item?.totalBet) :
          result?.map(item => Number(item?.totalPaymentWin));

        const dataChartOrder = {
          labels: labelDay,
          datasets: [
            {
              label: label,
              data: dataShow,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setDataGame(dataChartOrder);
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }
  const getDataChartHilo = async (params) => {
    setLoading(true);
    const keyword = encodeURIComponent(JSON.stringify(params))
    await API.get(`${ADMIN_HILO}/report?keyword=${keyword}`)
    .then((response) => {
      let result = response.data?.data?.result;
      if (result) {
        const labelDay = result?.map(item => item?.timeFilter);
        const label = 
          typeChartSl === "order" ? TEXT_DEF.text0125 : 
          typeChartSl === "bet" ? TEXT_DEF.text0126 :
          TEXT_DEF.text0127;
          
        const dataShow = 
          typeChartSl === "order" ? result?.map(item => item?.count) : 
          typeChartSl === "bet" ? result?.map(item => item?.totalBet) :
          result?.map(item => Number(item?.totalPaymentWin));

        const dataChartOrder = {
          labels: labelDay,
          datasets: [
            {
              label: label,
              data: dataShow,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setDataGame(dataChartOrder);
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  const getDataChartKeno = async (params) => {
    setLoading(true);
    const keyword = encodeURIComponent(JSON.stringify(params))
    await API.get(`${ADMIN_KENO}/report?keyword=${keyword}`)
    .then((response) => {
      let result = response.data?.data?.result;
      if (result) {
        const labelDay = result?.map(item => item?.timeFilter);
        const label = 
          typeChartSl === "order" ? TEXT_DEF.text0125 : 
          typeChartSl === "bet" ? TEXT_DEF.text0126 :
          TEXT_DEF.text0127;
          
        const dataShow = 
          typeChartSl === "order" ? result?.map(item => item?.count) : 
          typeChartSl === "bet" ? result?.map(item => item?.totalBet) :
          result?.map(item => Number(item?.totalPaymentWin));

        const dataChartOrder = {
          labels: labelDay,
          datasets: [
            {
              label: label,
              data: dataShow,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setDataGame(dataChartOrder);
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  const getDataChartOrder = async (params) => {
    setLoading(true)
    const keyword = encodeURIComponent(JSON.stringify(params))
    await API.get(`${ADMIN_DASHBOARD}/chart-order?keyword=${keyword}`)
    .then((response) => {
      let result = response.data?.data?.result;
      if (result) {
        const labelDay = result?.map(item => item?.timeFilter);
        const label = 
          typeChartSl === "order" ? TEXT_DEF.text0125 : 
          typeChartSl === "bet" ? TEXT_DEF.text0126 :
          TEXT_DEF.text0127;
        const dataShow = 
          typeChartSl === "order" ? result?.map(item => item?.count) : 
          typeChartSl === "bet" ? result?.map(item => item?.totalBet) :
          result?.map(item => Number(item?.totalPaymentWin) * -1);

        const dataChartOrder = {
          labels: labelDay,
          datasets: [
            {
              label: label,
              data: dataShow,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }
        setDataChart(dataChartOrder);
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  const getDataLotteryByGame = async (bookmakerId) => {
    setLoading(true)
    await API.get(`${ADMIN_ORDERS}/data-game-lottery?bookmakerId=${auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? auth?.bookmakerId : bookmakerId}`)
    .then((response) => {
      const result = response.data?.data?.result;
      if (result) {
        const dataList = [];
        result?.map((item, index) => {
          const itm = {
            key: `id-${index + 1}`,
            bookmakerName: item?.bookmakerName,
            game: getGame(item?.typeGame, item?.secondsGame),
            countBet: item?.count,
            totalBet: dollarUS.format(Number(item?.totalBet)),
            profit: dollarUS.format(Number(item?.totalPaymentWin) * -1),
          }
          dataList.push(itm)
        })
        setDataGameLottery(dataList);
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  const onSearch = () => {
    let params = {};
    if (indexTab == 1) {
      if (bookmakerSl && auth?.role === "supper") {
        params.bookmaker = bookmakerSl;
      }
      if (auth?.role === "admin_bm" || auth?.role === "administrators_bm"){
        params.bookmaker = auth?.bookmakerId
      }
      if (typeSl) {
        params.typeFilter = typeSl;
      }
      if (dateFrom) {
        params.startDate = moment(dateFrom).format("YYYY-MM-DD");
        if (!dateTo) params.endDate = "2125-12-31";
      }
      if (dateTo) {
        if (!dateFrom) params.endDate = "2023-01-01";
        params.endDate = moment(dateTo).format("YYYY-MM-DD");
      }
      // if (typeChartSl) {
      //   params.typeFilter = typeChartSl;
      // }
  
      getDataChartOrder(params)
    } else if (indexTab == 2) {
      if (bookmakerSl) {
        getDataLotteryByGame(bookmakerSl)
      }
    } else if(indexTab == 3) {
      if (dateFrom) {
        params.startDate = moment(dateFrom).format("YYYY-MM-DD");
        if (!dateTo) params.endDate = "2125-12-31";
      }
      if (dateTo) {
        if (!dateFrom) params.endDate = "2023-01-01";
        params.endDate = moment(dateTo).format("YYYY-MM-DD");
      }
      if (bookmakerSl && auth?.role === "supper") {
        params.bookmaker = bookmakerSl;
      }
      if (auth?.role === "admin_bm" || auth?.role === "administrators_bm"){
        params.bookmaker = auth?.bookmakerId
      }
      if (typeSl) {
        params.typeFilter = typeSl;
      }
      switch(gameType)
      {
        case 'poker':
          getDataChartPoker(params)
          break
        case 'hilo':
          getDataChartHilo(params)
          break
        default: 
          getDataChartKeno(params)
      }
    }
  };

  const columnsGameLot = [
    // {
    //   title: "STT",
    //   align: "center",
    //   key: "order",
    //   render: (text, record, index) => (
    //     <span>{(pagePlayHis - 1) * 10 + (index + 1)}</span>
    //   ),
    // },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmakerName",
      key: "bookmakerName",
    },
    {
      title: TEXT_DEF.text0080,
      key: "game",
      dataIndex: "game",
    },
    {
      title: TEXT_DEF.text0133,
      key: "countBet",
      dataIndex: "countBet",
    },
    {
      title: TEXT_DEF.text0093,
      key: "totalBet",
      dataIndex: "totalBet",
    },
    {
      title: TEXT_DEF.text0094,
      key: "profit",
      dataIndex: "profit",
    },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    if (e.target.value == 2) {
      getDataLotteryByGame(0)
    }
  };

  const renderChartByParams = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              {
                auth?.role === "supper" || auth?.role === 'administrators' ? 
                  <div className="col-4">
                    <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                      <Select
                        showSearch
                        placeholder="Chọn nhà cái"
                        optionFilterProp="children"
                        onChange={(val) => setBookmakerSl(val)}
                        filterOption={filterOptionBm}
                        options={opBookmaker}
                        allowClear
                      />
                    </Form.Item>
                </div> : null
              }
              <div className="col-4">
                <Form.Item name="typeFilter" label={TEXT_DEF.text0096}>
                  <Select onChange={(val) => setTypeSl(val)} allowClear>
                    <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                    <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                    <Select.Option value="year">{TEXT_DEF.text0091}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="typeChartFilter" label={TEXT_DEF.text0123}>
                  <Select onChange={(val) => setTypeChartSl(val)} allowClear>
                    <Select.Option value="order">{TEXT_DEF.text0125}</Select.Option>
                    <Select.Option value="bet">{TEXT_DEF.text0126}</Select.Option>
                    <Select.Option value="profit">{TEXT_DEF.text0127}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginLeft: '10%', marginTop: 30  }}>
            {dataChart && <Bar options={options} data={dataChart} /> }
          </div>
        </Spin>
      </>
    );
  };

  const renderChartGameLottery = () => {
    return (
      <>
        {
          auth?.role === "supper" || auth?.role === 'administrators' ?
            <div className="col-12">
              <Form
                form={form}
                layout="inline"
                autoComplete="off"
                onFinish={() => onSearch()}
              >
                <div
                  className="col-12"
                  style={{ marginBottom: 15, display: "flex" }}
                >
                  <div className="col-4">
                    <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                      <Select
                        showSearch
                        placeholder="Chọn nhà cái"
                        optionFilterProp="children"
                        onChange={(val) => setBookmakerSl(val)}
                        filterOption={filterOptionBm}
                        options={opBookmaker}
                        allowClear
                      />
                    </Form.Item>
                  </div>
                  {/* <div className="col-4">
                  <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                    <DatePicker
                      placeholder=""
                      onChange={(e) => onChangeDate(e, "from")}
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                      disabledTime={(d) =>
                        !d ||
                        d.isSameOrAfter(
                          dateTo ? moment(dateTo).add(1, "d") : moment()
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                    <DatePicker
                      placeholder=""
                      onChange={(e) => onChangeDate(e, "to")}
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                      disabledTime={(d) =>
                        !d ||
                        d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                        d.isSameOrAfter(moment())
                      }
                    />
                  </Form.Item>
                </div> */}
                </div>
                <div className="payment-search">
                  <Button htmlType="submit" type="primary">
                    {TEXT_DEF.text0026}
                  </Button>
                </div>
              </Form>
            </div>
          : null 
        }
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataGameLottery"
              locale={locale}
              columns={columnsGameLot}
              dataSource={dataGameLottery}
              pagination={true}
              bordered
              style={{ width: "100%" }}
              scroll={{
                x: 1200,
              }}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderChartGameByParams = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              {
                auth?.role === "supper" || auth?.role === 'administrators' ? <div className="col-4">
                  <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                    <Select
                      showSearch
                      placeholder="Chọn nhà cái"
                      optionFilterProp="children"
                      onChange={(val) => setBookmakerSl(val)}
                      filterOption={filterOptionBm}
                      options={opBookmaker}
                      allowClear
                    />
                  </Form.Item>
                </div> : null
              }
              <div className="col-4">
                <Form.Item name="typeFilter" label={TEXT_DEF.text0096}>
                  <Select onChange={(val) => setTypeSl(val)} allowClear>
                    <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                    <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                    <Select.Option value="year">{TEXT_DEF.text0091}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="typeChartFilter" label={TEXT_DEF.text0123}>
                  <Select onChange={(val) => setTypeChartSl(val)} allowClear>
                    {/* <Select.Option value="user">{TEXT_DEF.text0124}</Select.Option> */}
                    <Select.Option value="order">{TEXT_DEF.text0125}</Select.Option>
                    <Select.Option value="bet">{TEXT_DEF.text0126}</Select.Option>
                    <Select.Option value="profit">{TEXT_DEF.text0127}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="gameType" label={`Loại game`}>
                  <Select onChange={(val) => setGameType(val)} allowClear>
                    <Select.Option value="hilo">Hilo</Select.Option>
                    <Select.Option value="poker">Poker</Select.Option>
                    <Select.Option value="keno">Keno</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginLeft: '10%', marginTop: 30  }}>
            {dataGame && <Bar options={options} data={dataGame} /> }
          </div>
        </Spin>
      </>
    );
  };

  const renderChart = (indexTab) => {
    if(indexTab == 1) {
      return renderChartByParams()
    }else if(indexTab == 2) {
      return renderChartGameLottery()
    } else if(indexTab == 3){
      return renderChartGameByParams()
    }
  }

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group value={indexTab} onChange={onChangeTab}>
          <Radio.Button value={1}>{TEXT_DEF.text0136}</Radio.Button>
          <Radio.Button value={2}>{TEXT_DEF.text0137}</Radio.Button>
          <Radio.Button value={3}>{`Tổng hợp mini Game`}</Radio.Button>
        </Radio.Group>
      </div>
      {
        renderChart(indexTab)
        // if(indexTab == 1) {

        // }
        // indexTab == 1 ? renderChartByParams() :
        // renderChartGameLottery()
      }
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
    </div>
  );
};

export default ReportCom;
