import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Radio, Switch } from "antd";
import API, { BOOKMAKER, GAME, GET_CLASSIC } from "../../network/api";
import "moment-timezone";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";
import { formatGameCategory } from "../../components/until";
import { lotteryTitle, lotteryType, classicTitle, transformText } from "../../constant/lotteryTitle";
import "./style.css";

const ManagerGame = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const roleLocal = localStorage.getItem("roles");
    const roleArr = roleLocal?.split(",");

    const [bookmakerSl, setBookmakerSl] = useState();
    const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
    const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
    const [bookmakerList, setBookmakerList] = useState();
    const [gameCategory, setGameCategory] = useState();
    const [gameList, setGameList] = useState();
    const [loading, setLoading] = useState(false);
    const [gameDf, setGameDf] = useState();
    const [recordUp, setRecordUp] = useState();
    const [isReload, setIsReload] = useState(false);
    const [classicGame, setClassicGame] = useState([]);

    useEffect(() => {
        getBookmakerList();
    }, []);

    useEffect(() => {
        getClassicGame();
    }, []);

    useEffect(() => {
        if (bookmakerSl !== undefined) {
            getGameList();
            getClassicGame();
        }
    }, [bookmakerSl, isReload]);

    const getBookmakerList = async () => {
        setLoading(true);
        const responseData = await API.get(`${BOOKMAKER}`);
        const result = responseData.data?.data?.result[0];
        if (result) {
            let data = [];
            result?.map((item) => {
                const newItem = {
                    key: item?.id,
                    name: item?.name,
                };
                data.push(newItem);
            });
            setBookmakerList(data);
            setBookmakerSl(data[0]?.key);
            getGameList();
        }
        setLoading(false);
    };

    const getGameList = async () => {
        setLoading(true);
        const responseData = await API.get(`${GAME}/all/-1`);
        const result = responseData.data?.data?.result;
        if (result) {
            const categoryTracker = {};
            const dataCategory = result?.reduce((result, item) => {
                if (!categoryTracker[item.category]) {
                    categoryTracker[item.category] = true;
                    result.push({ id: item.category, category: item.category, name: formatGameCategory(item.category) });
                }
                return result;
            }, []);
            setGameCategory(dataCategory);
            setGameList(result);
            setGameDf(result);
        }
        setLoading(false);
    };

    const getClassicGame = async () => {
        setLoading(true);
        try {
            const response = await API.get(`${GET_CLASSIC}`);
            const data = response.data?.data;
            const groupedItems = data.reduce((group, item) => {
                const { parrentType } = item;

                group[parrentType] = group[parrentType] ?? [];
                group[parrentType].push(item);

                return group;
            }, {});
            const convertObjToArr = Object.keys(groupedItems)?.map((key) => [key, groupedItems[key]]);

            setClassicGame(convertObjToArr);
            setLoading(false);
        } catch (error) {
            console.log("Error: ", error);
            setLoading(false);
        }
    };

    const checkFlagShow = (game) => {
        const arrBmk = game?.bookmakerIdList?.split(",");
        const isCheck = arrBmk?.indexOf(bookmakerSl.toString()) > -1 ? true : false;
        return isCheck;
    };

    const handleChangeSwitch = (e, game) => {
        setIsShowModalConfirm(true);
        setRecordUp({
            game: game,
            isShow: e,
        });
    };

    const handleUpdate = async () => {
        setLoading(true);
        let newStr = "";
        const str = recordUp?.game?.bookmakerIdList;
        const arrBk = str?.length > 0 ? str?.split(",") : [] || [];
        let bookmakerIdList;
        const indexBk = arrBk?.indexOf(bookmakerSl.toString());

        if (indexBk > -1) {
            if (recordUp?.isShow === true) {
                bookmakerIdList = newStr;
            } else {
                arrBk.splice(indexBk, 1);
                bookmakerIdList = arrBk.join(",");
            }
        } else {
            if (recordUp?.isShow === true) {
                arrBk.push(bookmakerSl.toString());
                bookmakerIdList = arrBk.length > 1 ? arrBk.join(",") : bookmakerSl.toString();
            } else {
                bookmakerIdList = newStr;
            }
        }
        const params = {
            ...recordUp?.game,
            sumBet: Number(recordUp?.game?.sumBet),
            bookmakerIdList: bookmakerIdList,
        };
        const responseData = await API.patch(`${GAME}/${params?.id}`, params);
        if (responseData?.data?.data?.code === 20004) {
            setIsShowModalConfirm(false);
            setIsShowModalSuccess(true);
            setIsReload(!isReload);
        }
    };

    const renderClassicGame = () => {
        return (
            <>
                <div className="col-12">
                    <div className="col-12">
                        <div className="col-12" style={{ fontWeight: 600, textTransform: "uppercase" }}>
                            {TEXT_DEF.text0197}
                        </div>
                        <div className="col-12">
                            {classicGame.map((items) => (
                                <>
                                    <div className="col-11 font-medium">{classicTitle[items[0]]}</div>
                                    <div className="col-11 div-game">
                                        {items[1].map((el) => (
                                            <div className="col-3 div-game-child">
												<div className="col-11 text-game">
													{el?.textView === "XS Miền Bắc" ?  transformText[el?.type] : el?.textView}
												</div>
                                                <div className="col-11 sts-game" style={{ marginTop: 3, display: "flex" }}>
                                                    <div className="col-3">Hiện thị:</div>
                                                    <div className="col-8" style={{ display: "contents" }}>
                                                        <Switch
                                                            checkedChildren="On"
                                                            unCheckedChildren="Off"
                                                            checked={checkFlagShow(el)}
                                                            style={{ width: 60, marginTop: "5px" }}
                                                            onChange={(e) => handleChangeSwitch(e, el)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderGameList = () => {
        return (
            <>
                <div className="col-12">
                    {gameCategory?.map((item, index) => (
                        <div className="col-12" key={`${bookmakerSl}-${item?.id}`}>
                            <div className="col-12" style={{ marginTop: index === 0 ? 0 : 20, fontWeight: 600, textTransform: "uppercase" }}>
                                {item?.name}
                            </div>
                            <div className="col-11 div-game">
                                {gameList?.map(
                                    (game, index) =>
                                        game?.category === item?.category && (
                                            <div key={`${bookmakerSl}-${item?.id}-${game?.id}`} className="col-3 div-game-child">
                                                <div className="col-11 text-game">
                                                    {lotteryTitle[game?.parrentType]}&nbsp;
                                                    {lotteryType[game?.type] ?? game?.type?.toUpperCase()}
                                                </div>
                                                <div className="col-11 sts-game" style={{ marginTop: 3, display: "flex" }}>
                                                    <div className="col-3">Hiện thị:</div>
                                                    <div className="col-8" style={{ display: "contents" }}>
                                                        <Switch
                                                            checkedChildren="On"
                                                            unCheckedChildren="Off"
                                                            defaultChecked={checkFlagShow(game)}
                                                            style={{ width: 60, marginTop: "5px" }}
                                                            onChange={(e) => handleChangeSwitch(e, game)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    return (
        <div className="col-12">
            <div
                className="col-12"
                style={{
                    borderBottom: "1px solid #CCC",
                    paddingBottom: "2rem",
                    marginBottom: "2rem",
                }}
            >
                <Radio.Group value={bookmakerSl} onChange={(e) => setBookmakerSl(e.target.value)}>
                    {bookmakerList?.map((item) => (
                        <Radio.Button value={item?.key}>{item?.name}</Radio.Button>
                    ))}
                </Radio.Group>
            </div>
            {bookmakerSl && gameList ? renderGameList() : null}
            {bookmakerSl ? renderClassicGame() : null}
            {isShowModalConfirm && (
                <ModalCommon isShow={isShowModalConfirm} message={TEXT_DEF.text0071} txtBtnAcc={TEXT_DEF.text0008} textBtnClose={TEXT_DEF.text0020} actionNext={handleUpdate} close={() => setIsShowModalConfirm(false)} />
            )}
            {/* 
            {isShowModalSuccess && (
                <ModalCommon
                    isShow={isShowModalSuccess}
                    message={TEXT_DEF.text0063}
                    txtBtnAcc={TEXT_DEF.text0007}
                    actionNext={() => {
                        setIsShowModalSuccess(false);
                    }}
                    close={() => {
                        setIsShowModalSuccess(false);
                    }}
                />
            )} */}
        </div>
    );
};

export default ManagerGame;
