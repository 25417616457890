import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { WALLET_INOUT, BOOKMAKER, ADMIN_ORDERS } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
// import DetailModal from "./detailModal";

const UserInfo = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [loading, setLoading] = useState(false);
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pagePlayHis, setPagePlayHis] = useState(1);
  const [totalRecordPlayHis, setTotalRecordPlayHis] = useState(0);
  const [dataFilter, setDataFilter] = useState();
  const [dataPlayHis, setDataPlayHis] = useState();
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const [messForm, setMessForm] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataBookmaker = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: item?.id,
              label: item?.name
            }
            dataBookmaker.push(newIt);
          });

          setOpBookmaker(dataBookmaker);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataBookmaker();
  }, [])

  useEffect(() => {
    // const keyword = dataFilter
    //   ? dataFilter
    //   : auth?.role === "admin_bm" ? encodeURIComponent(
    //     JSON.stringify({ bookmakerId: auth?.bookmakerId })) :
    //     "null"
    const params = {
      bookmakerId: dataFilter?.bookmakerId,
      username: dataFilter?.username,
      nickname: dataFilter?.nickname
    }
    const getDataTable = async () => {
      setLoading(true);
      await API.get(`${ADMIN_ORDERS}/user-info?bookmakerId=${params?.bookmakerId}&username=${params?.username}${params?.nickname ? `&nickname=${params?.nickname}` : ""}`)
      .then((response) => {
        const result = response.data?.data?.result;
        if (result) {
          const dataList = [];
          result?.map((item, index) => {
            const newIt = {
              key: `id-${index + 1}`,
              username: item?.username,
              bookmaker: item?.bookmakerName,
              nickname: item?.nickname,
              currentBalance: dollarUS.format(Number(item?.balance)),
              countBet: item?.count,
              totalBet: dollarUS.format(Number(item?.totalBet)),
              totalWin: dollarUS.format(Number(item?.totalPaymentWin)),
              totalLoss: dollarUS.format(Number(item?.totalPaymentWin) * -1),
            }
            dataList.push(newIt);
          });

          setDataPlayHis(dataList);
        }
        setTotalRecordPlayHis(response.data?.data?.result.length);
        setLoading(false);
        setIsSubmitSearch(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
        setIsSubmitSearch(false);
      });
    }

    if (isSubmitSearch) {
      getDataTable();
    }
}, [pagePlayHis, dataFilter])

  const locale = { emptyText: TEXT_DEF.text0043 };

  const dollarUS = Intl.NumberFormat("en-US");

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePagePlayHis = (page) => {
    setPagePlayHis(page);
  };

  const onSearch = () => {
    let params = auth?.role === "admin_bm" || auth?.role === "administrators_bm" ?
       { bookmakerId: auth?.bookmakerId } : 
       { } ;
    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (bookmakerSl) {
      params.bookmakerId = bookmakerSl;
    }
    if (form.getFieldValue("nicknameFilter")) {
      params.nickname = form
      .getFieldValue("nicknameFilter")
      .trim()
    }
      

    if (params?.username && params.bookmakerId) {
      setDataFilter(params);
      setMessForm(undefined)
    } else {
      setDataFilter(null);
      setMessForm("Hãy nhập thông tin tìm kiếm");
      return;
    }
    setIsSubmitSearch(true);
  };

  const playHis = [
    // {
    //   title: "STT",
    //   align: "center",
    //   key: "order",
    //   render: (text, record, index) => (
    //     <span>{(pagePlayHis - 1) * 10 + (index + 1)}</span>
    //   ),
    // },
    {
      title: TEXT_DEF.text0011,
      key: "bookmaker",
      dataIndex: "bookmaker",
      fixed: 'left'

    },
    {
      title: 'Tên HV',
      key: "nickname",
      dataIndex: "nickname",
      fixed: 'left'
    },
    {
      title: 'Tên HV mã hoá',
      key: "username",
      dataIndex: "username",
      fixed: 'left'
    },
    {
      title: TEXT_DEF.text0134,
      key: "currentBalance",
      dataIndex: "currentBalance",
    },
    {
      title: TEXT_DEF.text0133,
      key: "countBet",
      dataIndex: "countBet",
    },
    {
      title: TEXT_DEF.text0093,
      key: "totalBet",
      dataIndex: "totalBet",
    },
    {
      title: TEXT_DEF.text0131,
      key: "totalWin",
      dataIndex: "totalWin",
    },
    {
      title: TEXT_DEF.text0086,
      key: "totalLoss",
      dataIndex: "totalLoss",
    },
  ];

  const renderResult = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 20, display: "flex" }}
            >
              {auth?.role === "supper" || auth?.role === 'administrators' ? <div className="col-4 flex">
                <div className="col-10">
                  <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                    <Select
                      showSearch
                      placeholder="Chọn nhà cái"
                      optionFilterProp="children"
                      onChange={(val) => setBookmakerSl(val)}
                      filterOption={filterOptionBm}
                      options={opBookmaker}
                      allowClear
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
                
                <span style={{ color: "red" }}>*</span>
              </div> : null}
              <div className="col-4 flex">
                <div className="col-10">
                  <Form.Item name="usernameFilter" label={'Tên HV mã hoá'}>
                    <Input />
                  </Form.Item>
                </div>
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-4 flex">
                <div className="col-10">
                  <Form.Item name="nicknameFilter" label={'Tên HV'}>
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {/* <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0027}
              </Button> */}
            </div>
          </Form>
        </div>

        {messForm && <div className="col-12" style={{ color: 'red' }}>{messForm}</div>}
        
        { dataPlayHis &&
          <>
            {/* <div style={{ width: '100%', height: '2px', background: 'gray', margin: '20px 0px' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0134} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0093} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0131} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0132} :</span>
                <span>100000000000</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: 'bolder', marginRight: '10px' }}>{TEXT_DEF.text0133} :</span>
                <span>100000000000</span>
              </div>
            </div>
            <div style={{ width: '100%', height: '2px', background: 'gray', margin: '20px 0px' }}></div> */}
            <Spin spinning={loading} delay={500} size="large">
              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Table
                  key="userList"
                  locale={locale}
                  columns={playHis}
                  dataSource={dataPlayHis}
                  pagination={false}
                  bordered
                  style={{ width: "100%" }}
                  scroll={{ x: 1300 }}

                />
              </div>
              {/* <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 15,
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  current={pagePlayHis}
                  total={totalRecordPlayHis}
                  onChange={onChangePagePlayHis}
                  defaultPageSize={10}
                  showSizeChanger={false}
                  showTotal={showTotal}
                />
              </div> */}
            </Spin>
          </>
        }
      </>
    );
  };

  return (
    <div className="col-12">
      {renderResult()}
    </div>
  );
}

export default UserInfo;