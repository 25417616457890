// Step1.js
import React, { useEffect, useState } from "react";
import {Button, Input, Form, Select, Checkbox} from "antd";
import { TEXT_DEF } from "../../constant/text";
import API, { QA } from "../../network/api";
import { fetchData } from "./action";
import { ConfirmModal } from "./mainModal";

const CreateTopics = ({ handleCancelModal, setIsReloadTopic }) => {
  const [formCreateTopic] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [exist, setExist] = useState(null);
  const [noExist, setNoExist] = useState(null);
  const [listMainTopics, setListMainTopics] = useState([]);
	const [parentId, setParentId] = useState();
	const { TextArea } = Input;


  useEffect(() => {
    if (exist) {
      getAllMainTopics();
    }
  }, [exist]);

  const onCreateNewTopic = async (payload) => {
    setIsSubmitting(true);

    try {
      await API.post(`${QA}/create`, payload).then((response) => {
        const statusCode = response?.data?.data?.code;
        if (statusCode === 20001) {
          setIsReloadTopic(true);
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      console.log("Error: ", error);
    }
  };

  const getAllMainTopics = async () => {
    const listMainTopic = await fetchData(`${QA}/all?level=1`, (item) => item.parent === null);
    setListMainTopics(listMainTopic);
  };

  const handleConfirm = () => {
    onCreateNewTopic(formValues);
    handleCancelModal();
    alert(TEXT_DEF.text0179);
  };

  const handleSubmit = async () => {
    try {
      const values = await formCreateTopic.validateFields();
      let payload;

      const checkAndSetError = async (field, value) => {
        const isExists = await checkExistsTopic({ name: value.name, parentId: value.parentId, checkExist: "true" });
        if (isExists) {
          formCreateTopic.setFields([
            {
              name: field,
              errors: [TEXT_DEF.text0195],
            },
          ]);
          return true;
        }
        return false;
      };

      if (noExist) {
        payload = { name: values?.name };
        if (await checkAndSetError("name", payload)) return;
      } else if (exist) {
        payload = {
          name: values?.parentChild,
          parentId: parentId,
        };
        if (await checkAndSetError("parentChild", payload)) return;
      }

      setFormValues(payload);
      setIsConfirmModalOpen(true);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const checkExistsTopic = async (payload) => {
    try {
      const response = await API.post(`${QA}/create`, payload);
      const isExist = response?.data?.data?.result?.exist;
      if (isExist) {
        return true;
      }
      return false;
    } catch (error) {
      console.log("Error: ", error);
      return false;
    }
  };

  const handleChangeForm = (e, type) => {
    if (type === "noExist") {
      setNoExist(e.target.checked);
    } else {
      setExist(e.target.checked);
    }
  };

  const handleGetParentId = (id) => {
    setParentId(id);
  };

  const handleScroll = (e) => {};

  const renderFormCreateTopic = () => {
    return (
      <>
        {!isConfirmModalOpen ? (
          <Form form={formCreateTopic} autoComplete="off" style={{ padding: "2rem" }} onFinish={handleSubmit}>
            <div className="col-12 flex justify-center items-center flex-col">
              <div className="form-group col-12 flex gap-2">
                <label>{TEXT_DEF.text0183}:</label>
                <Checkbox onChange={(e) => handleChangeForm(e, "noExist")} disabled={exist} />
              </div>

              {noExist ? (
                <>
                  <div className="form-group col-12 flex pt-4">
                    <label htmlFor="name" className="col-3">
                      {TEXT_DEF.text0175} <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Item
                      name="name"
                      style={{ marginRight: 0, width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập thông tin",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </>
              ) : null}

              <div className="form-group col-12">
                <Form.Item value={true} label={TEXT_DEF.text0184}>
                  <Checkbox onChange={(e) => handleChangeForm(e, "Exist")} disabled={noExist} />
                </Form.Item>
              </div>

              {exist ? (
                <>
                  <div className="form-group col-12 flex">
                    <label htmlFor="parentName" className="col-3">
                      {TEXT_DEF.text0175} <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Item name="parentName" style={{ marginRight: 0, width: "100%" }}>
                      <Select
                        placeholder={TEXT_DEF.text0180}
                        onPopupScroll={handleScroll}
                        onChange={handleGetParentId}
                        options={listMainTopics.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group col-12 flex">
                    <label htmlFor="parentChild" className="col-3">
                      {TEXT_DEF.text0176} <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Item
                      name="parentChild"
                      style={{ marginRight: 0, width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập thông tin",
                        },
                      ]}
                    >
                      <TextArea rows={6} />
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </div>

            {noExist || exist ? (
              <div className="col-12 flex justify-center gap-10">
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit" disabled={isSubmitting || !formCreateTopic.isFieldsTouched(true) || !!formCreateTopic.getFieldsError().filter(({ errors }) => errors.length).length}>
                      {TEXT_DEF.text0030}
                    </Button>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="default" onClick={handleCancelModal}>
                    {TEXT_DEF.text0007}
                  </Button>
                </Form.Item>
              </div>
            ) : null}
          </Form>
        ) : (
          <ConfirmModal handleConfirm={handleConfirm} handleCancelModal={handleCancelModal} text={TEXT_DEF.text0189} />
        )}
      </>
    );
  };

  return <>{renderFormCreateTopic()}</>;
};

export default CreateTopics;
