import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { Form, Input, Button, DatePicker, Table, Spin, Pagination, Select } from "antd";
import { TEXT_DEF } from "../../constant/text";
import API, { Lottery_Award, GAME, BOOKMAKER } from "../../network/api";
import { getGame, extractNumbers } from "../../components/until";
import { RIGHTS } from "../../constant/rights";
import {hasRight} from "../../components/until/right";
import moment from "moment";
import "moment-timezone";
import dayjs from "dayjs";
import { disabledTimeTo, disabledTimeFrom, validateDateRange } from "../../utils/dateUtils";

const LotteryAwardList = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [lotteryAwardList, setLotteryAwardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [gameSl, setGameSl] = useState();
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [opBookmaker, setOpBookmaker] = useState();
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [opGame, setOpGame] = useState();
  const [dataFilter, setDataFilter] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [modeSl, setModeSl] = useState();
  const dataMode = [
    {
      key: 0,
      value: false,
      label: "Cho HV thật",
    },
    {
      key: 1,
      value: true,
      label: "Cho HV giả",
    },
  ];

  const orderColums = [
    // {
    //   title: "Nhà cái",
    //   width: 100,
    //   fixed: "left",
    //   dataIndex: "bookmarkerName",
    //   key: "bookmarkerName",
    // },
    {
      title: TEXT_DEF.text0079,
      width: 100,
      fixed: "left",
      dataIndex: "type",
      key: "type",
    },
    {
      title: TEXT_DEF.text0082,
      width: 150,
      fixed: "left",
      dataIndex: "turnIndex",
      key: "turnIndex",
    },
    {
      title: "Giải thưởng",
      width: 250,
      fixed: "left",
      dataIndex: "awardDetail",
      key: "awardDetail",
      render(text, record) {
        return {
          props: {
            style: { "white-space": "pre-line" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Tiền bonus",
      width: 100,
      fixed: "left",
      dataIndex: "bonusPrice",
      key: "bonusPrice",
    },
    {
      title: "Tiền cược",
      width: 150,
      fixed: "left",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
    },
    {
      title: "Tiền thanh toán",
      width: 150,
      fixed: "left",
      dataIndex: "totalPayout",
      key: "totalPayout",
    },
    {
      title: "Lợi nhuận",
      width: 150,
      fixed: "left",
      dataIndex: "totalProfit",
      key: "totalProfit",
    },
  ];

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const onChangepage = (page) => {
    setPage(page);
  };

  const getListLotteryAward = async () => {
    if (!dataFilter) {
      return;
    }

    setLoading(true);
		const keyword = dataFilter;

    const result = await API.get(`${Lottery_Award}?take=10&skip=${page}&order=DESC&keyword=${keyword}`);
    const lotteryAward = _.get(result, "data.data.result[0]", []);
    const totalRecord = _.get(result, "data.data.result[1]", 0);
    const dataList = [];
    for (const item of lotteryAward) {
      const awardDetail = JSON.parse(item.awardDetail);
      const seconds = extractNumbers(item?.type);
      const parrentType = item?.type.substring(0, item?.type.indexOf(seconds.toString()));
      const dataTypegame = getGame(parrentType, seconds);

      dataList.push({
        bookmarkerName: item?.bookmaker?.name,
        turnIndex: item.turnIndex,
        type: dataTypegame,
        totalRevenue: item.totalRevenue,
        totalPayout: dollarUS.format(Number(item.totalPayout)),
        totalProfit: item.totalProfit,
        bonusPrice: dollarUS.format(Number(item.bonusPrice)),
        awardDetail: `
                    Giải đặc biệt: ${awardDetail[0][0]},
                    Giải nhất: ${awardDetail[1][0]},
                    Giải nhì: ${awardDetail[2][0]},
                    Giải ba: ${awardDetail[3][0]} - ${awardDetail[3][1]},
                    Giải tư: ${awardDetail[4][0]} - ${awardDetail[4][1]} - ${awardDetail[4][2]} - ${awardDetail[4][3]}
                    ${awardDetail[4][4]} - ${awardDetail[4][5]} - ${awardDetail[4][6]},
                    Giải năm: ${awardDetail[5][0]},
                    Giải sáu: ${awardDetail[6][0]} - ${awardDetail[6][1]} - ${awardDetail[6][2]},
                    Giải bảy: ${awardDetail[7][0]},
                    Giải tám: ${awardDetail[8][0]},
                `,
      });
    }

    setLoading(false);
    setLotteryAwardList(dataList);
    setTotalRecord(totalRecord);
  };

  useEffect(() => {
    getListLotteryAward();
  }, [page, dataFilter]);

  useEffect(() => {
    const getDataGame = async () => {
      await API.get(`${GAME}/all/0`)
        .then((response) => {
          const result = response.data?.data?.result;
          if (result) {
            const dataGame = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                value: `${item?.parrentType}-${extractNumbers(item?.type)}`,
                label: getGame(item?.parrentType, extractNumbers(item?.type)),
              };
              dataGame.push(newIt);
            });
            setOpGame(dataGame);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    getDataGame();
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataBookmaker = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                value: item?.id,
                label: item?.name,
              };
              dataBookmaker.push(newIt);
            });

            setOpBookmaker(dataBookmaker);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    getDataBookmaker();
	}, []);
	
	const dollarUS = Intl.NumberFormat("en-US");

  const getParams = () => {
    let params = {};
		if (gameSl) {
      const type = gameSl.substring(0, gameSl.indexOf("-"));
      const seconds = gameSl.substring(gameSl.indexOf("-") + 1);
      params.type = `${type}${seconds}s`;
    }

    if (form.getFieldValue("turnIndex")) {
      params.turnIndex = form.getFieldValue("turnIndex").trim();
		}
		
		if (dateFrom) {
      params.startDate = moment(dateFrom).unix();
      if (!dateTo) params.endDate = "2123-12-31";
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01";
      params.endDate = moment(dateTo).unix();
    }

    params.isTestPlayer = modeSl;
    params.bookmarkerId = 1;

    setPage(1);
    if (params.type || params.isTestPlayer !== undefined || params.turnIndex || params.bookmarkerId) {
      setDataFilter(JSON.stringify(params));
    } else {
      setDataFilter(null);
    }
  };

  const onSearch = () => {
    getParams();
  };

  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf("day");
    }
    return current && (current > dayjs().endOf("day") || current < dayjs(dateTo).subtract(3, "months").startOf("day") || current > dayjs(dateTo).endOf("day"));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf("day");
    }
    return current && (current > dayjs().endOf("day") || current < dayjs(dateFrom).startOf("day") || current > dayjs(dateFrom).add(3, "months").endOf("day") || current > dayjs(dateFrom).add(3, "months").endOf("day"));
  };

  const filterOptionBm = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const filterOptionMode = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
	
	const disabledDate = (currentDate) => {
		const threeMonthsAgo = moment().subtract(3, "months");
    return currentDate && (currentDate < threeMonthsAgo || currentDate > moment());
	}

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).format("yyyy-MM-DD HH:mm:ss"));
      else setDateTo(moment(e.$d).format("yyyy-MM-DD HH:mm:ss"));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
	};
	
  const renderTable = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
            {hasRight([RIGHTS.Super])
              ? // <div className="col-12"
                //     style={{ marginBottom: 20, display: "flex" }}
                // >
                //     <div className="col-4">
                //         <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                //             <Select
                //                 showSearch
                //                 placeholder="Chọn nhà cái"
                //                 optionFilterProp="children"
                //                 onChange={(val) => setBookmakerSl(val)}
                //                 filterOption={''}
                //                 options={opBookmaker}
                //                 allowClear
                //             />
                //         </Form.Item>
                //     </div>
                // </div>
                null
              : null}

            <div className="col-12" style={{ marginBottom: 20, display: "flex" }}>
              <div className="col-4">
                <Form.Item name="gameFilter" label={TEXT_DEF.text0080}>
                  <Select showSearch placeholder="Chọn trò chơi" optionFilterProp="children" onChange={(val) => setGameSl(val)} filterOption={filterOptionBm} options={opGame} allowClear />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="modeFilter" label={"Người chơi"}>
                  <Select showSearch placeholder="Chọn dữ liệu" optionFilterProp="children" onChange={(val) => setModeSl(val)} filterOption={filterOptionMode} options={dataMode} allowClear />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="turnIndex" label={"Lượt xổ"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
              <div className="col-4">
                <Form.Item
                  name="dateFrom" label={TEXT_DEF.text0024}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
										},
										{
											validator: () => {
												return validateDateRange(dateFrom, dateTo)
											}
										}
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ format: "HH:mm:ss" }}
                    style={{ width: "100%" }}
                    disabledDate={disabledDateFrom}
                    disabledTime={disabledTimeFrom(dateTo)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item
                  name="dateTo" label={TEXT_DEF.text0025}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    style={{ width: "100%" }}
                    disabledDate={disabledDateTo}
                    disabledTime={disabledTimeTo(dateFrom)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>

          <Spin spinning={loading} delay={500} size="large">
            <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
              <Table
                key="lotteryAwardList"
                locale={locale}
                columns={orderColums}
                dataSource={lotteryAwardList}
                pagination={false}
                bordered
                style={{ width: "100%" }}
                scroll={{
                  x: 1200,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 15,
                justifyContent: "flex-end",
              }}
            >
              <Pagination current={page} total={totalRecord} onChange={onChangepage} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
            </div>
          </Spin>
        </div>
      </>
    );
  };

  return <div className="col-12">{renderTable()}</div>;
};

export default LotteryAwardList;
