import * as _ from "lodash";

import {
    Form,
    Button,
    Spin,
    InputNumber,
    Checkbox,
    Select,
    Input
} from 'antd';
import React, { useState, useEffect, useRef } from "react";
import API, { ADMIN_SETTING } from "../../network/api";
import { ToastContainer, toast } from 'react-toastify';
import { ERROR_MESSAGES } from "../../constant/error";
import { TIME_RESET_BONUS_DEFAULT } from "../../constant/text";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import { TEXT_DEF } from "../../constant/text";


const SettingGameLottery = () => {
    let isRequest = useRef(false);
    const { Option } = Select;
    const [tp, setTp] = useState(0);
    const [settingId, setSettingId] = useState('');
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        if (isRequest.current) {
            return;
        };

        isRequest.current = true;
        const tp = form.getFieldValue("tp");
        const isUseBonus = form.getFieldValue("isUseBonus");
        const isMaxPayout = form.getFieldValue("isMaxPayout");
        const timeResetBonus = form.getFieldValue("timeResetBonus");
        const limitPayOut = form.getFieldValue("limitPayOut");
        const h3 = form.getFieldValue("h3");
        const h6 = form.getFieldValue("h6");
        const h12 = form.getFieldValue("h12");
        let defaultBonus = {
            h3,
            h6,
            h12,
        };
        defaultBonus = JSON.stringify(defaultBonus);

        if (!tp && tp !== 0) return;

        const body = {
            timeResetBonus,
            isMaxPayout,
            isUseBonus,
            profit: tp,
            limitPayOut,
            defaultBonus,
        };

        try {
            setLoading(true);
            if (!settingId) {
                await API.post(`${ADMIN_SETTING}`, body);
            } else {
                await API.patch(`${ADMIN_SETTING}/${settingId}`, body);
            }
            setLoading(false);
            isRequest.current = false;
            toast(ERROR_MESSAGES.UPDATE_SUCCESS);
        } catch (err) {
            setLoading(false);
            isRequest.current = false;
            toast(ERROR_MESSAGES.UPDATE_FAIL);
        }
    }

    useEffect(() => {
        getSetting();
    }, []);

    const getSetting = async () => {
        try {
            setLoading(true);
            const result = await API.get(`${ADMIN_SETTING}`);
            const setting = _.get(result, 'data.data', {});
            setTp(setting?.profit);
            form.setFieldsValue({
                tp: setting?.profit,
            });
            const defaultBonus = setting?.defaultBonus ? JSON.parse(setting?.defaultBonus) : {};
            form.setFieldsValue({
                isUseBonus: setting?.isUseBonus || false,
                isMaxPayout: setting?.isMaxPayout || false,
                timeResetBonus: setting?.timeResetBonus || TIME_RESET_BONUS_DEFAULT,
                limitPayOut: setting?.limitPayOut || 0,
                h3: defaultBonus?.h3 || 0,
                h6: defaultBonus?.h6 || 0,
                h12: defaultBonus?.h12 || 0,
            });
            setSettingId(setting?.id || '');
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const onChangeTp = (value) => {
        setTp(value);
    };

    return (
        <>
            <Spin spinning={loading} delay={500} size="large">
                <div className="col-12">
                    <Form form={form} layout="inline" autoComplete="off" onFinish={() => submit()}>
                        <div className="col-4">
                            <Form.Item name="tp" label={'Phần trăm lợi nhuận (%)'}>
                                <InputNumber
                                    style={{ width: '90%' }}
                                    onChange={onChangeTp}
                                    defaultValue={tp}
                                />
                            </Form.Item>
                            <Form.Item name="isUseBonus" valuePropName="checked" label={'Sử dụng bonus'}>
                                <Checkbox />
                            </Form.Item>

                            <Form.Item name="isMaxPayout" valuePropName="checked" label={'Lấy phần thưởng có payOut cao nhất'}>
                                <Checkbox />
                            </Form.Item>

                            <Form.Item name="timeResetBonus" label="Time reset bonus">
                                <Select>
                                    <Option value="3">3h</Option>
                                    <Option value="6">6h</Option>
                                    <Option value="12">12h</Option>
                                </Select>
                            </Form.Item>
                            <div style={{ marginBottom: 10 }} />
                            <Form.Item name="limitPayOut" label={'Số tiền trả thưởng lớn nhất: '}>
                                <Input
                                    style={{ width: '90%' }}
                                />
                            </Form.Item>
                            <div style={{ marginBottom: 30 }} />
                            <h5>Bonus mặc định theo mốc thời gian</h5>
                            <Form.Item 
                                name="h3"
                                label={'3h: '}
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '90%' }}
                                />
                            </Form.Item>
                            <div style={{ marginBottom: 10 }} />
                            <Form.Item
                                name="h6"
                                label={'6h: '}
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '90%' }}
                                />
                            </Form.Item>
                            <div style={{ marginBottom: 10 }} />
                            <Form.Item
                                name="h12"
                                label={'12h: '}
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '90%' }}
                                />
                            </Form.Item>
                        </div>
                        {
                            hasRight([RIGHTS.EditSettingXSNCommon]) ? (
                                <div className="col-4 payment-search">
                                    <Button htmlType="submit" type="primary">
                                        Lưu
                                    </Button>
                                </div>
                            ) : null
                        }
                    </Form>
                    <ToastContainer />
                </div>
            </Spin>
        </>
    );
};

export default SettingGameLottery;