import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select,
  Space
} from "antd";
import API, { ADMIN_ORDERS, BOOKMAKER, ADMIN_DASHBOARD, ADMIN_POKER, ADMIN_HILO, ADMIN_KENO, GAME } from "../../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../../constant/text";
// import settingIcon from "../../image/setting.png";
import ModalCommon from "../../../components/Modal";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { getGame, getCategory, extractNumbers } from "../../../components/until";
import ModalChart from "../modal"; 
import { OP_CATEGORY, OP_GAME_ORIGINALS } from "../constant";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement);

const ReportByDevice = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [indexTab, setIndexTab] = useState(1);
  localStorage.removeItem("indexTabAcc");
  const [loading, setLoading] = useState(false);
  const [typeSl, setTypeSl] = useState();
  const [typeChartSl, setTypeChartSl] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [dataChart, setDataChart] = useState(undefined);
  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);
  const [dataFillterDf, setDataFilterDf] = useState();
  const [dataGameLottery, setDataGameLottery] = useState()
  const [dataGame, setDataGame] = useState();

  const [opGameCategory, setOpGameCategory] = useState(OP_CATEGORY);
  const [gameCategory, setGameCategory] = useState();
  const [game, setGame] = useState();
  const [opGame, setOpGame] = useState();

  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataList, setDataList] = useState([]);

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "aa",
      },
    },
  };

  const colums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(page - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: "Loại tổng hợp",
      dataIndex: "typeSl",
      key: "typeSl",
    },
    {
      title: "Nền tảng",
      dataIndex: "device",
      key: "device",
    },
    {
      title: "Tổng user",
      dataIndex: "countUser",
      key: "countUser",
    },
    {
      title: "User mới",
      dataIndex: "userNew",
      key: "userNew",
    },
    {
      title: "Đơn cược",
      dataIndex: "countBet",
      key: "countBet",
    },
    {
      title: "Tiền cược",
      dataIndex: "totalBet",
      key: "totalBet",
    },
    {
      title: "Tiền khách thắng",
      dataIndex: "userWin",
      key: "userWin",
    },
    {
      title: "Tiền khuyễn mãi",
      dataIndex: "bonus",
      key: "bonus",
    },
    {
      title: "Lợi nhuận nhà điều hành",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "% thắng",
      dataIndex: "rateWin",
      key: "rateWin",
    }
  ];

  useEffect(() => {
    forceUpdate({});
  }, []);

  const getDataGameLottery = async (type = null) => {
    await API.get(`${GAME}/all`)
      .then((response) => {
        const result = response.data?.data?.result;
        if (result) {
          let dataGame = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: `${item?.parrentType}-${extractNumbers(item?.type)}`,
              label: getGame(item?.parrentType, extractNumbers(item?.type))
            }
            dataGame.push(newIt);
          });
          if (type === "all") {
            dataGame = dataGame.concat(OP_GAME_ORIGINALS)
          }

          setOpGame(dataGame);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const getDataGameOriginals = () => {
    setOpGame(OP_GAME_ORIGINALS);
  }

  useEffect(() => {
    if (gameCategory === "lottery") {
      getDataGameLottery()
    } else if (gameCategory === "casino") {
      getDataGameOriginals()
    } else {
      getDataGameLottery("all")
    }
  }, [gameCategory])

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataBookmaker = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: item?.id,
              label: item?.name
            }
            dataBookmaker.push(newIt);
          });

          setOpBookmaker(dataBookmaker);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataBookmaker();
  }, [])

  const onSearch = () => {
    let params = {};
    if (bookmakerSl && auth?.role === "supper") {
      params.bookmarkerId = bookmakerSl;
    }
    if (auth?.role === "admin_bm" || auth?.role === "administrators_bm"){
      params.bookmarkerId = auth?.bookmakerId
    }
    if (typeSl) {
      params.searchBy = typeSl;
    }
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01";
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
    }
    if (game) {
      params.gameType = game;
    }
    if (gameCategory) {
      params.game = gameCategory;
    }
    console.log(params)
  };

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    if (e.target.value == 2) {
      // getDataLotteryByGame(0)
    }
  };

  const onExportExl = () => {
    console.log("onExportExl")
  }

  const renderSearch = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              {auth?.role === "supper" || auth?.role === 'administrators' ? <div className="col-4">
                <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    onChange={(val) => setBookmakerSl(val)}
                    filterOption={filterOptionBm}
                    options={opBookmaker}
                    allowClear
                  />
                </Form.Item>
              </div> : null}
              <div className="col-4">
                <Form.Item name="gameCategory" label={`Thể loại game`}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(val) => setGameCategory(val)}
                    filterOption={filterOptionBm}
                    options={opGameCategory}
                    allowClear
                  />
                </Form.Item>
              </div>
            </div>
            {gameCategory &&
              <div
                className="col-12"
                style={{ marginBottom: 15, display: "flex" }}
              >
                <div className="col-8">
                  <div className="col-1">Game: </div>
                  <Form.Item name="gameType" label={`Game`} className="col-11">
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setGame(val)}
                      filterOption={filterOptionBm}
                      options={opGame}
                      allowClear
                    />
                  </Form.Item>
                </div>
              </div>
            }
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="typeFilter" label={TEXT_DEF.text0096}>
                  <Select onChange={(val) => setTypeSl(val)} allowClear>
                    <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                    <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                    <Select.Option value="hour">Theo giờ</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              { typeSl === "day" || typeSl === "month" ?
                <>
                  <div className="col-4">
                    <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                      <DatePicker
                        picker={typeSl}
                        placeholder=""
                        onChange={(e) => onChangeDate(e, "from")}
                        format={"DD-MM-YYYY"}
                        
                        style={{ width: "100%" }}
                        disabledTime={(d) =>
                          !d ||
                          d.isSameOrAfter(
                            dateTo ? moment(dateTo).add(1, "d") : moment()
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-4">
                    <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                      <DatePicker
                        picker={typeSl}
                        placeholder=""
                        onChange={(e) => onChangeDate(e, "to")}
                        format={"DD-MM-YYYY"}
                        style={{ width: "100%" }}
                        disabledTime={(d) =>
                          !d ||
                          d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                          d.isSameOrAfter(moment())
                        }
                      />
                    </Form.Item>
                  </div>
                </>
                : null
              }
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button onClick={onExportExl} style={{ marginLeft: 20 }} htmlType="submit" type="primary">
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTableData = () => {
    return (
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={colums}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
              scroll={{
                x: 1200,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={page}
              total={totalRecord}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
      </Spin>
    )
  }

  return (
    <div className="col-12">
      {renderSearch()}
      {renderTableData()}
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
    </div>
  );
};

export default ReportByDevice;
