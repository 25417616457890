import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Spin, InputNumber, Input } from "antd";
import * as _ from "lodash";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";

import { TEXT_DEF, CREATE_SUCCESS, CREATE_FAIL, GET_DATA_FAIL } from "../../constant/text";
import API, { AMIN_BONUS_CONFIG } from "../../network/api";

const BonusConfigCreate = () => {
    let isRequest = useRef(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getBonusConfigById();
        }
    }, []);

    const getBonusConfigById = async () => {
        try {
            setLoading(true);
            const result = await API.get(`${AMIN_BONUS_CONFIG}/${id}`);
            setLoading(false);
            const response = _.get(result, 'data.data', false);
            form.setFieldsValue({
                to: response.to || 0,
                from: response.from || 0,
                percent: response.percent || 0,
                bonusPriceLimit: response.bonusPriceLimit || 0,
            });
        } catch (err) {
            setLoading(false);
            toast(GET_DATA_FAIL);
        }
    }

    const createBonusConfig = async () => {
        if (isRequest.current) {
            return;
        };

        isRequest.current = true;
        const body = {
            to: form.getFieldValue("to"),
            from: form.getFieldValue("from"),
            percent: form.getFieldValue("percent"),
            bonusPriceLimit: form.getFieldValue("bonusPriceLimit"),
        };

        try {
            setLoading(true);
            let result;
            if (!id) {
                result = await API.post(AMIN_BONUS_CONFIG, body);
            } else {
                result = await API.patch(`${AMIN_BONUS_CONFIG}/${id}`, body);
            }
            isRequest.current = false;
            setLoading(false);
            const status = _.get(result, 'data.success', false);
            if (status === true) {
                toast(CREATE_SUCCESS);
                navigate("/bonus-config");
            } else {
                toast(CREATE_FAIL);
            }
        } catch (err) {
            setLoading(false);
            isRequest.current = false;
            toast(CREATE_FAIL);
        }
    }

    return (
        <Spin spinning={loading} delay={500} size="large">
            <Form
                className="form-create"
                form={form}
                layout="inline"
                autoComplete="off"
                onFinish={createBonusConfig}
            >
                <div className="col-8">
                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                            From <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="from"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ marginBottom: 30 }} />

                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                            To <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="to"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ marginBottom: 30 }} />

                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                            Percent <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="percent"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ marginBottom: 30 }} />

                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                        Bonus price limit <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="bonusPriceLimit"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: "Nhập kiểu số",
                                        transform(value) {
                                            if (!value) return;

                                            return Number(value)
                                        },
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item shouldUpdate style={{ width: "100%" }}>
                        {() => (
                            <div className="lg-btn">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form
                                            .getFieldsError()
                                            .filter(({ errors }) => errors.length).length
                                    }
                                >
                                    {!id ? TEXT_DEF.text0018 : TEXT_DEF.text0016}
                                </Button>
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </Spin>
    );
};

export default BonusConfigCreate;