import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import { toast } from 'react-toastify';
import { Form, Input, Button, Select, Checkbox } from "antd";
import React, { useState, useEffect, useRef } from "react";

import { TEXT_DEF, UPDATE_FAIL, GET_DATA_FAIL } from "../../constant/text";
import API, { USER, ROLES, BOOKMAKER } from "../../network/api";
import { hasRight } from "../../components/until/right";
import { RIGHTS } from "../../constant/rights";
const { Option } = Select;

const EditAdminUser = () => {
    let isRequest = useRef(false);
    const navigate = useNavigate();
    const limit = 1000;
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMess, setErrorMess] = useState();
    const [roles, setRoles] = useState([]);
    const [bookmarkers, setBookmarkers] = useState([]);
    const [messageError, setMessageError] = useState('');
    const [firstBookmarker, setFirstBookmarker] = useState("");
    const [selectedRole, setSelectedRole] = useState();

    const backScreen = () => { navigate('/account-manager') }

    useEffect(() => {
        if (!id) return;

        handleRole();
    }, []);

    useEffect(() => {
        getRoles(firstBookmarker);
    }, [firstBookmarker]);

    const handleRole = async () => {
        const bookmakers = await getBookmarkers();
        await getRoles(bookmakers?.[0]?.key);
        await getAdminUserById();
    };

    const onChangeBookmarker = (value) => {
        setFirstBookmarker(value);
    };

    const getAdminUserById = async () => {
        try {
            setLoading(true);
            const result = await API.get(`${USER}/${id}`);
            const user = _.get(result, 'data.data');

            if (!user) {
                setLoading(false);
                return;
            }

            form.setFieldsValue({
                username: user.username || '',
                role: user.roleAdminUser.name || '',
                bookmarker: user.bookmaker.id,
                isAdminSystem: user.isAdminSystem || false,
            });

            setSelectedRole(user?.roleAdminUser?.name || '');
            setFirstBookmarker(user.bookmaker.id);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast(GET_DATA_FAIL);
        }
    };

    const getRoles = async (bookmarkerId) => {
        if (!bookmarkerId) {
            setRoles([]);
            return;
        }

        try {
            setLoading(true);
            const result = await API.get(`${ROLES}/bookmarker/${bookmarkerId}`);
            const data = _.get(result, 'data.data', []);
            setRoles(data);
            initFormValues({
                role: data?.[0]?.name || '',
            });

            const hasRole = data.some(i => i.name === selectedRole);
            if (hasRole) {
                form.setFieldsValue({
                    role: selectedRole,
                });
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast(GET_DATA_FAIL);
        }
    };

    const getBookmarkers = async () => {
        const result = await API.get(`${BOOKMAKER}/all`);
        const bookmarkers = result.data?.data?.result[0] || [];
        if (!bookmarkers || bookmarkers.length === 0) return;

        const tempBookmarkers = bookmarkers?.map((item) => {
            return {
                key: item?.id,
                value: item?.id,
                label: item?.name,
            };
        });
        setBookmarkers(tempBookmarkers);
        initFormValues({
            bookmarker: tempBookmarkers?.[0]?.key,
        });

        return tempBookmarkers;
    };

    const initFormValues = (values) => {
        for (const key in values) {
            form.setFieldsValue({
                [key]: values[key],
            });
        }
    };

    const update = async () => {
        if (isRequest.current) {
            return;
        };
      
        isRequest.current = true;
        const role = roles.find(r => r.name === form.getFieldValue("role"));
        const body = {
            username: form.getFieldValue("username"),
            roleAdminUser: role.id,
            isAdminSystem: form.getFieldValue("isAdminSystem"),
            bookmakerId: form.getFieldValue('bookmarker'),
        };
        try {
            setLoading(true);
            const res = await API.patch(`${USER}/${id}`, body);
            const message = _.get(res, 'data.data.result', '');
            const code = _.get(res, 'data.data.code', 0);
            if (code === 40001) {
              setMessageError(message);
              return;
            } else {
              setMessageError('');
            }
            setLoading(false);
            navigate("/account-manager");
            isRequest.current = false;
        } catch (err) {
            setLoading(false);
            toast(UPDATE_FAIL);
            isRequest.current = false;
        }
    }

    return (
        <div className="w-full flex flex-col">
            <Button
                type="default"
                htmlType="button"
                style={{ marginBottom: 30, width: 80 }}
                onClick={backScreen}
            >
                Quay lại
            </Button>

            <Form
                className="form-create"
                form={form}
                layout="inline"
                autoComplete="off"
                onFinish={update}
                id='form-input'
            >
                <div className="col-8">
                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                            {TEXT_DEF.text0002} <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} onInput={() => setErrorMess()} />
                            </Form.Item>
                        </div>
                    </div>
                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center", color: "red" }}
                    >
                        <div className="col-3"></div>
                        <div className="col-9">{errorMess}</div>
                    </div>
                    <div style={{ marginBottom: 15 }} />

                    {
                        hasRight([RIGHTS.Super]) ? (
                            <div>
                                <div
                                    className="col-12"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <div className="col-3">
                                        {`${TEXT_DEF.text0011}`} <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className="col-9">
                                        <Form.Item
                                            name="bookmarker"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: TEXT_DEF.text0004,
                                                },
                                            ]}
                                            style={{ marginRight: 0 }}
                                        >
                                            <Select onChange={onChangeBookmarker} disabled
                                            >
                                                {bookmarkers.map((item) => (
                                                    <Option key={item.key} value={item.key} >
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 15 }} />
                            </div>
                        ) : null
                    }

                    <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="col-3">
                            Vai trò <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-9">
                            <Form.Item
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: TEXT_DEF.text0004,
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Select
                                >
                                    {roles.map((item) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{ marginBottom: 20 }} />
                    {
                        hasRight([RIGHTS.Super]) ? (
                            <div
                                className="col-12"
                                style={{ display: "flex", alignItems: "center" }}
                                >
                                <div className="col-3">
                                    Is system admin
                                </div>
                                <div className="col-9">
                                    <Form.Item name="isAdminSystem" valuePropName="checked">
                                        <Checkbox>Is system admin</Checkbox>
                                    </Form.Item>
                                    <div className="message-error">{messageError}</div>
                                </div>
                            </div>
                        ) : null
                    }

                    <Form.Item style={{ width: "100%" }}>
                        <div className="lg-btn">
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                {TEXT_DEF.text0030}
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </div>

    );
}

export default EditAdminUser;