import React, { useState, useEffect, useRef } from "react";
import { TEXT_DEF } from "../../constant/text";
import TextArea from "antd/lib/input/TextArea";
import { Button, Input, Table, Switch, Spin, Pagination, Form, Select } from "antd";
import settingIcon from "../../image/setting.png";
import Modal from 'react-bootstrap/Modal';
import ModalCommon from "../../components/Modal";
import API, { GAME_TEXT } from "../../network/api";
import { getBetType, getChildBetType } from "../../components/until";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import { getOpCategoryByGameTxt, getOpBetTypeByGameTxt } from "../../components/until";

const ModalGame = ({
  isShow, onChangeHowToPlay, onChangeTutorial, onChangeIsShow, onChangeIsMaintenance,
  record, txtBtnAcc, textBtnClose, actionNext, close
}) => {
  return (
    <Modal show={isShow} className='modal-all' onHide={close}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="col-12 flex" style={{ flexDirection: 'column' }}>
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0079}</div>
            <div className="col-8">
              <Input readOnly={true} defaultValue={record?.categoryView} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0080}</div>
            <div className="col-8">
              <Input readOnly={true} defaultValue={record?.betTypeView} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0062}</div>
            <div className="col-8">
              <Input readOnly={true} defaultValue={record?.childBetTypeView} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0062}</div>
            <div className="col-8">
              <TextArea onChange={onChangeHowToPlay} rows={6} defaultValue={record?.howToPlay} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0063}</div>
            <div className="col-8">
              <TextArea onChange={onChangeTutorial} rows={10} defaultValue={record?.tutorial} />
            </div>
          </div>
          {/* <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0066}</div>
            <div className="col-8 text-left">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={record?.isShow}
                onChange={onChangeIsShow}
                style={{ width: 60, marginTop: "5px" }}
              />
            </div>
          </div> */}
          {/* <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-8 text-left">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={record?.isMaintenance}
                onChange={onChangeIsMaintenance}
                style={{ width: 60, marginTop: "5px" }}
              />
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {textBtnClose ?
          <>
            <Button type="primary" onClick={actionNext}>
              {txtBtnAcc}
            </Button>
            <Button type="default" onClick={close}>
              {textBtnClose}
            </Button>
          </>
          :
          <Button type="primary" onClick={actionNext}>
            {txtBtnAcc}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

const GameTextSetting = ({ }) => {
  let isRequest = useRef(false);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [howToPlay, setHowToPlay] = useState();
  const [tutorial, setTutorial] = useState();
  const [isShow, setIsShow] = useState();
  const [isMaintenance, setIsMaintenance] = useState();
  const [recordSl, setRecordSl] = useState();

  const [gameList, setGameList] = useState();
  const [isReloadGame, setIsReloadGame] = useState(false);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [params, setParams] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);
  const [categorySl, setCategorySl] = useState();
  const [betTypeSl, setBetTypeSl] = useState();

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    const getdataSystem = async () => {
      try {
        const responseGame = await API.get(`${GAME_TEXT}/all?${params}`);
        const resultGame = responseGame.data?.data?.result?.data;
        const totalRecords = responseGame.data?.data?.result?.total;
        setTotalRecord(totalRecords)
        if (resultGame) {
          const dataGame = [];
          resultGame?.map((item) => {
            const game = {
              id: item?.id,
              category: item?.category,
              categoryView: item?.category === "XoSo" ? `Xổ số ${item.region ? 'truyền thống' : ''}` : "Orignals",
              betType: item?.betType,
              betTypeView: getBetType(item?.betType),
              childBetType: item?.childBetType,
              childBetTypeView: getChildBetType(item?.childBetType),
              isShow: true,
              howToPlay: item?.howToPlay,
              tutorial: item?.tutorial,
              isMaintenance: false,
              type: item.region ? `${item.region}` : '',
            };
            dataGame.push(game);
          });
          setGameList(dataGame);
        }
      } catch (err) {
        console.log(err);
      }
    }

    getdataSystem();
  }, [isReloadGame, params]);

  const getParams = (action = '', data = {}) => {
    let params = `page=${page}`;
    if (action === 'search') {
      setPage(1);
      params = `page=1`;
    } else if (action === 'change_page') {
      params = `page=${(data?.page || 1)}&limit=${data?.size || pageSize}`;
      setPage(data?.page || 1);
    }
    if (categorySl) {
      params += `&category=${categorySl}`;
    }
    if (betTypeSl) {
      params += `&betType=${betTypeSl}`;
    }
    setParams(params);

    return params;
  }
  useEffect(() => {
    console.log(categorySl)
  }, [categorySl])

  const onChangePage = (page, size) => {
    getParams('change_page', { page, size });
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const search = () => {
    getParams('search');
  };

  const onCreateData = () => {
    setIsShowModal(true)
  }

  const onClearModal = () => {
    setIsShowModal(false);
    setTutorial(undefined);
    setRecordSl(undefined);
  }

  const requestData = async (type) => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    if (type === "create") {
      onClearModal();
    } else if (type === "edit") {
      const parmas = {
        category: recordSl?.category,
        betType: recordSl?.betType,
        childBetType: recordSl?.childBetType,
        howToPlay: howToPlay ? howToPlay.toString() : recordSl?.howToPlay,
        tutorial: tutorial ? tutorial.toString() : recordSl?.tutorial,
        maxReward: recordSl?.maxReward
      }
      try {
        await API.patch(`${GAME_TEXT}/${recordSl?.id}`, parmas)
          .then((response) => {
            const code = response.data?.data?.code;
            isRequest.current = false;
            if (code === 20004) {
              setIsReloadGame(!isReloadGame);
              onClearModal();
            }
          })
      } catch (error) {
        isRequest.current = false;
        console.log(error)
      }
    } else {
      setIsShowModalConfirm(false);
      setRecordSl(undefined);
    }
  }

  const columns = [
    {
      title: TEXT_DEF.text0079,
      dataIndex: "categoryView",
      key: "categoryView",
      width: '7%',
    },
    {
      title: TEXT_DEF.text0080,
      dataIndex: "betTypeView",
      key: "betTypeView",
      width: '7%',
    },
    {
      title: 'Vùng miền',
      dataIndex: "type",
      key: "type",
      width: '7%',
    },
    {
      title: TEXT_DEF.text0062,
      dataIndex: "childBetTypeView",
      key: "childBetTypeView",
      width: '7%',
    },
    {
      title: TEXT_DEF.text0122,
      dataIndex: "howToPlay",
      key: "howToPlay",
      // width: '50%',
    },
    {
      title: TEXT_DEF.text0063,
      dataIndex: "tutorial",
      key: "tutorial",
      // width: '50%',
    },
    // {
    //   title: TEXT_DEF.text0066,
    //   key: "isShow",
    //   width: '5%',
    //   render: (record) => (
    //     record?.isShow ? "Bật" : "Tắt"
    //   )
    // },
    // {
    //   title: TEXT_DEF.text0065,
    //   key: "isMaintenance",
    //   width: '5%',
    //   render: (record) => (
    //     record?.isMaintenance ? "Bật" : "Tắt"
    //   )
    // },
    {
      title: TEXT_DEF.text0016,
      key: "edit",
      align: "center",
      width: '5%',
      render: (record) =>
        <div>
          {
            hasRight([RIGHTS.EditSettingGameText]) ? (
              <div className="flex justify-center">
                <img
                  style={{
                    height: 20,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  src={settingIcon}
                  alt="setting"
                  onClick={() => {
                    setRecordSl(record);
                    onCreateData();
                  }}
                />
              </div>
            ) : null
          }
        </div>
    },
    // {
    //   title: "Xóa",
    //   key: "detele",
    //   width: '10%',
    //   render: (record) =>
    //     auth?.role === "supper" ||
    //       (auth?.role === "admin" && roleArr?.indexOf("user_edit") > -1) ? (
    //         <div className="flex justify-center">
    //           <img
    //             style={{
    //               height: 20,
    //               cursor: "pointer",
    //               display: "flex",
    //               justifyContent: "center",
    //             }}
    //             src={deteleIcon}
    //             alt="delete"
    //             onClick={() => {
    //               setRecordSl(record);
    //               setIsShowModalConfirm(true);
    //             }}
    //           />
    //         </div>
    //     ) : null,
    // },
  ]

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className="col-12">
      {/* {auth?.role === "supper" ||
        (auth?.role === "admin" &&
          roleArr.some((el) => el === "system_config_edit")) ? (
          <div
            className="col-12 mt-10"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => onCreateData()}
            >
              {TEXT_DEF.text0068}
            </Button>
          </div>
        ) : null} */}
      <div className="col-12">
        <Form form={form} layout="inline" autoComplete="off" onFinish={() => search()}>
          <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
            <div className="col-4">
              <Form.Item name="category" label={'Thể loại'}>
                <Select
                  showSearch
                  placeholder="Chọn thể loại"
                  optionFilterProp="children"
                  onChange={(val) => setCategorySl(val)}
                  filterOption={filterOptionBm}
                  options={getOpCategoryByGameTxt()}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item name="betType" label={'Trò chơi'}>
              <Select
                  showSearch
                  placeholder="Chọn trò chơi"
                  optionFilterProp="children"
                  onChange={(val) => setBetTypeSl(val)}
                  filterOption={filterOptionBm}
                  options={getOpBetTypeByGameTxt(categorySl)}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
          <div className="payment-search">
            <Button htmlType="submit" type="primary">
              {TEXT_DEF.text0026}
            </Button>
          </div>
        </Form>
      </div>
      <Spin spinning={loading} delay={500} size="large" style={{ width: '100%' }}>
        <div
          className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <Table
            key="userList"
            locale={locale}
            columns={columns}
            dataSource={gameList}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 15,
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            showTotal={showTotal}
            showSizeChanger={true}
            pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
          />
        </div>
      </Spin>
      
      {isShowModal && (
        <ModalGame
          isShow={isShowModal}
          onChangeHowToPlay={(e) => setHowToPlay(e?.target.value)}
          onChangeTutorial={(e) => setTutorial(e?.target.value)}
          onChangeIsShow={(e) => setIsShow(e)}
          onChangeIsMaintenance={(e) => setIsMaintenance(e)}
          record={recordSl}
          txtBtnAcc={TEXT_DEF.text0030}
          textBtnClose={TEXT_DEF.text0007}
          actionNext={() => requestData(recordSl ? "edit" : "create")}
          close={onClearModal}
        />
      )}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0069}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0007}
          actionNext={() => requestData("delete")}
          close={() => { setIsShowModalConfirm(false); setRecordSl(undefined) }}
        />
      )}
    </div>
  );

}

export default GameTextSetting;