import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { ADMIN_ORDERS, BOOKMAKER, GAME } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import { getGame, getCategory, extractNumbers } from "../../components/until";

const WinLossHisList = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [orderList, setOrderList] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [page, setPage] = useState(1);
  const [totalRecordUser, setTotalRecordUser] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [opGame, setOpGame] = useState();
  const [gameSl, setGameSl] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataGame = async () => {
      await API.get(`${GAME}/all`)
      .then((response) => {
        const result = response.data?.data?.result;
        if (result) {
          const dataGame = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: `${item?.parrentType}-${extractNumbers(item?.type)}` ,
              label: getGame(item?.parrentType, extractNumbers(item?.type))
            }
            dataGame.push(newIt);
          });
          setOpGame(dataGame);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataGame();
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          const dataBookmaker = [];
          result?.map((item) => {
            const newIt = {
              key: item?.id,
              value: item?.id,
              label: item?.name
            }
            dataBookmaker.push(newIt);
          });

          setOpBookmaker(dataBookmaker);
        }
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    getDataBookmaker();
  }, [])

  useEffect(() => {
    getListDataUser();
  }, [page, dataFilter, indexTab]);

  const getListDataUser = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? 
        encodeURIComponent(JSON.stringify({ bookmakerId: auth?.bookmakerId, status: "closed" })) : 
        encodeURIComponent(JSON.stringify({ status: "closed" }));

    await API.get(`${ADMIN_ORDERS}/all?take=10&skip=${page}&order=DESC&keyword=${keyword}`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          let dataList = [];
          result?.map(item => {
            const itCv = {
              key: item?.id,
              bookmaker: item?.user?.bookmaker?.name,
              username: item?.user?.username,
              nickname: item?.user?.userInfo?.nickname,

              userId: item?.user?.id,
              category: getCategory(item?.type),
              game: getGame(item?.type, item?.seconds),
              howToPlay: getHowToPlay(item?.betTypeName, item?.childBetTypeName),
              indexNum: item?.numericalOrder,
              detail: item?.detail,
              status: item?.status === "closed" ? "Đã đóng" : item?.status === "canceled" ? "Đã hủy" : "Đang mở",
              turnIndex: item?.turnIndex,
              createdAt: item?.createdAt,
              revenue: dollarUS.format((Number(item?.revenue))),
              winOrLoss: dollarUS.format(Number(item?.paymentWin)),
              userProfit: dollarUS.format(getUserProfit(item)),
              bookmakerProfit: dollarUS.format(getBookmakerProfit(item?.paymentWin)),
              result: item?.winningNumber?.length > 0 ? convertResult(item?.winningNumber[0]?.winningNumbers) : ""
            };
            dataList.push(itCv);
          })
          setOrderList(dataList);
          setTotalRecordUser(response.data?.data?.result[1]);
          setLoading(false);
        }
      }) 
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const convertResult = (text) => {
    return text.replaceAll(`"`, "").replace("[", "").replace("]", "").replaceAll(",", ", ");
  }

  const getUserProfit = (item) => {
    let result;
    if (item?.status === "closed") {
      result = Number(item?.revenue) + Number(item?.paymentWin);
    } else if (item?.status === "canceled") {
      result = item?.revenue;
    } else {
      result = 0;
    }
    return result;
  }

  const getBookmakerProfit = (paymentWin) => {
    return Number(paymentWin) === 0 ? 0 : Number(paymentWin) * -1
  }

  const getHowToPlay = (betType, chilBetType) => {
    return `${betType} - ${chilBetType}`
  }

  const onChangepage = (page) => {
    setPage(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const onSearch = () => {
    let params = auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? { bookmakerId: auth?.bookmakerId, status: "closed" } : { status: "closed" };
    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (form.getFieldValue("nicknameFilter")) {
      params.nickname = form
      .getFieldValue("nicknameFilter")
      .trim()
    }
      
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01";
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
    }
    if (bookmakerSl) {
      params.bookmakerId = bookmakerSl;
    }
    if (gameSl) {
      const type = gameSl.substring(0, gameSl.indexOf("-"));
      const seconds = gameSl.substring(gameSl.indexOf("-") + 1);
      params.type = type;
      params.seconds = seconds;
    }
    if (form.getFieldValue("numericalFilter")) {
      params.numericalOrder = form.getFieldValue("numericalFilter").trim();
    }

    setPage(1);

    if (params?.username || params?.startDate || params?.endDate || params.bookmakerId || params.type || params.numericalOrder
      || params.nickname) {
      setDataFilter(JSON.stringify(params));
    } else {
      setDataFilter(null);
    }
  };

  const orderColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(page - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0011,
      width: 150,
      fixed: "left",
      dataIndex: "bookmaker",
      key: "bookmaker",
    },
    {
      title: 'Tên HV',
      width: 150,
      fixed: "left",
      dataIndex: "nickname",
      key: "nickname",
    },
    {
      title: 'Tên HV mã hoá',
      width: 150,
      fixed: "left",
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0079,
      width: 150,
      dataIndex: "category",
      key: "category",
    },
    {
      title: TEXT_DEF.text0080,
      width: 150,
      dataIndex: "game",
      key: "game",
    },
    {
      title: TEXT_DEF.text0081,
      width: 150,
      dataIndex: "howToPlay",
      key: "howToPlay",
    },
    {
      title: TEXT_DEF.text0082,
      width: 150,
      dataIndex: "turnIndex",
      key: "turnIndex",
    },
    {
      title: TEXT_DEF.text0083,
      width: 150,
      dataIndex: "indexNum",
      key: "indexNum",
    },
    {
      title: TEXT_DEF.text0078,
      width: 250,
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: TEXT_DEF.text0102,
      width: 200,
      dataIndex: "result",
      key: "result",
    },
    {
      title: TEXT_DEF.text0075,
      width: 150,
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: TEXT_DEF.text0077,
      width: 150,
      dataIndex: "winOrLoss",
      key: "winOrLoss",
    },
    {
      title: TEXT_DEF.text0076,
      width: 150,
      dataIndex: "userProfit",
      key: "userProfit",
    },
    {
      title: TEXT_DEF.text0086,
      width: 150,
      dataIndex: "bookmakerProfit",
      key: "bookmakerProfit",
    },
    {
      title: TEXT_DEF.text0088,
      width: 150,
      dataIndex: "status",
      key: "status",
    },
    {
      title: TEXT_DEF.text0087,
      width: 150,
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    // {
    //   title: TEXT_DEF.setting_user,
    //   key: "setting",
    //   align: "center",
    //   render: (record) =>
    //     auth?.role === "supper" ||
    //       (auth.role === "admin" && roleArr?.indexOf("user_edit_info")) > -1 ? (
    //       <div className="flex w-full items-center justify-center">
    //         <img
    //           style={{ height: 20, cursor: "pointer" }}
    //           src={settingIcon}
    //           alt="setting"
    //           onClick={() => {
    //             localStorage.setItem("indexLocalEdit", "2");
    //             navigate(`/keyUser/${record.key}`);
    //           }}
    //         />
    //       </div>
    //     ) : null,
    // },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleDownloadExcel = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? 
        encodeURIComponent(JSON.stringify({ bookmakerId: auth?.bookmakerId, status: "closed" })) : 
        encodeURIComponent(JSON.stringify({ status: "closed" }));

    await API.get(`${ADMIN_ORDERS}/all?take=1000000&skip=1&order=DESC&keyword=${keyword}`)
      .then((response) => {
        const result = response.data?.data?.result[0];
        if (result) {
          let dataList = [];
          result?.map(item => {
            const itCv = {
              bookmaker: item?.user?.bookmaker?.name,
              username: item?.user?.username,
              nickname: item?.user?.userInfo?.nickname,

              category: getCategory(item?.type),
              game: getGame(item?.type, item?.seconds),
              howToPlay: getHowToPlay(item?.betTypeName, item?.childBetTypeName),
              turnIndex: item?.turnIndex,
              indexNum: item?.numericalOrder,
              detail: item?.detail,
              result: item?.winningNumber?.length > 0 ? convertResult(item?.winningNumber[0]?.winningNumbers) : "",
              revenue: dollarUS.format((Number(item?.revenue))),
              winOrLoss: dollarUS.format(Number(item?.paymentWin)),
              userProfit: dollarUS.format(getUserProfit(item)),
              bookmakerProfit: dollarUS.format(getBookmakerProfit(item?.paymentWin)),
              status: item?.status === "closed" ? "Đã đóng" : item?.status === "canceled" ? "Đã hủy" : "Đang mở",
              createdAt: moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
            };
            dataList.push(itCv);
            console.log(itCv)
          })
          // console.log(dataList)
          const header = [
            TEXT_DEF.text0011, 
            TEXT_DEF.text0002, 
            TEXT_DEF.text0079, 
            TEXT_DEF.text0080, 
            TEXT_DEF.text0081, 
            TEXT_DEF.text0082,
            TEXT_DEF.text0083,
            TEXT_DEF.text0078,
            TEXT_DEF.text0102,
            TEXT_DEF.text0075,
            TEXT_DEF.text0077,
            TEXT_DEF.text0076,
            TEXT_DEF.text0086,
            TEXT_DEF.text0088,
            TEXT_DEF.text0087,
          ];
          downloadExcel({
            fileName: "Lịch sử thẳng/thua",
            sheet: "Sheet 1",
            tablePayload: {
              header: header,
              body: dataList,
            },
          })
        }
        setLoading(false);
      }) 
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const renderAccUser = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 20, display: "flex" }}
            >
              {auth?.role === "supper" && <div className="col-4">
                <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    onChange={(val) => setBookmakerSl(val)}
                    filterOption={filterOptionBm}
                    options={opBookmaker}
                    allowClear
                  />
                </Form.Item>
              </div>}
              <div className="col-4">
                <Form.Item name="usernameFilter" label={'Tên HV mã hoá'}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="gameFilter" label={TEXT_DEF.text0080}>
                    <Select
                      showSearch
                      placeholder="Chọn trò chơi"
                      optionFilterProp="children"
                      onChange={(val) => setGameSl(val)}
                      filterOption={filterOptionBm}
                      options={opGame}
                      allowClear
                    />
                  </Form.Item>
              </div>                  
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="numericalFilter" label={TEXT_DEF.text0083}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
            < div className="col-4">
                <Form.Item name="nicknameFilter" label={'Tên HV'}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="orderList"
              locale={locale}
              columns={orderColums}
              dataSource={orderList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
              scroll={{
                x: 1200,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={page}
              total={totalRecordUser}
              onChange={onChangepage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderAccUser()}
      {/* {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_delete_user}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )} */}
    </div>
  );
};

export default WinLossHisList;
