import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select
} from "antd";
import API, { BOOKMAKER, ADMIN_HILO, ADMIN_POKER, ADMIN_KENO } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";
import {
  LockFilled,
  UnlockFilled
} from "@ant-design/icons";
import { getTxtRewardPoker, getTxtRewardHilo } from "../../components/until";
import { playerTypes } from '../../constant/playerTypes';
import {typeBet} from "../../constant/typeBet";

const PlayHisOriginals = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [pokerHis, setPokerHis] = useState([]);
  const [hiloHis, setHiloHis] = useState([]);
	const [kenoHis, setKenoHis] = useState([]);
  const [indexTab, setIndexTab] = useState(1);
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pagePoker, setPagePoker] = useState(1);
  const [totalRecordPoker, setTotalRecordPoker] = useState(0);
  const [pageHilo, setPageHilo] = useState(1);
	const [pageKeno, setPageKeno] = useState(1);
  const [totalRecordHilo, setTotalRecordHilo] = useState(0);
	const [totalRecordKeno, setTotalRecordKeno] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [idField, setIdField] = useState();
  const [dataBlock, setDataBlock] = useState();
  const [txtMsg, setTxtMsg] = useState();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
	const [isTestPlayer, setIsTestPlayer] = useState('');
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
	const levelMapping = {
		
	}

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataBookmaker = async () => {
      await API.get(`${BOOKMAKER}/all`)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataBookmaker = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                value: item?.id,
                label: item?.name
              }
              dataBookmaker.push(newIt);
            });

            setOpBookmaker(dataBookmaker);
          }
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    getDataBookmaker();
  }, [])

  useEffect(() => {
    if (opBookmaker) getListData();
  }, [pagePoker, pageHilo, dataFilter, indexTab, opBookmaker]);

  const getListData = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : encodeURIComponent(
        JSON.stringify(
          auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? { bookmakerId: auth?.bookmakerId } : null
        )
      );
    const URL_1 = `${ADMIN_POKER}/all?take=10&skip=${pagePoker}&order=DESC&keyword=${keyword}`;
    const URL_2 = `${ADMIN_HILO}/all?take=10&skip=${pageHilo}&order=DESC&keyword=${keyword}`;
    const URL_3 = `${ADMIN_KENO}/all?take=10&skip=${pageKeno}&order=DESC&keyword=${keyword}`;

    if (indexTab == 1) {
      await API.get(URL_1)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataHis = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                bookmakerName: item?.bookmakerName,
                username: item?.username,
                code: item?.code,
                play1st: item?.play1st,
                play2nd: item?.play2nd,
                revenue: dollarUS.format(Number(item?.revenue)),
                paymentWin: dollarUS.format(Number(item?.paymentWin) - Number(item?.revenue)),
                rewardTitle: getTxtRewardPoker(item?.rewardTitle),
                isGameOver: item?.isGameOver == true ? 'Kết thúc' : 'Đang mở',
                createdAt: item?.createdAt,
                nickname: item?.nickname,
                updatedAt: item?.updatedAt,
                updatedBy: item?.updatedBy == 'system' ? 'Có' : 'Không',
                isTestPlayer: item?.isUserFake == 1 ? 'Chơi thử' : 'Chơi thật',
              };
              dataHis.push(newIt);
            });
            setPokerHis(dataHis);
            setTotalRecordPoker(response.data?.data?.result[1]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (indexTab == 2) {
      await API.get(URL_2)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            const dataHis = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                bookmakerName: item?.bookmakerName,
                username: item?.username,
                code: item?.code,
                cards: item?.cards,
                hiLo: getTxtRewardHilo(item?.hiLo),
                multiHis: item?.multiHis,
                revenue: dollarUS.format(Number(item?.revenue)),
                totalPaymentWin: dollarUS.format(Number(item?.totalPaymentWin) - Number(item?.revenue)),
                isGameOver: item?.isGameOver == true ? 'Kết thúc' : 'Đang mở',
                createdAt: item?.createdAt,
                updatedAt: item?.updatedAt,
                nickname: item?.nickname,
                updatedBy: item?.updatedBy == 'system' ? 'Có' : 'Không',
                isTestPlayer: item?.isUserFake == 1 ? 'Chơi thử' : 'Chơi thật',
              };
              dataHis.push(newIt);
            });
            setHiloHis(dataHis);
            setTotalRecordHilo(response.data?.data?.result[1]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (indexTab === 3) {
			await API.get(URL_3)
        .then((response) => {
					const result = response.data?.data?.result[0];
          if (result) {
            const dataHis = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                bookmakerName: item?.bookmakerName,
                username: item?.username,
                code: item?.code,
                kenoHitBet: item?.kenoHitBet,
                kenoHitResult: item?.kenoHitResult,
                multi: item?.multi,
                typeBet: typeBet[item?.typeBet],
                revenue: dollarUS.format(Number(item?.revenue)),
                totalPaymentWin: dollarUS.format(Number(item?.totalPaymentWin) - Number(item?.revenue)),
                isGameOver: item?.isGameOver === true ? "Kết thúc" : "Đang mở",
                createdAt: item?.createdAt,
                nickname: item?.nickname,
                updatedBy: item?.updatedBy === "system" ? "Có" : "Không",
                isTestPlayer: item?.isUserFake === 1 ? "Chơi thử" : "Chơi thật",
              };
              dataHis.push(newIt);
            });
            setKenoHis(dataHis);
            setTotalRecordKeno(response.data?.data?.result[1]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
		}
  };

  const onChangePagePoker = (page) => {
    setPagePoker(page);
  };

  const onChangePageHilo = (page) => {
    setPageHilo(page);
  };

	const onChangePageKeno = (page) => {
		setPageKeno(page);
	}

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const onSearch = () => {
    let params = auth?.role === 'admin_bm' || auth?.role === "administrators_bm" ? { bookmakerId: auth?.bookmakerId } : {};
    if (form.getFieldValue('usernameFilter')) params.username = form.getFieldValue('usernameFilter').trim().toLowerCase();
    if (dateFrom) {
      params.startDate = moment(dateFrom).format('YYYY-MM-DD');
      if (!dateTo) params.endDate = '2125-12-31';
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = '2023-01-01';
      params.endDate = moment(dateTo).format('YYYY-MM-DD');
    }
    if (bookmakerSl) {
      params.bookmakerId = bookmakerSl;
    }
    if (form.getFieldValue('codeFilter')) {
      params.code = form.getFieldValue('codeFilter').trim();
    }

    if (form.getFieldValue('nicknameFilter')) {
      params.nickname = form.getFieldValue('nicknameFilter').trim();
    }

		if (form.getFieldValue("isTestPlayer")) {
      params.isTestPlayer = form.getFieldValue("isTestPlayer");
    } 

    if (indexTab === 1) {
      setPagePoker(1);
    } else {
      setPageHilo(1);
    }

    if (params?.nickname || params?.username || params?.startDate || params?.endDate || params?.bookmakerId || params?.code || params?.isTestPlayer) {
      setDataFilter(JSON.stringify(params));
    } else {
      setDataFilter(null);
    }
  };

  const pokerColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pagePoker - 1) * 10 + (index + 1)}</span>
      ),
      fixed: 'left',
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmakerName",
      key: "bookmakerName",
      fixed: 'left',
    },
    {
      title: 'Tên HV',
      dataIndex: "nickname",
      key: "nickname",
      fixed: 'left',
    },
    {
      title: 'Tên HV mã hóa',
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Mã vé cược",
      dataIndex: "code",
      key: "code",
    },
    {
      title: TEXT_DEF.text0141,
      dataIndex: "play1st",
      key: "play1st",
    },
    {
      title: TEXT_DEF.text0142,
      dataIndex: "play2nd",
      key: "play2nd",
    },
    {
      title: TEXT_DEF.text0126,
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: TEXT_DEF.text0077,
      dataIndex: "paymentWin",
      key: "paymentWin",
    },
    {
      title: "Giải trúng",
      dataIndex: "rewardTitle",
      key: "rewardTitle",
    },
    {
      title: TEXT_DEF.text0088,
      dataIndex: "isGameOver",
      key: "isGameOver",
    },
    {
      title: TEXT_DEF.text0148,
      dataIndex: "isTestPlayer",
      key: "isTestPlayer",
    },
    {
      title: 'Thời gian bắt đầu',
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Thời gian kết thúc',
      key: "updatedAt",
      render: (record) =>
        moment(record?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Tự động cập nhật kết quả',
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
  ];

  const hiloColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => <span>{(pageHilo - 1) * 10 + (index + 1)}</span>,
      fixed: "left",
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmakerName",
      key: "bookmakerName",
      fixed: "left",
    },
    {
      title: "Tên HV",
      dataIndex: "nickname",
      key: "nickname",
      fixed: "left",
    },
    {
      title: "Tên HV mã hóa",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Mã vé cược",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Chi tiết lá bài",
      dataIndex: "cards",
      key: "cards",
    },
    {
      title: "Dự đoán",
      dataIndex: "hiLo",
      key: "hiLo",
    },
    {
      title: "Tỉ lệ nhân",
      dataIndex: "multiHis",
      key: "multiHis",
    },
    {
      title: TEXT_DEF.text0126,
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: TEXT_DEF.text0077,
      dataIndex: "totalPaymentWin",
      key: "totalPaymentWin",
    },
    {
      title: TEXT_DEF.text0088,
      dataIndex: "isGameOver",
      key: "isGameOver",
    },
    {
      title: TEXT_DEF.text0148,
      dataIndex: "isTestPlayer",
      key: "isTestPlayer",
    },
    {
      title: "Thời gian bắt đầu",
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Thời gian kết thúc',
      key: "updatedAt",
      render: (record) =>
        moment(record?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Tự động cập nhật kết quả',
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
  ];

	const columnsKeno = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => <span>{(pageHilo - 1) * 10 + (index + 1)}</span>,
      fixed: "left",
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmakerName",
      key: "bookmakerName",
      fixed: "left",
    },
    {
      title: "Tên HV",
      dataIndex: "nickname",
      key: "nickname",
      fixed: "left",
    },
    {
      title: "Tên HV mã hóa",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Mã vé cược",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Dãy số đặt",
      dataIndex: "kenoHitBet",
      key: "kenoHitBet",
    },
    {
      title: "Kết quả",
      dataIndex: "kenoHitResult",
      key: "kenoHitResult",
    },
    {
      title: "Tỉ lệ nhân",
      dataIndex: "multi",
      key: "multi",
    },
    {
      title: "Cấp độ",
      dataIndex: "typeBet",
      key: "typeBet",
    },
    {
      title: TEXT_DEF.text0126,
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: TEXT_DEF.text0077,
      dataIndex: "totalPaymentWin",
      key: "totalPaymentWin",
    },
    {
      title: TEXT_DEF.text0088,
      dataIndex: "isGameOver",
      key: "isGameOver",
    },
    {
      title: TEXT_DEF.text0148,
      dataIndex: "isTestPlayer",
      key: "isTestPlayer",
    },
    {
      title: "Thời gian chơi",
      key: "createdAt",
      render: (record) => moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Tự động cập nhật kết quả",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const addNewAdmin = () => {
    localStorage.setItem("indexLocalCreate", indexTab);
    navigate("/createAccount");
  };

  const onChangeTab = (e) => {
    form.setFieldValue("bookmakerFilter", null);
    form.setFieldValue("usernameFilter", null);
    form.setFieldValue("dateFrom", null);
    form.setFieldValue("dateTo", null);
    form.setFieldValue("codeFilter", null);
    form.setFieldValue("nicknameFilter", null);
    form.setFieldValue("isTestPlayer", null);
    setDateFrom(null);
    setDateTo(null);
    setDataFilter(undefined);
    setPagePoker(1);
    setPageHilo(1);
		setPageKeno(1);
    setIsTestPlayer(undefined);
    // setHiloHis([]);
    // setPokerHis([])
    setIndexTab(e.target.value);
  };

  const handleDownloadExcel = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : encodeURIComponent(
        JSON.stringify(
          auth?.role === "admin_bm" || auth?.role === "administrators_bm" ? { bookmakerId: auth?.bookmakerId } : null
        )
      );
    const URL_1 = `${ADMIN_POKER}/all?take=1000000&skip=1&order=DESC&keyword=${keyword}`;
    const URL_2 = `${ADMIN_HILO}/all?take=1000000&skip=1&order=DESC&keyword=${keyword}`;
    const URL_3 = `${ADMIN_KENO}/all?take=1000000&skip=1&order=DESC&keyword=${keyword}`;
    const dataHis = [];

    if (indexTab == 1) {
      await API.get(URL_1)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            result?.map((item, index) => {
              const newIt = {
                key: index+1,
                bookmakerName: opBookmaker && opBookmaker.find(x => x.value = item?.bookmakerId).label,
                username: item?.username,
                nickname: item?.nickname,
                code: item?.code,
                play1st: item?.play1st,
                play2nd: item?.play2nd,
                revenue: dollarUS.format(Number(item?.revenue)),
                paymentWin: dollarUS.format(Number(item?.paymentWin) - Number(item?.revenue)),
                rewardTitle: getTxtRewardPoker(item?.rewardTitle),
                isGameOver: item?.isGameOver == true ? 'Kết thúc' : 'Đang mở',
                createdAt: moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
                updatedAt: moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
                updatedBy: item?.updatedBy == 'system' ? 'Có' : 'Không',
                isTestPlayer: item?.isUserFake === 1 ? "Chơi thử" : "Chơi thật",
                
              }
              // console.log(newIt)
              dataHis.push(newIt);
            });
          }
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (indexTab == 2) {
      await API.get(URL_2)
        .then((response) => {
          const result = response.data?.data?.result[0];
          if (result) {
            result?.map((item, index) => {
              const newIt = {
                key: index+1,
                bookmakerName: opBookmaker && opBookmaker.find(x => x.value = item?.bookmakerId).label,
                username: item?.username,
                nickname: item?.nickname,
                code: item?.code,
                cards: item?.cards,
                hiLo: getTxtRewardHilo(item?.hiLo),
                multiHis: item?.multiHis,
                revenue: dollarUS.format(Number(item?.revenue)),
                totalPaymentWin: dollarUS.format(Number(item?.totalPaymentWin) - Number(item?.revenue)),
                isGameOver: item?.isGameOver == true ? 'Kết thúc' : 'Đang mở',
                createdAt: item?.createdAt,
                updatedAt: moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
                updatedBy: item?.updatedBy == 'system' ? 'Có' : 'Không',
                isTestPlayer: item?.isUserFake === 1 ? "Chơi thử" : "Chơi thật",
              }
              dataHis.push(newIt);
            });
          }
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
      } else if (indexTab === 3) {
				await API.get(URL_3)
          .then((response) => {
            const result = response.data?.data?.result[0];
            if (result) {
              result?.map((item, index) => {
								const newIt = {
                  key: index +1,
                  bookmakerName: item?.bookmakerName,
                  username: item?.username,
                  nickname: item?.nickname,
                  code: item?.code,
                  kenoHitBet: item?.kenoHitBet,
                  kenoHitResult: item?.kenoHitResult,
                  multi: item?.multi,
                  typeBet: item?.typeBet,
                  revenue: dollarUS.format(Number(item?.revenue)),
                  totalPaymentWin: dollarUS.format(Number(item?.totalPaymentWin) - Number(item?.revenue)),
                  isGameOver: item?.isGameOver === true ? "Kết thúc" : "Đang mở",
                  createdAt: item?.createdAt,
                  nickname: item?.nickname,
                  updatedBy: item?.updatedBy === "system" ? "Có" : "Không",
                  isTestPlayer: item?.isUserFake === 1 ? "Chơi thử" : "Chơi thật",
                };
                dataHis.push(newIt);
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
			}

			const titleMapping = {
				1: 'Poker',
				2: 'Hilo',
				3: 'Keno'
			};

			const headersMapping = {
        1: [
          "stt",
          TEXT_DEF.text0011,
          "Tên HV mã hoá",
          "Tên HV",
          "Mã vé cược",
          TEXT_DEF.text0141,
          TEXT_DEF.text0142,
          TEXT_DEF.text0126,
          "Lợi nhuận đơn cược",
          "Giải trúng",
          TEXT_DEF.text0088,
          "Thời gian bắt đầu",
          "Thời gian kết thúc",
          "Tự động cập nhật kết quả",
          "Loại tài khoản"
        ],
        2: [
          "stt",
          TEXT_DEF.text0011,
          "Tên HV mã hoá",
          "Tên HV",
          "Mã vé cược",
          "Chi tiết lá bài",
          "Dự đoán",
          "Tỉ lệ nhân",
          TEXT_DEF.text0126,
          "Lợi nhuận đơn cược",
          TEXT_DEF.text0088,
          "Thời gian bắt đầu",
          "Thời gian kết thúc",
          "Tự động cập nhật kết quả",
          "Loại tài khoản"
        ],
        3: ["stt",TEXT_DEF.text0011, "Tên HV mã hoá", "Tên HV", "Mã vé cược", "Dãy số đặt", "Kết quả", "Tỉ lệ nhân", "Cấp độ", TEXT_DEF.text0126, "Lợi nhuận đơn cược", TEXT_DEF.text0088, "Thời gian chơi", "Tự động cập nhật kết quả", "Loại tài khoản"],
      };

      downloadExcel({
        fileName: `Lịch sử ${titleMapping[indexTab]}`,
        sheet: "Sheet 1",
        tablePayload: {
          header: headersMapping[indexTab],
          body: dataHis,
        },
      });
      setLoading(false);
  };

  const renderPoker = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}
          id="form-poker">
            <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
              {auth?.role === 'supper' || auth?.role === 'administrators' ? (
                <div className="col-4">
                  <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                    <Select showSearch placeholder="Chọn nhà cái" optionFilterProp="children" onChange={(val) => setBookmakerSl(val)} filterOption={filterOptionBm} options={opBookmaker} allowClear />
                  </Form.Item>
                </div>
              ) : null}
              <div className="col-4">
                <Form.Item name="usernameFilter" label={'Tên HV mã hóa'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="nicknameFilter" label={'Tên HV'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="codeFilter" label={'Mã vé cược'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'from')}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    disabledTime={(d) => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'to')}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    disabledTime={(d) => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment())}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                  <Select showSearch placeholder="Chọn trò chơi" optionFilterProp="children" onChange={(val) => setIsTestPlayer(val)} filterOption={''} options={playerTypes} allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType="button" type="primary">
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
        {/* {auth?.role === "supper" ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={addNewBank}>
            {TEXT_DEF.text0041}
          </Button>
        </div> : null } */}
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table key="userList" locale={locale} columns={pokerColums} dataSource={pokerHis} pagination={false} bordered style={{ width: '100%' }} scroll={{ x: 1300 }} />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 15,
              justifyContent: 'flex-end',
            }}
          >
            <Pagination current={pagePoker} total={totalRecordPoker} onChange={onChangePagePoker} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
          </div>
        </Spin>
      </>
    );
  };

  const renderHilo = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
            id="form-hilo"
          >
            <div
              className="col-12"
              style={{ marginBottom: 20, display: "flex" }}
            >
              {auth?.role === "supper" || auth?.role === 'administrators' ? <div className="col-4">
                <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                  <Select
                    showSearch
                    placeholder="Chọn nhà cái"
                    optionFilterProp="children"
                    onChange={(val) => setBookmakerSl(val)}
                    filterOption={filterOptionBm}
                    options={opBookmaker}
                    allowClear
                  />
                </Form.Item>
              </div> : null}
              <div className="col-4">
                <Form.Item name="usernameFilter" label={'Tên HV mã hóa'}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="nicknameFilter" label={'Tên HV'}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 flex">
            <div className="col-4">
                <Form.Item name="codeFilter" label={"Mã vé cược"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginTop: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                  <Select showSearch placeholder="Chọn trò chơi" optionFilterProp="children" onChange={(val) => setIsTestPlayer(val)} filterOption={''} options={playerTypes} allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="listHilo"
            locale={locale}
            columns={hiloColums}
            dataSource={hiloHis}
            pagination={false}
            bordered
            style={{ width: "100%" }}
            scroll={{ x: 1300 }}
            />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pageHilo}
            total={totalRecordHilo}
            onChange={onChangePageHilo}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </>
    );
  };


	const renderKeno = () => {
		return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()} id="form-hilo">
            <div className="col-12" style={{ marginBottom: 20, display: "flex" }}>
              {auth?.role === "supper" || auth?.role === 'administrators' ? (
                <div className="col-4">
                  <Form.Item name="bookmakerFilter" label={TEXT_DEF.text0011}>
                    <Select showSearch placeholder="Chọn nhà cái" optionFilterProp="children" onChange={(val) => setBookmakerSl(val)} filterOption={filterOptionBm} options={opBookmaker} allowClear />
                  </Form.Item>
                </div>
              ) :  null}
              <div className="col-4">
                <Form.Item name="usernameFilter" label={"Tên HV mã hóa"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="nicknameFilter" label={"Tên HV"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 flex">
              <div className="col-4">
                <Form.Item name="codeFilter" label={"Mã vé cược"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, "d") : moment())}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment())}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginTop: 15, display: "flex" }}>
              <div className="col-4">
                <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                  <Select showSearch placeholder="Chọn trò chơi" optionFilterProp="children" onChange={(val) => setIsTestPlayer(val)} filterOption={""} options={playerTypes} allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType="button" type="primary">
                {TEXT_DEF.text0027}
              </Button>
            </div>
          </Form>
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table key="listHilo" locale={locale} columns={columnsKeno} dataSource={kenoHis} pagination={false} bordered style={{ width: "100%" }} scroll={{ x: 1300 }} />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination current={pageKeno} total={totalRecordKeno} onChange={onChangePageKeno} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
        </div>
      </>
    );
	}

	const tabRender = {
		1: renderPoker,
		2: renderHilo,
		3: renderKeno,
	}

  return (
    <div className="col-12">
      {auth?.role === "supper" || auth?.role === "admin_bm" ? (
        <div
          className="col-12"
          style={{
            borderBottom: "1px solid #CCC",
            paddingBottom: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Radio.Group value={indexTab} onChange={onChangeTab}>
            <Radio.Button value={1}>{TEXT_DEF.text0139}</Radio.Button>
            <Radio.Button value={2}>{TEXT_DEF.text0140}</Radio.Button>
            <Radio.Button value={3}>{TEXT_DEF.text0151}</Radio.Button>
          </Radio.Group>
        </div>
      ) : null}

      {/* {indexTab === 1 ? renderPoker() : renderHilo()} */}
			{tabRender[indexTab]?.()}
      {/* {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={txtMsg.confirm}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0020}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={txtMsg.success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )} */}
    </div>
  );
};

export default PlayHisOriginals;
