import React, { useState } from "react";
import { Menu } from "antd";
import {
  IdcardFilled,
  FilterFilled,
  SettingFilled,
  NotificationOutlined,
  AndroidOutlined,
  WalletOutlined,
  UserOutlined,
  AppstoreOutlined
} from "@ant-design/icons";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { TEXT_DEF } from "../../constant/text";
import {
  MANAGER_PESMISSION,
  SETTING_HIS,
  SETTING_SYTEMS,
} from "../../constant/generate";
import { useNavigate } from "react-router-dom";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import { useTabs } from '../../contexts/TabContext';

const { SubMenu } = Menu;

const SideBarCustom = () => {
  const { addTab } = useTabs();

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/account"
      : location.pathname
  );
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const currentPath = location.pathname;

  const handleMenuClick = (e) => {
    const tabKey = e.key;
    const tabText = e.domEvent.target.innerText;
    addTab(tabKey, tabText);
  };


  return (
    <>
      <div className="logo font-extrabold flex text-center">VNTOP BACK OFFICE</div>
      <Menu onClick={handleClick} mode="inline" selectedKeys={[currentPath]}
      onSelect={handleMenuClick}>
        <Menu.Item
          style={{ padding: "0 16px" }}
          key="/dashboard"
        // icon={<IdcardFilled className="menu-icon" />}
        >
          <Link to="/dashboard">{'TRANG CHỦ'}</Link>
        </Menu.Item>

        {
          hasRight([RIGHTS.ShowListUsersPlayGame]) ? (
            <Menu.ItemGroup
              title='QUẢN LÝ NGƯỜI CHƠI'
              style={{ paddingLeft: 0 }}
            >
              <Menu.Item
                style={{ padding: "0 16px" }}
                key="/account"
              // icon={<IdcardFilled className="menu-icon" />}
              >
                <Link to="/account">{"DANH SÁCH TÀI KHOẢN"}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : null
        }

        {
          hasRight([RIGHTS.ShowReportOrdersByBookmarker, RIGHTS.ShowReportOrdersByGame, RIGHTS.ShowReportOrdersByUser,
          RIGHTS.ShowReportOrders]) ? (
            <Menu.ItemGroup
              title='THỐNG KÊ'
              style={{ paddingLeft: 0 }}
            >
              {
                hasRight([RIGHTS.ShowReportOrdersByBookmarker]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/report-by-bookmaker"
                  // icon={<IdcardFilled className="menu-icon" />}
                  >
                    <Link to="/report-by-bookmaker">{"NHÀ ĐIỀU HÀNH"}</Link>
                  </Menu.Item>
                ) : null
              }
              {
                hasRight([RIGHTS.ShowReportOrdersByGame]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/report-by-game"
                  // icon={<IdcardFilled className="menu-icon" />}
                  >
                    <Link to="/report-by-game">{"TRÒ CHƠI"}</Link>
                  </Menu.Item>
                ) : null
              }
              {/* <Menu.Item
                  style={{ padding: "0 16px" }}
                  key="/report-by-device"
                // icon={<IdcardFilled className="menu-icon" />}
                >
                  <Link to="/report-by-device">{"NỀN TẢNG"}</Link>
                </Menu.Item> */}
              {hasRight([RIGHTS.ShowReportOrdersByUser]) ? (
                <Menu.Item
                  style={{ padding: "0 16px" }}
                  key="/report-by-user"
                  className={({ isActive }) => isActive ? "active" : ""}
                // icon={<IdcardFilled className="menu-icon" />}
                >
                  <Link to="/report-by-user">{"NGƯỜI CHƠI"}</Link>
                </Menu.Item>
              ) : null
              }
              {hasRight([RIGHTS.ShowReportOrders]) ? (
                <Menu.Item
                  style={{ padding: "0 16px" }}
                  key="/report-by-order"
                // icon={<IdcardFilled className="menu-icon" />}
                >
                  <Link to="/report-by-order">{"ĐƠN CƯỢC"}</Link>
                </Menu.Item>
              ) : null
              }
            </Menu.ItemGroup>
          ) : null
        }

        {
          hasRight([RIGHTS.ShowLotteryAwardXNS]) ? (
            <Menu.ItemGroup
              style={{ paddingLeft: 0 }}
              // icon={<IdcardFilled className="menu-icon" />}
              title='TRA CỨU KẾT QUẢ'
            >
              {/* <Menu.Item
                  style={{ padding: "0 16px" }}
                  key="/orders"
                >
                  <Link to="/orders">{TEXT_DEF.text0074}</Link>
                </Menu.Item> */}
              {
                hasRight([RIGHTS.ShowLotteryAwardXNS]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/lotteryAward"
                  >
                    <Link to="/lotteryAward">{TEXT_DEF.text0145.toUpperCase()}</Link>
                  </Menu.Item>
                ) : null
              }
			  {
                hasRight([RIGHTS.ShowLotteryAwardXNS]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/classicAward"
                  >
                    <Link to="/classicAward">{TEXT_DEF.text0197.toUpperCase()}</Link>
                  </Menu.Item>
                ) : null
              }
            </Menu.ItemGroup>
          ) : null
        }

        {
          hasRight([RIGHTS.ShowWalletHistory]) ? (
            <Menu.ItemGroup
              style={{ paddingLeft: 0 }}
              key="/transaction"
              // icon={<IdcardFilled className="menu-icon" />}
              title='QUẢN LÝ GIAO DỊCH'
            >
              <Menu.Item
                style={{ padding: "0 16px" }}
                key="/userPlayHis"
              >
                <Link to="/userPlayHis">{TEXT_DEF.text0128.toUpperCase()}</Link>
              </Menu.Item>
              <Menu.Item
                style={{ padding: "0 16px" }}
                key="/checkCheating"
              >
                <Link to="/checkCheating">{TEXT_DEF.text0201.toUpperCase()}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : null
        }

        <Menu.ItemGroup
          style={{ paddingLeft: 0 }}
          key="/systemSetting"
          icon={<IdcardFilled className="menu-icon" />}
          title='CÀI ĐẶT HỆ THỐNG'
        >
          {
            (
              hasRight([RIGHTS.ShowSettingLayout, RIGHTS.ShowSettingRankFake, RIGHTS.ShowSettingQA, RIGHTS.ShowSettingGameText])
            ) ? (
              <SubMenu
                style={{ paddingLeft: 0 }}
                key="/systemHome"
                title='CÀI ĐẶT TRANG CHỦ'
              >
                {
                  hasRight([RIGHTS.ShowSettingLayout]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/layout"
                    >
                      <Link to="/layout">{TEXT_DEF.text0033.toUpperCase()}</Link>
                    </Menu.Item>
                  ) : null
                }

                {
                  hasRight([RIGHTS.ShowSettingRankFake]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/bxhFake"
                    >
                      <Link to="/bxhFake">{TEXT_DEF.text0040.toUpperCase()}</Link>
                    </Menu.Item>
                  ) : null
                }

                {
                  hasRight([RIGHTS.ShowSettingQA]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/list-qa"
                    >
                      <Link to="/list-qa">{"Q&A"}</Link>
                    </Menu.Item>) : null
                }

                {
                  hasRight([RIGHTS.ShowSettingGameText]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/game-text"
                    >
                      <Link to="/game-text">{"TEXT HIỆN THỊ"}</Link>
                    </Menu.Item>
                  ) : null
                }
              </SubMenu>
            ) : null
          }

          {/* <SubMenu
            style={{ paddingLeft: 0 }}
            key="/systemPromotion"
            title='KHUYỄN MÃI'
          >
          </SubMenu> */}
        </Menu.ItemGroup>

        <Menu.ItemGroup
          style={{ paddingLeft: 0 }}
          key="/systemGame"
          icon={<IdcardFilled className="menu-icon" />}
          title='CÀI ĐẶT TRÒ CHƠI'
        >
          {
            (
              hasRight([RIGHTS.ShowSettingXSNBonus, RIGHTS.ShowSettingXSNCommon])
            ) ? (
              <SubMenu
                style={{ paddingLeft: 0 }}
                key="/lottery1Config"
                title='XỔ SỐ NHANH'
              >
                {
                  hasRight([RIGHTS.ShowSettingXSNCommon]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/setting-lottery-game"
                    >
                      <Link to="/setting-lottery-game">THÔNG SỐ</Link>
                    </Menu.Item>
                  ) : null
                }
                {
                  hasRight([RIGHTS.ShowSettingXSNBonus]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/bonus-config"
                    >
                      <Link to="/bonus-config">TIỀN THƯỞNG</Link>
                    </Menu.Item>
                  ) : null
                }
                {
                  hasRight([RIGHTS.ShowSettingXSNCommon]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/ranks"
                    >
                      <Link to="/ranks">LEVEL NGƯỜI CHƠI</Link>
                    </Menu.Item>
                  ) : null
                }
              </SubMenu>
            ) : null
          }
          <SubMenu
            style={{ paddingLeft: 0 }}
            key="/lottery2Config"
            title='XỔ SỐ TRUYỀN THỐNG'
          >
            {
                  hasRight([RIGHTS.ShowSettingXSNCommon]) ? (
                    <Menu.Item
                      style={{ padding: "0 16px" }}
                      key="/setting-classic-games"
                    >
                      <Link to="/setting-classic-games">THÔNG SỐ</Link>
                    </Menu.Item>
                  ) : null
            }
          </SubMenu>
          {
            hasRight([RIGHTS.ShowSettingOriginals]) ? (
              <Menu.Item
                style={{ padding: "0 16px" }}
                key="/setting-orginals"
              >
                <Link to="/setting-orginals">{"ORIGINALS"}</Link>
              </Menu.Item>
            ) : null
          }
        </Menu.ItemGroup>

        {
          hasRight([RIGHTS.ShowListBookmarkers]) ? (
            <Menu.ItemGroup
              style={{ paddingLeft: 0 }}
              key="/managerBookmaker"
              icon={<IdcardFilled className="menu-icon" />}
              title='QUẢN LÝ NHÀ ĐIỀU HÀNH'
            >
              <Menu.Item
                style={{ padding: "0 16px" }}
                key="/bookmaker"
              // icon={<IdcardFilled className="menu-icon" />}
              >
                <Link to="/bookmaker">{TEXT_DEF.text0010.toUpperCase()}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : null
        }

        {
          (
            hasRight([RIGHTS.ShowListAdminUsers, RIGHTS.ShowListRoles])
          ) ? (
            <Menu.ItemGroup
              style={{ paddingLeft: 0 }}
              key="/manager"
              // icon={<IdcardFilled className="menu-icon" />}
              title='TÀI KHOẢN QUẢN TRỊ'
            >
              {
                hasRight([RIGHTS.ShowListAdminUsers]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/account-manager"
                  >
                    <Link to="/account-manager">{"DANH SÁCH TÀI KHOẢN"}</Link>
                  </Menu.Item>
                ) : null
              }
              {
                hasRight([RIGHTS.ShowListRoles]) ? (
                  <Menu.Item
                    style={{ padding: "0 16px" }}
                    key="/roles"
                  >
                    <Link to="/roles">{"VAI TRÒ QUẢN TRỊ"}</Link>
                  </Menu.Item>
                ) : null
              }
            </Menu.ItemGroup>
          ) : null
        }

        {
          hasRight([RIGHTS.Super]) &&
          <Menu.ItemGroup
            style={{ paddingLeft: 0 }}
            key="/onOffGame"
            icon={<IdcardFilled className="menu-icon" />}
            title='QUẢN LÝ TRÒ CHƠI'
          >
            <Menu.Item
              style={{ padding: "0 16px" }}
              key="/manager-game"
            // icon={<IdcardFilled className="menu-icon" />}
            >
              <Link to="/manager-game">{"QUẢN LÝ TRÒ CHƠI"}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
				}
        {
          hasRight([RIGHTS.ShowWhiteListByBookmaker]) &&
          <Menu.ItemGroup
            style={{ paddingLeft: 0 }}
            key="/whiteList"
            icon={<IdcardFilled className="menu-icon" />}
            title='WHITE LIST'
          >
            <Menu.Item
              style={{ padding: "0 16px" }}
              key="/white-list"
            // icon={<IdcardFilled className="menu-icon" />}
            >
              <Link to="/white-list">{"WHITE LIST"}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        }
      </Menu>
    </>
  );
};

export default SideBarCustom;
