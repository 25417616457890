import React, { useState, useEffect, useRef } from "react";
import { TEXT_DEF, DELETE_SUCCESS } from "../../constant/text";
import { Button, Input, Table, Form, Pagination, Spin, Tabs } from "antd";
import settingIcon from "../../image/setting.png";
import deteleIcon from "../../image/delete.png";
import ModalCommon from "../../components/Modal";
import API, { QA } from "../../network/api";
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import { MainModal } from "./mainModal";
import UserQuestionCollector from "./userQuestionCollector";
import ListTopic from "./listTopic";

const QAList = () => {
  let isRequest = useRef(false);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [recordSl, setRecordSl] = useState();
  const [qaList, setQaList] = useState();
  const [isReloadQa, setIsReloadQa] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalEditOrDeleteOpen, setIsModalEditOrDeleteOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [type, setType] = useState("");
  const [isReloadTopics, setIsReloadTopics] = useState(false);
  const [currentStepEdit, setCurrentStepEdit] = useState(1);
  const [topicRecord, setTopicRecord] = useState(null);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [params, setParams] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {
    getAllQA();
  }, [params]);

  useEffect(() => {
    if (isReloadQa) {
      getAllQA();
      setIsReloadQa(false);
    }
  }, [isReloadQa]);

  const getAllQA = async () => {
    try {
      setLoading(true);

      const responseQA = await API.get(`${QA}/all?level=3${params}`);
      const resultQA = responseQA.data?.data?.result?.qa;
      const listQA = resultQA.filter((item) => item?.answer !== null || item?.question !== null);
      const totalRecords = listQA.length;
      setTotalRecord(totalRecords);
      if (listQA) {
        setQaList(listQA);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
    }
  };

  const getParams = (action = "", data = {}) => {
    let params = `page=${page}`;
    if (action === "search") {
      setPage(1);
      params = `page=1`;
    } else if (action === "change_page") {
      params = `page=${data?.page || 1}&limit=${data?.size || pageSize}`;
      setPage(data?.page || 1);
    }
    const question = form.getFieldValue("question");
    const answer = form.getFieldValue("answer");
    if (question) {
      params += `&question=${question}`;
    }
    if (answer) {
      params += `&answer=${answer}`;
    }
    setParams(params);

    return params;
  };

  const onChangePage = (page, size) => {
    getParams("change_page", { page, size });
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const search = () => {
    getParams("search");
  };

  const onClearModal = () => {
    setIsShowModal(false);
    setRecordSl(undefined);
  };

  const requestData = async (type) => {
    if (isRequest.current) {
      return;
    }

    isRequest.current = true;

    try {
      await API.delete(`${QA}/${recordSl?.id}`)
        .then((response) => {
          const code = response.data?.data?.code;
          isRequest.current = false;
          if (code === 20005) {
            type === "deleteQA" ? setIsReloadQa(!isReloadQa) : setIsReloadTopics(!isReloadTopics);
            onClearModal();
            setIsShowModalConfirm(false);
            alert(DELETE_SUCCESS);
            setRecordSl(undefined);
          }
        })
        .catch(() => {
          isRequest.current = false;
        });
    } catch (error) {
      isRequest.current = false;
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      width: "30%",
    },
    {
      title: "Trả lời",
      dataIndex: "answer",
      key: "answer",
      render: (record) => {
        // let formattedText = '';
        // if (record.includes('-')) {
        //   formattedText = record.split(' - ').join('- <br>');
        //   return <span dangerouslySetInnerHTML={{__html: formattedText}}></span>;
        // } else {
        //   return <span>{record}</span>;
        // }
        return <span className="whitespace-pre-wrap">{record}</span>;
      },
      width: "50%",
    },
    {
      title: TEXT_DEF.text0016,
      key: "edit",
      align: "center",
      width: "10%",
      render: (record) => (
        <div>
          {hasRight([RIGHTS.EditSettingQA]) ? (
            <div className="flex justify-center">
              <img
                style={{
                  height: 20,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                src={settingIcon}
                alt="setting"
                onClick={() => {
                  setIsModalEditOrDeleteOpen(true);
                  setCurrentStepEdit(2);
                  setType("editQA");
                  handleEdit(record);
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: "Xóa",
      key: "detele",
      width: "10%",
      render: (record) => (
        <div>
          {hasRight([RIGHTS.DeleteSettingQA]) ? (
            <div className="flex justify-center">
              <img
                style={{
                  height: 20,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                src={deteleIcon}
                alt="delete"
                onClick={() => {
                  setRecordSl(record);
                  setType("deleteQA");
                  setIsShowModalConfirm(true);
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const showModal = () => {
    setIsModalCreateOpen(true);
    setType("qa");
  };

  const handleCancel = (type) => {
    if (type === "create") {
      setIsModalCreateOpen(false);
    } else if (type === "editOrDelete") {
      setIsModalEditOrDeleteOpen(false);
    }
  };

  const handleEdit = (record) => {
    setRecord(record);
  };

  const renderListQA = () => {
    return (
      <div className="col-12">
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => search()}>
            <div className="col-12" style={{ marginBottom: 20, display: "flex" }}>
              <div className="col-4">
                <Form.Item name="question" label={"Câu hỏi"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="answer" label={"Trả lời"}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {hasRight([RIGHTS.CreateSettingQA]) ? (
                <Button htmlType="submit" type="primary" onClick={showModal} style={{ marginLeft: 15 }}>
                  {TEXT_DEF.text0068}
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large" style={{ width: "100%" }}>
          <div className="col-12 flex-col" style={{ display: "flex", marginTop: 30, alignItems: "center" }}>
            <Table key="qaList" locale={locale} columns={columns} dataSource={qaList} pagination={false} bordered style={{ width: "100%" }} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination current={page} total={totalRecord} onChange={onChangePage} showTotal={showTotal} showSizeChanger={true} pageSizeOptions={["10", "50", "100", "200", "500", "1000"]} />
          </div>
        </Spin>

        <MainModal
          isModalCreateOpen={isModalCreateOpen}
          isModalEditOrDeleteOpen={isModalEditOrDeleteOpen}
          record={record}
          handleCancel={handleCancel}
          setIsReloadQa={setIsReloadQa}
          type={type}
          setIsReloadTopics={setIsReloadTopics}
          currentStepEdit={currentStepEdit}
          setCurrentStepEdit={setCurrentStepEdit}
          topicRecord={topicRecord}
        />

        {isShowModalConfirm && (
          <ModalCommon
            isShow={isShowModalConfirm}
            message={TEXT_DEF.text0069}
            txtBtnAcc={TEXT_DEF.text0008}
            textBtnClose={TEXT_DEF.text0007}
            actionNext={() => requestData(type)}
            close={() => {
              setIsShowModalConfirm(false);
              setRecordSl(undefined);
            }}
          />
        )}
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Danh sách Q&A",
      children: renderListQA(),
    },
    {
      key: "2",
      label: "Danh sách chủ đề",
      children: (
        <ListTopic
          setIsModalCreateOpen={setIsModalCreateOpen}
          setIsModalEditOrDeleteOpen={setIsModalEditOrDeleteOpen}
          setType={setType}
          isReloadTopics={isReloadTopics}
          setIsReloadTopics={setIsReloadTopics}
          setCurrentStepEdit={setCurrentStepEdit}
          setTopicRecord={setTopicRecord}
          setRecordSl={setRecordSl}
          setIsShowModalConfirm={setIsShowModalConfirm}
        />
      ),
    },
    {
      key: "3",
      label: "Câu hỏi của hội viên",
      children: <UserQuestionCollector />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default QAList;
