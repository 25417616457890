import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Form, Spin, Table, Pagination, Tooltip, Modal, Input, Button, DatePicker, Select } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { TEXT_DEF } from '../../constant/text';
import { RIGHTS } from '../../constant/rights';
import { hasRight } from '../../components/until/right';
import moment from 'moment';
import 'moment-timezone';
import API, { WHITE_LIST, BOOKMAKER } from '../../network/api';
import { validationIpV4 } from '../../validation';
import 'moment-timezone';
import dayjs from 'dayjs';
import { disabledTimeFrom, disabledTimeTo } from "../../utils/dateUtils";

const WhiteList = () => {
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [ipWhiteList, setIPWhiteList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecordWhiteList, setTotalRecordWhiteList] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [ipAddress, setIPAddress] = useState();
  const [bookmarkerSelected, setBookmarkerSelected] = useState();
  const [id, setId] = useState();
  const [bookmarkers, setBookmarkers] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [params, setParams] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = localStorage.getItem("auth");
  const authObject = auth ? JSON.parse(auth) : null;

  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const { confirm } = Modal;

  useEffect(() => {
    getIpWhiteListByAllBookMaker();
  }, [page, params]);

  const getIpWhiteListByAllBookMaker = async () => {
    setLoading(true);

    try {
      const searchParams = params ? params : '';
      const response = await API.get(`${WHITE_LIST}/index?take=10&skip=${page}${searchParams}`);
      const result = response?.data?.data?.[0] || [];
      const total = response?.data?.data?.[1] || [];
      const listIPAddress = [];
      for (const item of result) {
        listIPAddress.push({
          key: item?.id,
          bookmaker: item?.bookmaker?.name,
          ipAddress: item?.ip,
          note: item?.note,
          createdAt: item?.createdAt,
          updatedAt: item?.updatedAt,
          updatedBy: item?.updatedBy?.username,
        });
      }
      setLoading(false);
      setIPWhiteList(listIPAddress);
      setTotalRecordWhiteList(total);
    } catch (error) {
      console.log('Error: ', error);
      setLoading(false);
    }
  };

  const getBookMarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });

    setBookmarkers(tempBookmarkers);
    setBookmarkerSelected(tempBookmarkers?.[0]?.key);

    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.label,
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;

    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
      });
    }
  };

  const getParams = () => {
    let params = '';

    const bookmaker = form.getFieldValue('bookmaker');

    if (bookmaker) {
      params += `&bookmaker=${bookmaker}`;
    }

    if (dateFrom) {
      params += `&fromDate=${moment(dateFrom).unix()}`;
    }

    if (dateTo) {
      params += `&toDate=${moment(dateTo).unix()}`;
    }

    setParams(params);
  };

  const onSearch = () => {
    getParams();
    setPage(1);
  };

  const showConfirm = (ipAddressID) => {
    confirm({
      title: TEXT_DEF.text0170,
      icon: <ExclamationCircleFilled />,
      okText: "Xác nhận",
      cancelText: "Đóng",
      onOk() {
        handleDeleteIpAddress(ipAddressID);
      },
      onCancel() {},
    });
  };

  const whitelistCol = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
      fixed: 'left',
      width: 80,
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: 'bookmaker',
      key: 'bookmaker',
      fixed: 'left',
    },
    {
      title: 'Địa Chỉ IP',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      fixed: 'left',
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      key: 'note',
      fixed: 'left',
    },
    {
      title: 'Thời gian tạo',
      key: 'createdAt',
      render: (record) => moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Thời gian cập nhật',
      key: 'createdAt',
      render: (record) => moment(record?.updatedAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
    {
      title: 'Hành động',
      key: 'id',
      align: 'center',
      width: 200,
      render: (record) => (
        <>
          {hasRight([RIGHTS.EditWhiteList]) ? (
            <Tooltip title="Sửa địa chỉ IP">
              <button type="button" className="btn btn-outline-warning btn-sm rounded-circle mr-1" onClick={() => openEditIPAddressModal(record.key)}>
                <EditOutlined />
              </button>
            </Tooltip>
          ) : null}
          {hasRight([RIGHTS.DeleteWhiteList]) ? (
            <Tooltip title="Xoá bản ghi">
              <button type="button" className="btn btn-outline-danger btn-sm rounded-circle mr-1" onClick={() => showConfirm(record.key)}>
                <DeleteOutlined />
              </button>
            </Tooltip>
          ) : null}
        </>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} tài khoản`;

  const openEditIPAddressModal = async (ipAddressID) => {
    if (ipAddressID) {
      setLoading(true);
      const response = await API.get(`${WHITE_LIST}/${ipAddressID}`);
      const result = response?.data?.data?.result;
      if (result) {
        form.resetFields();
        formEdit.setFieldValue("editIPAddress", result?.ip);
        formEdit.setFieldValue("editNote", result?.note);
        setIPAddress(result);
      }
      setLoading(false);
    } else {
      setIPWhiteList(undefined);
      form.resetFields();
    }
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleEditIPAddress = async () => {
    const params = {
      ip: formEdit.getFieldValue("editIPAddress")?.trim() || ipAddress?.ip,
      bookmaker: formEdit.getFieldValue("editBookMaker")?.trim() || ipAddress?.bookmaker,
      note: formEdit.getFieldValue("editNote")?.trim() || ipAddress?.note,
    };

    if (isSubmitting) return;
    setLoading(true);
    setIsSubmitting(true);
    try {
      const response = await API.put(`${WHITE_LIST}/${ipAddress.id}`, params);
      const statusCode = response?.data?.data?.code;

      if (statusCode === 20004) {
        await getIpWhiteListByAllBookMaker();
        setIsSubmitting(false);

        setIsEditModalOpen(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleDeleteIpAddress = async (ipAddressID) => {
    if (!ipAddressID) return;

    setLoading(true);

    try {
      const response = await API.delete(`${WHITE_LIST}/${ipAddressID}`);
      const statusCode = response?.data?.data?.code;

      if (statusCode === 20005) {
        await getIpWhiteListByAllBookMaker();
      }
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  };

  const addIPAddress = async (values) => {
    if (isSubmitting) return;
    setLoading(true);
    setIsSubmitting(true);

    const updatedValues = {
      ...values,
      bookmaker: authObject?.bookmakerId || id,
    };

    try {
      const response = await API.post(`${WHITE_LIST}/create`, updatedValues);
			const statusCode = response?.data?.data?.code;

      if (statusCode === 20001) {
        await getIpWhiteListByAllBookMaker();
      }

      setIsCreateModalOpen(false);
      setLoading(false);
      setIsSubmitting(false);
      formCreate.resetFields();
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleDropdownVisibleChange = (open) => {
    if (open) {
      getBookMarkers();
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeDate = (e, type) => {
    if (e) {
      const selectedDate = moment(e.$d);
      const today = moment();

      if (selectedDate.isSame(today, 'day')) {
        if (type === "from") {
          setDateFrom(moment(today).startOf("day").format("YYYY-MM-DD HH:mm:ss"));
        } else {
          setDateTo(today.format("YYYY-MM-DD HH:mm:ss"));
        }
      } else {
        const formattedDate = selectedDate.format("YYYY-MM-DD HH:mm:ss");
        if (type === "from") {
          setDateFrom(formattedDate);
        } else {
          setDateTo(formattedDate);
        }
      }
    } else {
      if (type === "from") {
        setDateFrom(null);
      } else {
        setDateTo(null);
      }
    }
  };

  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateTo).subtract(3, 'months').startOf('day') || current > dayjs(dateTo).endOf('day'));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateFrom).startOf('day') || current > dayjs(dateFrom).add(3, 'months').endOf('day') || current > dayjs(dateFrom).add(3, "months").endOf('day'));
  };

  const handleCancelModalCreate = () => {
    setIsCreateModalOpen(false);
    formCreate.resetFields();
  };

  const handleCancelModalEdit = () => {
    setIsEditModalOpen(false);
    formEdit.resetFields();
  };

  const renderWhiteList = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
              <>
                <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
                  {hasRight([RIGHTS.Super]) ? (
                    <div className="col-4">
                      <Form.Item name="bookmaker" label={TEXT_DEF.text0011}>
                        <Select
                          showSearch
                          placeholder="Chọn nhà cái"
                          optionFilterProp="children"
                          // onChange={(val) => setBookmarkerSelected(val)}
                          filterOption={filterOptionBm}
                          options={bookmarkers}
                          allowClear
                          onDropdownVisibleChange={handleDropdownVisibleChange}
                          loading={loading}
                        />
                      </Form.Item>
                    </div>
                  ) : null}
                </div>
                <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
                  <div className="col-4">
                    <Form.Item
                      name="dateFrom"
                      label={TEXT_DEF.text0024}
                      rules={[
                        {
                          required: true,
                          message: TEXT_DEF.text0004,
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        onChange={(e) => onChangeDate(e, "from")}
                        format={"DD-MM-YYYY"}
                        style={{ width: "100%" }}
                        disabledDate={disabledDateFrom}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-4">
                    <Form.Item
                      name="dateTo"
                      label={TEXT_DEF.text0025}
                      rules={[
                        {
                          required: true,
                          message: TEXT_DEF.text0004,
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        onChange={(e) => onChangeDate(e, "to")}
                        format={"DD-MM-YYYY"}
                        style={{ width: "100%" }}
                        disabledDate={disabledDateTo}
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
              <div className="action">
                <Button htmlType="submit" type="primary">
                  {TEXT_DEF.text0026}
                </Button>
                <Button htmlType="button" type="primary" className="ml-2" onClick={() => setIsCreateModalOpen(true)}>
                  {TEXT_DEF.text0171}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <Spin spinning={loading} delay={500} size="large" style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table key="whitelist" locale={locale} columns={whitelistCol} dataSource={ipWhiteList} pagination={false} bordered style={{ width: "100%" }} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination current={page} total={totalRecordWhiteList} onChange={onChangePage} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
          </div>
        </Spin>
      </>
    );
  };

  const handleChange = (value) => {
    setId(value);
  };

  return (
    <div className="col-12">
      {renderWhiteList()}

      {/** Modal Edit */}
      <Modal
        title="Sửa địa chỉ IP"
        open={isEditModalOpen}
        okText="Lưu"
        onCancel={handleCancelModalEdit}
        cancelText="Huỷ"
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
          disabled: isSubmitting,
        }}
        destroyOnClose
        modalRender={(dom) => (
          <Form layout="vertical" form={formEdit} name="form_in_modal" initialValues={{ modifier: "public" }} clearOnDestroy onFinish={() => handleEditIPAddress()} autoComplete="off">
            {dom}
          </Form>
        )}
      >
        <div className="form-group mb-4 col-12">
          <label htmlFor="editIPAddress" className="mb-2">
            {TEXT_DEF.text0164} <span style={{ color: "red" }}>*</span>
          </label>
          <Form.Item
            name="editIPAddress"
            style={{ marginRight: 0 }}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập thông tin",
              },
              {
                validator: async (_, value) => {
                  if (value) {
                    const isValid = validationIpV4(value);
                    if (!isValid) {
                      return Promise.reject(TEXT_DEF.text0163);
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={255} defaultValue={ipAddress?.ip} />
          </Form.Item>
        </div>

        <div className="form-group mb-4 col-12">
          <label htmlFor="editBookMaker" className="mb-2">
            {TEXT_DEF.text0011} <span style={{ color: "red" }}>*</span>
          </label>
          <Form.Item name="editBookMaker" style={{ marginRight: 0 }}>
            <Input maxLength={255} defaultValue={ipAddress?.bookmakerData?.name} disabled={true} />
          </Form.Item>
        </div>

        <div className="form-group mb-4 col-12">
          <label htmlFor="editNote" className="mb-2">
            {TEXT_DEF.text0165}
          </label>
          <Form.Item name="editNote" style={{ marginRight: 0 }}>
            <Input maxLength={255} defaultValue={ipAddress?.note} />
          </Form.Item>
        </div>
        <div className="form-group col-12 flex justify-center flex-row">
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </div>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="default" onClick={handleCancelModalEdit}>
                  Huỷ
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Modal>

      {/** Modal Create */}
      <Modal
        title="Thêm mới"
        open={isCreateModalOpen}
        okText="Thêm"
        cancelText="Huỷ bỏ"
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
          disabled: isSubmitting,
        }}
        onCancel={handleCancelModalCreate}
        destroyOnClose
        modalRender={(dom) => (
          <Form layout="vertical" form={formCreate} name="form_in_modal" initialValues={{ modifier: "public" }} clearOnDestroy onFinish={(values) => addIPAddress(values)} autoComplete="off">
            {dom}
          </Form>
        )}
      >
        <div className="form-group mb-4 col-12">
          <label htmlFor="ip" className="mb-2">
            {TEXT_DEF.text0164} <span style={{ color: "red" }}>*</span>
          </label>
          <Form.Item
            name="ip"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập thông tin",
              },
              {
                validator: async (_, value) => {
                  if (value) {
                    const isValid = validationIpV4(value);
                    if (!isValid) {
                      return Promise.reject(TEXT_DEF.text0163);
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            style={{ marginRight: 0 }}
          >
            <Input />
          </Form.Item>
        </div>

        {hasRight([RIGHTS.Super]) ? (
          <div className="form-group mb-4 col-12">
            <label htmlFor="ip" className="mb-2">
              {TEXT_DEF.text0011} <span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item name="bookmaker" rules={[{ required: true, message: "Vui lòng nhập thông tin!" }]}>
              <Select
                showSearch
                placeholder="Chọn nhà cái"
                optionFilterProp="children"
                filterOption={filterOptionBm}
                onChange={handleChange}
                options={bookmarkers}
                allowClear
                onDropdownVisibleChange={handleDropdownVisibleChange}
                loading={loading}
              />
            </Form.Item>
          </div>
        ) : null}

        <div className="form-group mb-4 col-12">
          <label htmlFor="note" className="mb-2">
            {TEXT_DEF.text0165}
          </label>
          <Form.Item name="note" style={{ marginRight: 0 }}>
            <Input maxLength={255} />
          </Form.Item>
        </div>
        <div className="form-group col-12 flex justify-center flex-row">
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="primary" htmlType="submit">
                  Thêm
                </Button>
              </div>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <div className="lg-btn">
                <Button type="default" onClick={handleCancelModalCreate}>
                  Đóng
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WhiteList;
