export const OP_CATEGORY = [
  {
    key: 'category-0',
    value: 'all',
    label: "Tất cả"
  },
  {
    key: 'category-1',
    value: 'lottery',
    label: "Xổ số"
  },
  {
    key: 'category-2',
    value: 'casino',
    label: "Game Việt"
  },
]

export const OP_GAME_ORIGINALS = [
  {
    key: 'originnal-0',
    value: 'hilo',
    label: "Hilo"
  },
  {
    key: 'originnal-1',
    value: 'video-poker',
    label: "Poker"
  },
  {
    key: 'originnal-2',
    value: 'keno',
    label: "Keno"
  }
]
