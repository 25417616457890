import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import moment from "moment";
import {
    Form,
    Input,
    Button,
    DatePicker,
    Table,
    Radio,
    Spin,
    Pagination,
    Select,
} from "antd";
import { TEXT_DEF } from "../../constant/text";
import API, { ADMIN_ORDERS, GAME, BOOKMAKER } from "../../network/api";
import { getGame, extractNumbers } from "../../components/until";
import { playerTypes } from "../../constant/playerTypes";

const StatisticOrdersOfUser = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const [form] = Form.useForm();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [gameTypes, setGameTypes] = useState();
    const [bookmarkers, setBookmarkers] = useState();
    const [bookmarkerSelected, setBookmarkerSelected] = useState();
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(false);
    const [gameSl, setGameSl] = useState();
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [isTestPlayer, setIsTestPlayer] = useState('false');
    const locale = {
        emptyText: TEXT_DEF.text0043,
    };
    const dollarUS = Intl.NumberFormat("en-US");

    const orderColums = [
        {
            title: "Name",
            width: 30,
            fixed: "left",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Loại trò chơi",
            width: 30,
            fixed: "left",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Tổng đơn cược",
            width: 20,
            fixed: "left",
            dataIndex: "count",
            key: "count",
        },
        {
            title: "Tiền đặt cược",
            width: 30,
            fixed: "left",
            dataIndex: "revenue",
            key: "revenue",
        },
        {
            title: "Lợi nhuận",
            width: 30,
            fixed: "left",
            dataIndex: "paymentWin",
            key: "paymentWin",
        },
    ];

    useEffect(() => {
        getGameTypes();
        getBookmarkers();
    }, []);

    useEffect(() => {
        if (!bookmarkerSelected) return;

        getOrders();
    }, [bookmarkerSelected, page]);

    const getGameTypes = async () => {
        const result = await API.get(`${GAME}/all`);
        if (!result?.data?.data?.result || result?.data?.data?.result.length === 0) return;

        const dataGame = (result?.data?.data?.result || []).map((item) => {
            return {
                key: item?.id,
                value: `${item?.parrentType}-${extractNumbers(item?.type)}`,
                label: getGame(item?.parrentType, extractNumbers(item?.type))
            }
        });
        setGameTypes(dataGame);
    }

    const getBookmarkers = async () => {
        const result = await API.get(`${BOOKMAKER}/all`);
        const bookmarkers = result.data?.data?.result[0] || [];
        if (!bookmarkers || bookmarkers.length === 0) return;

        const tempBookmarkers = bookmarkers?.map((item) => {
            return {
                key: item?.id,
                value: item?.id,
                label: item?.name,
            };
        });
        setBookmarkers(tempBookmarkers);
        setBookmarkerSelected(tempBookmarkers?.[0]?.key);
        initFormValues(tempBookmarkers);
    };

    const getOrders = async (fromDate, toDate) => {
        setLoading(true);
        const fromDateTemp = form?.getFieldValue("fromDate");
        if (!fromDateTemp) {
            fromDate = moment(new Date()).format("YYYY-MM-DD");
        } else {
            fromDate = fromDateTemp;
        }

        const toDateTemp = form?.getFieldValue("toDate");
        if (!toDateTemp) {
            toDate = moment(new Date()).format("YYYY-MM-DD");
        } else {
            toDate = toDateTemp;
        }

        let params = `fromDate=${fromDate}&toDate=${toDate}`;
        if (bookmarkerSelected) {
            params += `&bookmarkerId=${bookmarkerSelected}`;
        } else if (auth?.role === "admin_bm" || auth?.role === "administrators_bm") {
            params += `&bookmarkerId=${auth?.bookmakerId}`;
        }
        if (gameSl) {
            params += `&type=${gameSl}`;
        }
        const username = form.getFieldValue("username")?.trim()?.toLowerCase();
        if (username) {
            params += `&username=${username}`;
        }
        if (isTestPlayer) {
            params += `&isTestPlayer=${isTestPlayer}`;
        }
        if (page) {
            params += `&skip=${page}`;
        }
        if (form?.getFieldValue("turnIndex")) {
            const turnIndex = form
                ?.getFieldValue("turnIndex")
                ?.trim();
            params += `&turnIndex=${turnIndex}`;
        }
        const result = await API.get(`${ADMIN_ORDERS}/statistic-orders?${params}`);
        const orders = _.get(result, 'data.data.orders', []);
        const total = _.get(result, 'data.data.total', []);
        setTotalRecord(total);
        const finalResult = [];
        for (const item of orders) {
            finalResult.push({
                count: item.count,
                name: item.name,
                paymentWin: dollarUS.format(Number(item?.paymentWin)),
                revenue: dollarUS.format(Number(item?.revenue)),
                type: `${item.type}${item.seconds}`,
            });
        }

        setOrders(finalResult);
        setLoading(false);
    };

    const initFormValues = (bookmarkers) => {
        form.setFieldsValue({
            bookmarker: bookmarkers?.[0]?.label,
        });
        form.setFieldsValue({
            isTestPlayer: 'false',
        });
    }

    const onChangeDate = (e, type) => {
        if (!e) {
            setFromDate();
            setToDate();
            return;
        }

        if (type === "fromDate") {
            setFromDate(moment(e.$d).format("YYYY-MM-DD"));
        }

        if (type === "toDate") {
            setToDate(moment(e.$d).format("YYYY-MM-DD"));
        }
    };

    const changePage = (page) => {
        setPage(page);
    };

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

    const onSearch = () => {
        // if (!fromDate && !toDate) return;

        if ((new Date(fromDate)) > (new Date(toDate))) return;

        getOrders(fromDate, toDate);
    }

    return (
        <>
            <Form
                form={form}
                layout="inline"
                autoComplete="off"
                onFinish={() => onSearch()}
            >
                <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                    {auth?.role === "supper" || auth?.role === "administrators" ? <div className="col-4">
                        <Form.Item name="bookmarker" label={TEXT_DEF.text0011}>
                            <Select
                                showSearch
                                placeholder="Chọn nhà cái"
                                // optionFilterProp="children"
                                onChange={(val) => setBookmarkerSelected(val)}
                                // filterOption={filterOptionBm}
                                options={bookmarkers}
                                allowClear
                            />
                        </Form.Item>
                    </div> : null}
                    <div className="col-4">
                        <Form.Item name="gameFilter" label={TEXT_DEF.text0080}>
                            <Select
                                showSearch
                                placeholder="Chọn trò chơi"
                                optionFilterProp="children"
                                onChange={(val) => setGameSl(val)}
                                // filterOption={filterOptionBm}
                                options={gameTypes}
                                allowClear
                            />
                        </Form.Item>
                    </div>

                    <div className="col-4">
                        <Form.Item name="username" label={'Tên HV mã hoá'}>
                            <Input style={{ width: "100%" }} />
                        </Form.Item>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{ marginBottom: 15, display: "flex" }}
                >
                    <div className="col-4">
                        <Form.Item name="fromDate" label="Bắt đầu">
                            <DatePicker
                                placeholder=""
                                onChange={(e) => onChangeDate(e, 'fromDate')}
                                format={"YYYY-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <Form.Item name="toDate" label="Kết thúc">
                            <DatePicker
                                placeholder=""
                                onChange={(e) => onChangeDate(e, 'toDate')}
                                format={"YYYY-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                            <Select
                                showSearch
                                placeholder="Chọn trò chơi"
                                optionFilterProp="children"
                                onChange={(val) => setIsTestPlayer(val)}
                                filterOption={''}
                                options={playerTypes}
                                allowClear
                            />
                        </Form.Item>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{ marginBottom: 15, display: "flex" }}
                >
                    <div className="col-4">
                        <Form.Item name="turnIndex" label={'Lượt xổ'}>
                            <Input style={{ width: "100%" }} />
                        </Form.Item>
                    </div>
                </div>
                <div className="payment-search">
                    <Button htmlType="submit" type="primary">
                        {TEXT_DEF.text0026}
                    </Button>
                </div>
            </Form>

            <div className="col-12">
                <Spin spinning={loading} delay={500} size="large">
                    <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                        <Table
                            key="lotteryAwardList"
                            locale={locale}
                            columns={orderColums}
                            dataSource={orders}
                            pagination={false}
                            bordered
                            style={{ width: "100%" }}
                            scroll={{
                                x: 1200,
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 15,
                            justifyContent: "flex-end",
                        }}
                    >
                        <Pagination
                            current={page}
                            total={totalRecord}
                            onChange={changePage}
                            defaultPageSize={10}
                            showSizeChanger={false}
                            showTotal={showTotal}
                        />
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default StatisticOrdersOfUser;