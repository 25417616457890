const hasRight = (rightsOfUser) => {
    if (!rightsOfUser || rightsOfUser.length === 0) return false;
    const auth = localStorage.getItem('auth');
    let rights = '';
    try {
        rights = JSON.parse(auth)?.permissions || '';
    } catch (err) {
        rights = '';
    }
    rights = rights.split(',');
    if (!rights || rights.length === 0) return false;

    let hasRight = false;
    const superRole = 'super';
    for (const rOfUser of rightsOfUser) {
        hasRight = rights.some(r => {
            return (r === rOfUser.Name || r === superRole);
        });
        if (hasRight) break;
    }

    return hasRight;
}

export {
    hasRight,
};