export const playerTypes = [
  {
    key: 0,
    value: 'false',
    label: 'Chơi thật',
  },
  {
    key: 1,
    value: 'true',
    label: 'Chơi thử',
  },
];
