import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import moment from "moment";
import {
    Form,
    Input,
    Button,
    DatePicker,
    Table,
    Radio,
    Spin,
    Pagination,
    Select,
} from "antd";
import { TEXT_DEF } from "../../../constant/text";
import API, { Lottery_Award, GAME, BOOKMAKER } from "../../../network/api";
import { getGame, extractNumbers } from "../../../components/until";
import { playerTypes } from "../../../constant/playerTypes";

const StatisticLottery = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const [form] = Form.useForm();
    const [lotteriesAward, setLotteriesAward] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [loading, setLoading] = useState(false);
    const [bookmarkers, setBookmarkers] = useState();
    const [bookmarkerSelected, setBookmarkerSelected] = useState();
    const [gameTypes, setGameTypes] = useState();
    const [gameSl, setGameSl] = useState();
    const [isTestPlayer, setIsTestPlayer] = useState('false');
    const locale = {
        emptyText: TEXT_DEF.text0043,
    };
    const dollarUS = Intl.NumberFormat("en-US");

    const orderColums = [
        {
            title: "Loại trò chơi",
            width: 30,
            fixed: "left",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Tổng đơn cược",
            width: 20,
            fixed: "left",
            dataIndex: "count",
            key: "count",
        },
        {
            title: "Tiền đặt cược",
            width: 30,
            fixed: "left",
            dataIndex: "totalRevenue",
            key: "totalRevenue",
        },
        {
            title: "Lợi nhuận nhà cái",
            width: 30,
            fixed: "left",
            dataIndex: "totalProfit",
            key: "totalProfit",
        },
        {
            title: "Phần trăm lợi nhuận",
            width: 30,
            fixed: "left",
            dataIndex: "percentProfit",
            key: "percentProfit",
        },
    ];

    useEffect(() => {
        if (!bookmarkerSelected) return;

        report();
    }, [bookmarkerSelected]);

    useEffect(() => {
        getBookmarkers();
        getGameTypes();
    }, []);

    const report = async (fromDate, toDate) => {
        setLoading(true);
        if (!fromDate) {
            fromDate = moment(new Date()).format("YYYY-MM-DD");
        }
        if (!toDate) {
            toDate = moment(new Date()).format("YYYY-MM-DD");
        }
        let params = `fromDate=${fromDate}&toDate=${toDate}`;
        if (bookmarkerSelected) {
            params += `&bookmarkerId=${bookmarkerSelected}`;
        } else if (auth?.role === "admin_bm" || auth?.role === "administrators_bm") {
            params += `&bookmarkerId=${auth?.bookmakerId}`;
        }
        if (gameSl) {
            params += `&type=${gameSl}`;
        }
        if (isTestPlayer) {
            params += `&isTestPlayer=${isTestPlayer}`;
        }
        const result = await API.get(`${Lottery_Award}/report?${params}`);
        const lotteriesAward = _.get(result, 'data.data', []);
        const dataList = [];
        for (const item of lotteriesAward) {
            dataList.push({
                type: item.type,
                totalProfit: dollarUS.format(Number(item?.totalProfit)),
                totalRevenue: dollarUS.format(Number(item?.totalRevenue)),
                count: item.count,
                percentProfit: `${((
                    (
                        !Number(item.totalProfit)
                        || !Number(item.totalRevenue)
                    ) ? 0 : (Number(item.totalProfit) / Number(item.totalRevenue))
                ) * 100).toFixed(2)} (%)`
            });
        }
        setLotteriesAward(dataList);
        setLoading(false);
    }

    const getBookmarkers = async () => {
        const result = await API.get(`${BOOKMAKER}/all`);
        const bookmarkers = result.data?.data?.result[0] || [];
        if (!bookmarkers || bookmarkers.length === 0) return;

        const dataBookmarker = bookmarkers?.map((item) => {
            return {
                key: item?.id,
                value: item?.id,
                label: item?.name,
            };
        });
        setBookmarkers(dataBookmarker);
        setBookmarkerSelected(dataBookmarker?.[0]?.key);
        initFormValues(dataBookmarker);
    };

    const initFormValues = (dataBookmarker) => {
        form.setFieldsValue({
            bookmarker: dataBookmarker?.[0]?.label,
        });
        form.setFieldsValue({
            isTestPlayer: 'false',
        });
    }

    const onChangeDate = (e, type) => {
        if (!e) {
            setFromDate();
            setToDate();
        }

        if (type === "fromDate") {
            setFromDate(moment(e.$d).format("YYYY-MM-DD"));
        }

        if (type === "toDate") {
            setToDate(moment(e.$d).format("YYYY-MM-DD"));
        }
    };

    const getGameTypes = async () => {
        const result = await API.get(`${GAME}/all`);
        if (!result?.data?.data?.result || result?.data?.data?.result.length === 0) return;

        const dataGame = (result?.data?.data?.result || []).map((item) => {
            return {
                key: item?.id,
                value: `${item?.parrentType}-${extractNumbers(item?.type)}`,
                label: getGame(item?.parrentType, extractNumbers(item?.type))
            }
        });
        setGameTypes(dataGame);
    }

    const onSearch = () => {
        if ((new Date(fromDate)) > (new Date(toDate))) return;

        report(fromDate, toDate);
    };

    return (
        <>
            <div className="col-12">
                <Form
                    form={form}
                    layout="inline"
                    autoComplete="off"
                    onFinish={() => onSearch()}
                >
                    <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                        {auth?.role === "supper" || auth?.role === "administrators" ? <div className="col-4">
                            <Form.Item name="bookmarker" label={TEXT_DEF.text0011}>
                                <Select
                                    showSearch
                                    placeholder="Chọn nhà cái"
                                    // optionFilterProp="children"
                                    onChange={(val) => setBookmarkerSelected(val)}
                                    // filterOption={filterOptionBm}
                                    options={bookmarkers}
                                    allowClear
                                />
                            </Form.Item>
                        </div> : null}
                        <div className="col-4">
                            <Form.Item name="gameFilter" label={TEXT_DEF.text0080}>
                                <Select
                                    showSearch
                                    placeholder="Chọn trò chơi"
                                    optionFilterProp="children"
                                    onChange={(val) => setGameSl(val)}
                                    // filterOption={filterOptionBm}
                                    options={gameTypes}
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                        <div className="col-4">
                            <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                                <Select
                                    showSearch
                                    placeholder="Chọn trò chơi"
                                    optionFilterProp="children"
                                    onChange={(val) => setIsTestPlayer(val)}
                                    filterOption={''}
                                    options={playerTypes}
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div
                        className="col-12"
                        style={{ marginBottom: 15, display: "flex" }}
                    >
                        <div className="col-4">
                            <Form.Item name="fromDate" label="Bắt đầu">
                                <DatePicker
                                    placeholder=""
                                    onChange={(e) => onChangeDate(e, 'fromDate')}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-4">
                            <Form.Item name="toDate" label="Kết thúc">
                                <DatePicker
                                    placeholder=""
                                    onChange={(e) => onChangeDate(e, 'toDate')}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </div>
                        <div className="payment-search">
                            <Button htmlType="submit" type="primary">
                                {TEXT_DEF.text0026}
                            </Button>
                        </div>
                    </div>
                </Form>
                <Spin spinning={loading} delay={500} size="large">
                    <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                        <Table
                            key="lotteryAwardList"
                            locale={locale}
                            columns={orderColums}
                            dataSource={lotteriesAward}
                            pagination={false}
                            bordered
                            style={{ width: "100%" }}
                            scroll={{
                                x: 1200,
                            }}
                        />
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default StatisticLottery;