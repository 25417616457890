import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Table, Pagination, Spin } from 'antd';
import './style.css'
import API, { WALLET_INOUT } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import moment from "moment";
import "moment-timezone";

const DetailModal = ({isShow, params, close}) => {

    const [dataTable, setDataTable] = useState();
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const locale = {
        emptyText: TEXT_DEF.text0043,
    };
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const keyword = encodeURIComponent(JSON.stringify(params)) 
        const getDataTable = async () => {
          setLoading(true);
          await API.get(`${WALLET_INOUT}/history?take=10&skip=${page}&order=DESC&keyword=${keyword}`)
          .then((response) => {
            const result = response.data?.data?.result[0];
            if (result) {
              const dataList = [];
              result?.map((item) => {
                const newIt = {
                  key: item?.id,
                  username: item?.user?.username,
                  bookmaker: item?.user?.bookmaker?.name,
                  createdAt: item?.createdAt,
                  balance: dollarUS.format(Number(item?.balance))
                }
                dataList.push(newIt);
              });
    
              setDataTable(dataList);
            }
            setTotalRecord(response.data?.data?.result[1]);
            setLoading(false);
          }).catch((error) => {
            console.log(error);
            setLoading(false);
          });
        }
    
        getDataTable();
    }, [page])

    const dollarUS = Intl.NumberFormat("en-US");

    const columns = [
        {
            title: "STT",
            align: "center",
            key: "order",
            render: (text, record, index) => (
                <span>{(page - 1) * 10 + (index + 1)}</span>
            ),
        },
        {
            title: TEXT_DEF.text0011,
            key: "bookmaker",
            dataIndex: "bookmaker",

        },
        {
            title: TEXT_DEF.text0002,
            key: "username",
            dataIndex: "username",
        },
        {
            title: TEXT_DEF.text0114,
            key: "balance",
            dataIndex: "balance",
        },
        {
            title: TEXT_DEF.text0087,
            key: "timeOut",
            render: (record) => moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
        },
    ];

    const onChangePage = (page) => {
        setPage(page);
    };

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;
    
    return (
        <Modal show={isShow} className='modal-detail' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{"Chi tiết lịch sử ví"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Spin spinning={loading} delay={500} size="large">
                    <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                        <Table
                            key="dataTable"
                            locale={locale}
                            columns={columns}
                            dataSource={dataTable}
                            pagination={false}
                            bordered
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 15,
                            justifyContent: "flex-end",
                        }}
                    >
                        <Pagination
                            current={page}
                            total={totalRecord}
                            onChange={onChangePage}
                            defaultPageSize={10}
                            showSizeChanger={false}
                            showTotal={showTotal}
                        />
                    </div>
                </Spin>
            </Modal.Body>
            <Modal.Footer>
                <Button type="default" onClick={close}>
                    Đóng
                </Button>  
            </Modal.Footer>
        </Modal>
    )
}

export default DetailModal