import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  Table,
  Radio,
  Spin,
  Pagination,
  Select,
  InputNumber
} from "antd";
import API, { DATA_FAKE, GAME } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { TEXT_DEF } from "../../constant/text";
import ModalCommon from "../../components/Modal";
import { extractNumbers, getGame } from "../../components/until";
import Modal from 'react-bootstrap/Modal';
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";

const BxhList = () => {
  let isRequest = useRef(false);
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [winLot, setWinLot] = useState([]);
  const [playing, setPlaying] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pageWinLot, setPageWinLot] = useState(1);
  const [totalRecordWinLot, setTotalRecordWinLot] = useState(0);
  const [pagePlaying, setPagePlaying] = useState(1);
  const [totalRecordPlaying, setTotalRecordPlaying] = useState(0);
  const [pageFavourites, setPageFavourites] = useState(1);
  const [totalRecordFavourites, setTotalRecordFavourites] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [idField, setIdField] = useState();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const [isShowModalData, setIsShowModalData] = useState(false);
  const [modalData, setModalData] = useState();
  const [dataCol1, setDataCol1] = useState();
  const [dataCol2, setDataCol2] = useState();
  const [dataCol3, setDataCol3] = useState();
  const [opGame, setOpGame] = useState();
  const [gameSl, setGameSl] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getDataGame = async () => {
      await API.get(`${GAME}/all/-1`)
        .then((response) => {
          const result = response.data?.data?.result;
          if (result) {
            const dataGame = [];
            result?.map((item) => {
              const newIt = {
                key: item?.id,
                value: `${item?.parrentType}-${extractNumbers(item?.type)}`,
                label: getGame(item?.parrentType, extractNumbers(item?.type))
              }
              dataGame.push(newIt);
            });

            setOpGame(dataGame);
          }
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    getDataGame();
  }, []);

  useEffect(() => {
    getListData();
  }, [pageWinLot, pagePlaying, pageFavourites, dataFilter, indexTab]);

  const getListData = async () => {
    setLoading(true);
    const skip = indexTab === 1 ? pageWinLot : indexTab === 2 ? pagePlaying : pageFavourites
    const URL = `${DATA_FAKE}/dataFake/${indexTab === 1 ? "user_win" : indexTab === 2 ? "user_playing" : "favorite_game"}?take=10&skip=${skip}`

    await API.get(URL).then((response) => {
      const result = response.data?.data?.result;
      if (result) {
        if (indexTab === 1) {
          const dataList = []
          result[0]?.map(it => {
            const newIt = {
              id: it?.id,
              username: it?.username,
              game: formatNameGame(it?.gameType, false),
              amountWin: it?.paymentWin,
            }
            dataList.push(newIt);
          });
          setWinLot(dataList);
          setTotalRecordWinLot(result[1])
        } else if (indexTab === 2) {
          const dataList = []
          result[0]?.map(it => {
            const newIt = {
              id: it?.id,
              username: it?.username,
              game: formatNameGame(it?.gameType, false),
              amountBet: it?.revenue,
            }
            dataList.push(newIt);
          });
          setPlaying(dataList);
          setTotalRecordPlaying(result[1])
        } else {
          const dataList = []
          result[0]?.map(it => {
            const newIt = {
              id: it?.id,
              game: formatNameGame(it?.gameType, true),
              countUser: it?.numbPlayer,
              sumBet: it?.totalBet,
            }
            dataList.push(newIt);
          });
          setFavourites(dataList);
          setTotalRecordFavourites(result[1])
        }
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  const formatNameGame = (gameType, isSetSenconds) => {
    if (!gameType) return "";
    if (gameType?.indexOf("xs") === -1) return gameType;
    const numb = extractNumbers(gameType);
    const indexNum = gameType.indexOf(numb.toString())
    const stringSub = gameType.substring(0, indexNum);
    return getGame(stringSub, isSetSenconds ? numb : 0);
  }

  const onChangePageWinLot = (page) => {
    setPageWinLot(page);
  };

  const onChangePagePlaying = (page) => {
    setPagePlaying(page);
  };

  const onChangePageFavourites = (page) => {
    setPageFavourites(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const handleShowModal = (id) => {
    setIsShowModalConfirm(true);
    setIdField(id);
  };

  const handleDeleteAccount = async () => {
    try {
      setIsShowModalConfirm(false);
      // await API.delete(`${USER}/${idField}`);
      await getListData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsShowModalSuccess(true);
    }
  };

  const formatMoney = (money) => {
    return dollarUS.format(Number(money));
  }

  const winLotColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pageWinLot - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0050,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0051,
      dataIndex: "game",
      key: "game",
    },
    {
      title: TEXT_DEF.text0052,
      key: "amountWin",
      render: (record) => (<span>{formatMoney(record?.amountWin)}</span>)
    },
    // {
    //   title: TEXT_DEF.text0016,
    //   key: "edit",
    //   align: "center",
    //   width: '10%',
    //   render: (record) =>
    //     auth?.role === "supper" ||
    //       (auth?.role === "admin" && roleArr?.indexOf("user_edit")) > -1 ? (
    //       <div className="flex justify-center">
    //         <img
    //           style={{
    //             height: 20,
    //             cursor: "pointer",
    //             display: "flex",
    //             justifyContent: "center",
    //           }}
    //           src={settingIcon}
    //           alt="setting"
    //           onClick={() => changeDataFake(record)}
    //         />
    //       </div>
    //     ) : null,
    // },
  ];


  const playingColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pagePlaying - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0050,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0053,
      dataIndex: "game",
      key: "game",
    },
    {
      title: TEXT_DEF.text0054,
      key: "amountBet",
      render: (record) => (<span>{formatMoney(record?.amountBet)}</span>)
    },
  ];

  const favouritesColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pageFavourites - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0051,
      dataIndex: "game",
      key: "game",
    },
    {
      title: TEXT_DEF.text0055,
      dataIndex: "countUser",
      key: "countUser",
    },
    {
      title: TEXT_DEF.text0056,
      key: "sumBet",
      render: (record) => (<span>{formatMoney(record?.sumBet)}</span>)
    },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const handleSubmitData = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    // console.log("call api")
    if (isShowModalData) {
      setLoading(true);
      // const parmas = {
      //   username: indexTab == 3 ? null : dataCol1,
      //   avatar: null,
      //   keyMode: indexTab == 1 ? "user_win" : indexTab == 2 ? "user_playing" : "favorite_game",
      //   gameType: indexTab == 3 ?
      //     (dataCol1 ? dataCol1?.replace("-", "").concat("s") : "xsmb45s") :
      //     (dataCol2 ? dataCol2?.replace("-", "").concat("s") : "xsmb45s"),
      //   paymentWin: indexTab == 1 ? dataCol3 : null,
      //   revenue: indexTab == 2 ? dataCol3 : null,
      //   numbPlayer: indexTab == 3 ? dataCol2 : null,
      //   totalBet: indexTab == 3 ? dataCol3 : null,
      // }
      const params = createParams(indexTab, dataCol1, dataCol2, dataCol3);

      if (params) {
        await API.post(`${DATA_FAKE}/dataFake/create`, params)
          .then((response) => {
            const result = response.data?.data?.result;
            if (result) {
              setModalData(undefined);
              setDataCol1(undefined);
              setDataCol2(undefined);
              setDataCol3(undefined);
              setIsShowModalData(false);
              getListData();
            }
            isRequest.current = false;
            setLoading(true);
          })
          .catch((error) => {
            isRequest.current = false;
            console.log(error);
            setLoading(false);
          });
      } else {
        alert("Dữ liệu fake không được để trống");
        setLoading(false);
      }
    }
  };

	const createParams = (indexTab, dataCol1, dataCol2, dataCol3) => {
    const gameType = indexTab === 3 ? (dataCol1 ? dataCol1.replace("-", "").concat("s") : "xsmb45s") : dataCol2 ? dataCol2.replace("-", "").concat("s") : "xsmb45s";
    const keyModes = ["user_win", "user_playing", "favorite_game"];
    const keyMode = keyModes[indexTab - 1] || "favorite_game";
    const avatar = null;

    const paramsByTab = {
      1: { username: dataCol1, paymentWin: dataCol3 },
      2: { username: dataCol1, revenue: dataCol3 },
      3: { username: null, numbPlayer: dataCol2, totalBet: dataCol3 },
    };

    if (indexTab === 1) {
      if (!paramsByTab[indexTab].username || !paramsByTab[indexTab].paymentWin) {
        return;
      }
    } else if (indexTab === 2) {
      if (!paramsByTab[indexTab].username || !paramsByTab[indexTab].revenue) {
        return;
      }
    } else if (indexTab === 3) {
      if (!paramsByTab[indexTab].numbPlayer || !paramsByTab[indexTab].totalBet) {
        return;
      }
    }

    return {
      keyMode,
      gameType,
      avatar,
      ...paramsByTab[indexTab],
    };
  };

  const changeDataFake = (record) => {
    let keysArray;
    if (record) {
      keysArray = Object.values(record);
      // console.log("keysArray: ", keysArray)
    }

    const recordCv = {
      record1: keysArray ? keysArray[1] : "",
      record2: keysArray ? keysArray[2] : "",
      record3: keysArray ? keysArray[3] : "",
    }
    const data = {
      label: {
        label1: indexTab == 3 ? "Trò chơi" : "Đại gia",
        label2: indexTab == 1 ? "Trò chơi" : indexTab == 2 ? "Đang chơi" : "Đang tham gia",
        label3: indexTab == 1 ? "Thắng gần đây" : indexTab == 2 ? "Đặt cược" : "Tổng cược",
      },
      record: recordCv
    }
    // console.log("data: ", data)
    setModalData(data)
    setIsShowModalData(true);
  };

  const onChangeIp1 = (e) => {
    setDataCol1(indexTab == 3 ? e : e.target.value)
  }

  const onChangeIp2 = (e) => {
    setDataCol2(e)
  }

  const onChangeIp3 = (e) => {
    setDataCol3(e)
  }

  const onChangeTab = (e) => {
    setPageWinLot(1);
    setPagePlaying(1);
    setPageFavourites(1);
    setIndexTab(e.target.value);
  };

  const renderWinLot = () => {
    return (
      <>
        {
          hasRight([RIGHTS.CreateSettingRankFake]) ? (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2rem",
              }}
            >
              <Button type="primary" onClick={() => changeDataFake(null)}>
                {TEXT_DEF.text0057}
              </Button>
            </div>
          ) : null
        }
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={winLotColums}
              dataSource={winLot}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageWinLot}
              total={totalRecordWinLot}
              onChange={onChangePageWinLot}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderPlaying = () => {
    return (
      <>
        {hasRight([RIGHTS.CreateSettingRankFake]) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <Button type="primary" onClick={() => changeDataFake(null)}>
              {TEXT_DEF.text0057}
            </Button>
          </div>
        ) : null}
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="listAdmin"
            locale={locale}
            columns={playingColums}
            dataSource={playing}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pagePlaying}
            total={totalRecordPlaying}
            onChange={onChangePagePlaying}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </>
    );
  };

  const renderFavourites = () => {
    return (
      <>
        {hasRight([RIGHTS.CreateSettingRankFake]) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <Button type="primary" onClick={() => changeDataFake(null)}>
              {TEXT_DEF.text0057}
            </Button>
          </div>
        ) : null}
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="listFavourites"
            locale={locale}
            columns={favouritesColums}
            dataSource={favourites}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pageFavourites}
            total={totalRecordFavourites}
            onChange={onChangePageFavourites}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </>
    );
  };

  return (
    <div className="col-12">
      {hasRight([RIGHTS.ShowSettingRankFake]) ? (
        <div
          className="col-12"
          style={{
            borderBottom: "1px solid #CCC",
            paddingBottom: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Radio.Group value={indexTab} onChange={onChangeTab}>
            <Radio.Button value={1}>{TEXT_DEF.text0047}</Radio.Button>
            <Radio.Button value={2}>{TEXT_DEF.text0048}</Radio.Button>
            <Radio.Button value={3}>{TEXT_DEF.text0049}</Radio.Button>
          </Radio.Group>
        </div>
      ) : null}

      {indexTab === 1 ? renderWinLot() : indexTab === 2 ? renderPlaying() : renderFavourites()}
      {isShowModalData && (
        <ModalCreateDataFake
          isShow={isShowModalData}
          onChangeIp1={onChangeIp1}
          onChangeIp2={onChangeIp2}
          onChangeIp3={onChangeIp3}
          indexTab={indexTab}
          label={modalData.label}
          opGame={opGame}
          record={modalData.record}
          txtBtnAcc={TEXT_DEF.text0030}
          textBtnClose={TEXT_DEF.text0007}
          actionNext={handleSubmitData}
          close={() => setIsShowModalData(false)}
        />
      )}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_delete_user}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default BxhList;

const ModalCreateDataFake = ({
  isShow, onChangeIp1, onChangeIp2, onChangeIp3, label, opGame,
  record, txtBtnAcc, textBtnClose, actionNext, close, indexTab
}) => {
  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal show={isShow} className='modal-all' onHide={close}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="col-12 flex" style={{ flexDirection: 'column' }}>
          <div className="col-12 flex">
            <div className="col-3 text-left">{label?.label1}</div>
            <div className="col-8">
              {
                indexTab == 3 ?
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Chọn trò chơi"
                    optionFilterProp="children"
                    onChange={onChangeIp1}
                    filterOption={filterOptionBm}
                    options={opGame}
                    allowClear
                  /> :
                  <Input defaultValue={record?.record1} onChange={onChangeIp1} maxLength={20} />
              }
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{label?.label2}</div>
            <div className="col-8">
              {
                indexTab == 3 ?
                  <InputNumber style={{ width: '100%' }} defaultValue={record?.record2} onChange={onChangeIp2} maxLength={50} />
                  :
                  <Select
                    defaultValue={"xsmb-45"}
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Chọn trò chơi"
                    optionFilterProp="children"
                    onChange={onChangeIp2}
                    filterOption={filterOptionBm}
                    options={opGame}
                    allowClear
                  />
              }

            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{label?.label3}</div>
            <div className="col-8">
              <InputNumber style={{ width: '100%' }} defaultValue={record?.record3} onChange={onChangeIp3} maxLength={50} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {textBtnClose ?
          <>
            <Button type="primary" onClick={actionNext}>
              {txtBtnAcc}
            </Button>
            <Button type="default" onClick={close}>
              {textBtnClose}
            </Button>
          </>
          :
          <Button type="primary" onClick={actionNext}>
            {txtBtnAcc}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}
