import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import {
    Button,
    Table,
    Spin,
    Pagination,
    Modal,
    Input,
    Form,
    Popconfirm,
} from "antd";
import { useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import {
    EMPTY_DATA,
    LABEL_CREATE,
    TEXT_DEF,
    DELETE_SUCCESS,
    DELETE_FAIL,
    GET_DATA_FAIL,
} from "../../constant/text";
import API, {
    ROLES,
    ROLE_CREATE,
} from "../../network/api";
import { hasRight } from "../../components/until/right";
import { RIGHTS, RIGHTS_DEFAULT } from "../../constant/rights";
import "./style.css";

const Role = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [rights, setRights] = useState([]);
    const [params, setParams] = useState('');

    useEffect(() => {
        getRoles();
    }, [params]);

    const getRoles = async () => {
        try {
            setLoading(true);
            const result = await API.get(`${ROLES}?${params}`);
            const roles = result.data?.data?.roles || [];
            const total = result.data?.data?.total || 0;
            const finalResult = [];
            for (const item of roles) {
                finalResult.push({
                    id: item.id,
                    name: item.name,
                    updatedAt: (new Date(item.updatedAt)).toLocaleString(),
                    updatedBy: item.updatedBy,
                    bookmarkerName: item?.bookmaker?.name || '',
                });
            }
            setRoles(finalResult);
            setTotalRecord(total);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast(GET_DATA_FAIL);
        }
    }

    const showModal = async (id) => {
        const rights = getRolesDefault(id);
        getRoleUserById(rights, id);
        setOpen(true);
    };

    const getRoleUserById = async (rights, id) => {
        try {
            setLoading(true);
            const result = await API.get(`${ROLE_CREATE}/${id}`);
            setLoading(false);
            const response = _.get(result, 'data.data', false);
            const tempRights = (response.permissions || '').split(',');
            const finalRights = rights.filter(i => {
                const rightsSelected = (i.rights || []).filter((r) => {
                    const hasRight = tempRights.some((rightSelected) => rightSelected === r.Name);
                    if (hasRight) {
                        return r;
                    }
                });
                
                if (rightsSelected.length > 0) {
                    i.rights = rightsSelected;

                    return i;
                }
            });

            setRights([...finalRights]);
        } catch (err) {
            setLoading(false);
            toast(GET_DATA_FAIL);
        }
    }

    const hideModal = () => {
        setOpen(false);
    };

    const colums = [
        {
            title: "STT",
            align: "center",
            key: "order",
            render: (text, record, index) => (
              <span>{(page - 1) * 10 + (index + 1)}</span>
            ),
        },
        {
            title: "ID vai trò",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nhà cái",
            dataIndex: "bookmarkerName",
            key: "bookmarkerName",
        },
        {
            title: "Tên vai trò",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Quyền hạn",
            key: "role",
            render: (record) =>
            <div>
              <div className="flex w-full items-center justify-center" style={{ flexDirection: "column" }}>
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => showModal(record.id)}>Click để xem</div>
              </div>
            </div>
        },
        {
            title: 'Sửa đổi quyền hạn',
            key: "setting",
            align: "center",
            render: (record) =>
                <div className="flex w-full items-center justify-center">
                    {
                        hasRight([RIGHTS.EditRole]) ? (
                            <div>
                                <Button
                                    className="edit"
                                    onClick={() => {
                                        navigate(`/roles/${record.id}`);
                                    }} danger
                                >Edit</Button>
                            </div>
                        ) : null
                    }
                </div>
        },
        {
            title: "Thời gian cập nhật",
            dataIndex: "updatedAt",
            key: "updatedAt",
        },
        {
            title: "Người cập nhật",
            dataIndex: "updatedBy",
            key: "updatedBy",
        },
        // {
        //     title: 'Action',
        //     key: "setting",
        //     align: "center",
        //     render: (record) =>
        //         <div className="flex w-full items-center justify-center">
        //             {
        //                 hasRight([RIGHTS.EditRole]) ? (
        //                     <div>
        //                         <Button
        //                             className="edit"
        //                             onClick={() => {
        //                                 navigate(`/roles/${record.id}`);
        //                             }} danger
        //                         >Edit</Button>
        //                     </div>
        //                 ) : null
        //             }

        //             {
        //                 hasRight([RIGHTS.DeleteRole]) ? (
        //                     <div>
        //                         <Popconfirm
        //                             title="Delete the task"
        //                             description={TEXT_DEF.text0069}
        //                             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        //                             onConfirm={() => { confirm(record.id) }}
        //                             onCancel={cancel}
        //                         >
        //                             <Button danger>Delete</Button>
        //                         </Popconfirm>
        //                     </div>
        //                 ) : null
        //             }
        //         </div>
        // },
    ];

    const createRole = () => {
        navigate("/roles/create");
    };

    const confirm = async (id) => {
        try {
            await API.delete(`${ROLES}/${id}`);
            await getRoles();
            toast(DELETE_SUCCESS);
        } catch (err) {
            setLoading(false);
            toast(DELETE_FAIL);
        }
    };

    const cancel = (e) => { };

    const onChangePage = (page) => {
        getParams('change_page', { page });
    };

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

    const getModules = () => {
        const modules = [];
        for (const k in RIGHTS) {
            const hasModule = modules.some(i => i.module === RIGHTS[k].Module);
            if (hasModule) continue;

            modules.push({
                module: RIGHTS[k].Module,
                moduleTitle: RIGHTS[k].Module_Title,
            });
        }

        return modules;
    }

    const getRolesDefault = (id) => {
        const modules = getModules();

        const rights = [];
        for (const m of modules) {
            if (m.module === 'super') continue;

            const rightsByModule = [];
            for (const r in RIGHTS) {
                if (RIGHTS[r].Module === m.module) {
                    rightsByModule.push(RIGHTS[r]);
                }
            }
            rights.push({
                module: m.module,
                moduleTitle: m.moduleTitle,
                rights: rightsByModule,
            });
        }

        return rights;
    };

    const search = () => {
        getParams('search');
    }

    const getParams = (action = '', data = {}) => {
        let params = `skip=${page}`;
        if (action === 'search') {
          setPage(1);
          params = `skip=1`;
        } else if (action === 'change_page') {
          params = `skip=${(data?.page || 1)}`;
          setPage(data?.page || 1);
        }
        const name = form.getFieldValue('role');
        if (name) {
          params += `&name=${name}`;
        }
        setParams(params);
    }

    return (
        <div className="roles">
            <Modal
                title="Danh sách quyền"
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                <Button key="submit" type="primary" onClick={hideModal}>
                    Đóng
                </Button>
                }
            >
                <div className="order-detail">
                    <div className="content">
                        <div
                            className="col-12"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <div className="col-12">
                                {
                                    rights.map((m) => {
                                        return (
                                            <div className="group-roles">
                                                <div className="module">{m.moduleTitle}</div>
                                                {
                                                    m.rights.map((r) => {
                                                        return (
                                                            <div className="right">
                                                                {
                                                                    <label>- {r.Right_Title}</label>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="col-12">
                <Form form={form} layout="inline" autoComplete="off" onFinish={() => search()}>
                    <div className="col-12 flex" style={{ marginBottom: 15 }}>
                        <div className="col-4">
                            <Form.Item
                                name="role"
                                label="Vai trò"
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-12 flex search-roles">
                        <div style={{ marginRight: 15 }}>
                            <Button htmlType="submit" type="primary">
                                {TEXT_DEF.text0026}
                            </Button>
                        </div>
                        {
                            hasRight([RIGHTS.CreateRole]) ? (
                                <div>
                                    <Button type="primary" onClick={createRole}>
                                        {LABEL_CREATE}
                                    </Button>
                                </div>
                            ) : null
                        }
                    </div>
                </Form>
            </div>

            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                    <Table
                        key="RANK"
                        locale={EMPTY_DATA}
                        columns={colums}
                        dataSource={roles}
                        pagination={false}
                        bordered
                        style={{ width: "100%" }}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        marginTop: 15,
                        justifyContent: "flex-end",
                    }}
                >
                    <Pagination
                        current={page}
                        total={totalRecord}
                        onChange={onChangePage}
                        defaultPageSize={10}
                        showSizeChanger={false}
                        showTotal={showTotal}
                    />
                </div>
            </Spin>
        </div>
    );
}

export default Role;