import API from "../../network/api";

export const fetchData = async (url, filterCondition) => {
  const response = await API.get(url);
  const data = response?.data?.data?.result?.qa;

  return data.filter(filterCondition).map((item) => ({ ...item }));
};

export const fetchTopic = async (url) => {
  const response = await API.get(url);
  const data = response?.data?.data?.result?.qa;
  const total = response?.data?.data?.result?.total;

  return {
    data,
    total,
  };
};
